import styled from 'styled-components';
import { colours, coloursNames } from 'styling/colours';

export const StyledMapWrapper = styled.div`
  height: calc(100vh - 30vh);
  .leaflet-container {
    height: 100%;
  }
`;

export const SearchBarStyled = styled.div`
  background: ${colours.disableSelect};
  padding: 10px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 22px;
  display: flex;
  height: 60px;

  .react-select-container {
    max-width: 306px;
    width: 100%;
    margin-left: 10px;
    .react-select__control {
      justify-content: space-between !important;
      padding: 0 3px 0 10px;
      border-radius: 6px;
      height: 40px;
    }

    .react-select__menu {
      z-index: 9999;
      padding: 16px;
      margin: 0;
      margin-top: 5px;
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        .ant-checkbox-group-item {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        span {
          font-size: 15px;
          text-transform: capitalize;
        }
      }
    }
  }
`;
export const StyledMapDropdownControlWrapper = styled.div`
  .react-select__control {
    padding-left: 16px;
    cursor: pointer;

    .react-select__input-container {
      // hide input so user cant see it.
      opacity: 0;
    }
    .react-control__preplaceholder {
      color: ${coloursNames.midGrey};
      margin-right: 5px;
    }
    .react-select__value-container {
      padding-left: 2px;
    }
    .react-select__single-value {
      overflow: initial;
    }
    .react-select__indicators {
      button {
        background: none;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }
`;

export const StyledDropDownContainer = styled.div`
  margin-left: 40px;
  width: 100%;
  display: flex;
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bar {
    height: 10px;
    width: 5px;
  }
  p {
    margin-top: 30px;
    font-size: 12px;
  }
`;

export const StyledMapFilterContainer = styled.div`
  position: relative;
  border: 1px solid rgba(205, 208, 227, 0.5);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 40px;
`;

export const StyledSmallTooltipInner = styled.div`
  border-radius: 6px;
  background: ${coloursNames.darkGrey};
  height: 65px;
  width: 168px;
  padding: 10px;
  span {
    font-family: 'MuseoSans';
    font-size: 14px;
    display: block;
    color: #d8d8d8;
    &:first-of-type {
      color: white;
    }
  }
  &:before {
    // override for arrow.
    left: 99.5%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-left-color: ${coloursNames.darkGrey};
    border-width: 15px;
    margin-top: -15px;
  }
`;

export const StyledBigTooltipInner = styled.div`
  position: relative;
  height: 100%;
  width: 413px;
  border-radius: 6px;
  background: ${coloursNames.darkGrey};
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.122214);
  img.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .tooltip-inner-content {
    padding: 15px 20px;
    .information-line {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3px;
      h4 {
        width: 35%;
        display: block;
        color: #cbcbcb;
        font-size: 14px;
        font-family: 'MuseoSans';
        margin: 0;
      }
      > span {
        display: flex;
        align-items: center;
        width: 65%;
        display: block;
        font-family: 'MuseoSans';
        font-size: 14px;
        color: white;
      }
      p {
        margin: 0;
      }
    }
    .information-sentance {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        color: #cccccb;
        font-family: 'MuseoSans';
        font-size: 12px;
        margin: 0;
        padding-left: 10px;
      }
    }
  }

  .hr {
    background: #cbcbcb;
    height: 1px;
    width: 100%;
    margin-top: 10px;
  }
  &:before {
    // override for arrow.
    left: 99.5%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-left-color: ${coloursNames.darkGrey};
    border-width: 15px;
    margin-top: -15px;
  }
`;

export const StyledAssetTotalTooltip = styled.div`
  background: #808285;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: 'MuseoSans';
  font-size: 14px;
  color: white;
`;

export const StyledLoadingOverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9991;
  background: #00000042;
  cursor: not-allowed;
  .content-wrapper {
    margin: 0;
    background: white;
    max-width: 135px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
  }
`;

export const NoSearchResultsPanelStyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -44px;
  background: white;
  width: 100%;
  height: 40px;
  padding: 0 9px;
  z-index: 9999;
  border: 1px solid rgba(188, 190, 192, 0.3);
  border-radius: 3px;
  overflow: hidden;

  span {
    padding-left: 5px;
    color: ${coloursNames.cinnabarOrange};
  }
`;

export const StyledFloorSelectorControlDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 8px;
  top: 100px;
  z-index: 999;

  .button-container {
    position: relative;
    display: block;

    button {
      outline: none;
      background: white;
      border: none;
      width: 34px;
      height: 34px;
      font-size: 12px;
      font-family: 'MuseoSans';
      cursor: pointer;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);

      &.--active {
        background: #808285;
        color: white;
      }

      span.count-readout {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -8px;
        top: 8px;
        background: white;
        border-radius: 50%;
        border: 1px solid #808285;
        color: #414042;
        font-size: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
      }
    }
    &:first-of-type {
      button {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    &:last-of-type {
      button {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;
