import React, { useEffect, useContext } from 'react';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import BeaconsTable from 'components/DataTable/BeaconsTable';
import RegisteredBeaconSearchSelectForm from '../components/RegisteredBeaconSearch/RegisteredBeaconSearchSelectForm';
import { registeredBeaconColumns } from 'components/DataTable/columns/registeredBeaconColumns';
import { LatestBeacon } from 'models/devices.models';
import RegisteredBeaconDialog from '../components/dialogs/RegisteredBeaconDialog';
import ArchiveConfirmationModal from '../components/modals/ArchiveConfirmationModal';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import { RegisteredBeaconContext } from '../contexts/RegisteredBeacon.context';
import useRegisteredBeaconAPIHandlers from '../hooks/useRegisteredBeaconAPIHandlers';

type TableWithFilterPanelType = {
  query: any;
  selectedTabKey: string;
};
type selectedRowsObjType = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: LatestBeacon[];
};

export default function RegisteredBeaconTabContent({
  query,
  selectedTabKey,
}: TableWithFilterPanelType) {
  const registeredBeaconContext = useContext(RegisteredBeaconContext);
  const {
    setSelectedRows,
    setSelectedBeaconDialogIsOpen,
    setToggleClearRows,
    setConfirmationModalOpen,
    searchTerm,
    nextToken,
    setFilteredData,
    setNextToken,
    selectedRows,
    toggledClearRows,
    assignOptionHasNoResults,
    isSearching,
    searchInProgress,
    searchResults,
    selectedSearchMenuOptionLabel,
    filteredData,
    confirmationModalOpen,
    selectedBeaconDialogIsOpen,
    cachedFilteredDataRef,
    isSorting,
    nextIsLoading,
    nextTokenCachedRef,
    assetTotalRef,
    paginationIndexRef,
    paginationIndex,
    setPaginationIndex,
    assetTotal,
    setAssetTotal,
  } = registeredBeaconContext;

  const { fetchNextPage, handleSort } = useRegisteredBeaconAPIHandlers();

  const handleSelectRow = (selectedRowsObj: selectedRowsObjType) => {
    // open dialog if table rows are selected.
    const { selectedRows } = selectedRowsObj;
    const rowsSelected = selectedRows.length > 0;
    setSelectedRows(selectedRows);
    setSelectedBeaconDialogIsOpen(rowsSelected);
  };

  function handleCloseDialog() {
    setSelectedRows(selectedRows);
    setSelectedBeaconDialogIsOpen(false);
    setToggleClearRows(!toggledClearRows);
  }

  function handleArchive() {
    setConfirmationModalOpen(true);
  }

  function handleAssign() {}
  function handleCreate() {}
  function handleModalConfirm() {}

  function getNoDataText() {
    return assignOptionHasNoResults
      ? 'No Results.'
      : `Sorry, no results were found for ${searchTerm}`;
  }

  useEffect(() => {
    // set initial filter data and next token from inital query response.
    if (query.data) {
      const { items, next, total } = query.data.data;
      setFilteredData(items);
      setNextToken(next);
      setAssetTotal(total);
      setPaginationIndex(0);

      cachedFilteredDataRef.current = items; // set cached version.
      nextTokenCachedRef.current = next;
      assetTotalRef.current = total;
      paginationIndexRef.current = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.data]);

  useEffect(() => {
    // reset dialogs and selected rows on tab change
    setToggleClearRows(!toggledClearRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabKey]);

  useEffect(() => {
    // set filtered data to search results if there are matches.
    if (isSearching) {
      setFilteredData(searchResults);
    } else {
      // otherwise revert tabledata & other state to cached ref we used on inital load / api calls.
      setFilteredData(cachedFilteredDataRef.current);
      setNextToken(nextTokenCachedRef.current);
      setAssetTotal(assetTotalRef.current);
      setPaginationIndex(paginationIndexRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    // unselect beacons on unmount.
    return () => {
      setToggleClearRows(!toggledClearRows);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RegisteredBeaconSearchSelectForm />
      <DataTableStatusWrapper error={query.error} status={query.status} assetType="Beacons">
        {query.data && (
          <BeaconsTable
            columns={registeredBeaconColumns}
            clearSelectedRows={toggledClearRows}
            handleSelectRow={handleSelectRow}
            handleSort={handleSort}
            data={filteredData}
            fetchNextPage={fetchNextPage}
            defaultSortFieldId={1} // pass device_id to match query sort.
            nextToken={nextToken}
            isSorting={isSorting}
            searchInProgress={searchInProgress}
            nextIsLoading={nextIsLoading}
            totalCount={assetTotal}
            paginationIndex={paginationIndex}
            noDataComponent={
              <NoDataComponent
                selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
                text={getNoDataText()}
              />
            }
            // selectableRows={userPermissions.hasWrite} // disabled until we can fulfil actions for rows.
          />
        )}
      </DataTableStatusWrapper>

      {confirmationModalOpen && (
        <ArchiveConfirmationModal
          selectedRows={selectedRows}
          handleModalConfirm={handleModalConfirm}
          setConfirmationModalOpen={setConfirmationModalOpen}
        />
      )}
      {selectedBeaconDialogIsOpen && (
        <RegisteredBeaconDialog
          handleCloseDialog={handleCloseDialog}
          selectedRows={selectedRows}
          handleArchive={handleArchive}
          handleAssign={handleAssign}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
}
