import React from 'react';
import useAssetInfo from 'hooks/assetInfo.hook';
import { InfoPara } from './contentStyledComponents';
import { replaceUnderscoresWithSpaces } from 'utils/utils';

const ArchiveAssetModalContent: React.FunctionComponent = () => {
  const assetDetails = useAssetInfo();

  return (
    <>
      <p>
        Are you sure you want to archive{' '}
        <strong id="archiveAssetWarningMessage">
          {assetDetails.entity.properties?.name} -{' '}
          {replaceUnderscoresWithSpaces(assetDetails.entity.subtype)}
        </strong>
        ?
      </p>
      <InfoPara>
        Please note, archived assets will not be displayed in the asset list and you cannot find
        them by searching.
      </InfoPara>
    </>
  );
};
export default ArchiveAssetModalContent;
