import { useEffect } from 'react';
import { add, isAfter } from 'date-fns';
import useRefreshToken from 'hooks/useAuthRefresh.hook';

const AmplifyRefreshTimer = (): null => {
  const dateNow = new Date();
  const refreshIntervalInMinutes = 20;
  const refreshToken = useRefreshToken();

  useEffect(() => {
    let amplifyRefreshIntervalTimer: any = null;
    let refreshTime = add(dateNow, {
      minutes: refreshIntervalInMinutes,
    });

    amplifyRefreshIntervalTimer = setInterval(() => {
      const timeNow = new Date();

      if (isAfter(timeNow, refreshTime)) {
        refreshToken();
        refreshTime = add(new Date(), {
          minutes: refreshIntervalInMinutes,
        });
      }
    }, 1000);

    return () => {
      clearInterval(amplifyRefreshIntervalTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default AmplifyRefreshTimer;
