import { useEffect, useState } from 'react';

export default function useScrollPastThreshold() {
  const THRESHOLD = 200;
  const [hasScrolledPastThreshold, setHasScrolledPastThreshold] = useState<any>(false);

  function attachScrollEvent() {
    window.onscroll = () => {
      window.requestAnimationFrame(() => {
        setHasScrolledPastThreshold(window.scrollY > THRESHOLD);
      });
    };
  }

  useEffect(() => {
    attachScrollEvent();
    return () => {
      window.onscroll = null;
    };
  }, []);

  return [hasScrolledPastThreshold, setHasScrolledPastThreshold];
}
