import React from 'react';
import VenueProvider from './contexts/venue.context';
import MapController from './controllers/MapController';
import AssetsProvider from './contexts/assets.context';
import MapControlProvider from './contexts/mapcontrol.context';

export default function AssetsMapPage() {
  return (
    <main>
      <div className="container" style={{ overflowY: 'hidden' }}>
        <VenueProvider>
          <MapControlProvider>
            <AssetsProvider>
              <MapController />
            </AssetsProvider>
          </MapControlProvider>
        </VenueProvider>
      </div>
    </main>
  );
}
