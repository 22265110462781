import LoadingBars from 'components/Loading/LoadingBars';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type DataTableStatusWrapperTypes = {
  status: string;
  error: any;
  children: React.ReactNode;
  assetType?: string;
};

const StyledContainer = styled.div`
  display: flex;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bar {
    height: 10px;
    width: 5px;
  }
  p {
    margin-top: 30px;
    font-size: 12px;
  }
`;

export default function DataTableStatusWrapper({
  status,
  error,
  children,
  assetType = 'Assets',
}: DataTableStatusWrapperTypes): any {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // ensure loading screen is present between renders.
    setIsLoading(status === 'loading');
  }, [status]);

  if (error instanceof Error) {
    return <StyledContainer>{error!.message}</StyledContainer>;
  }

  return isLoading ? (
    <StyledContainer>
      <LoadingBars />
      <p>System is Loading {assetType}</p>
    </StyledContainer>
  ) : (
    children
  );
}
