import React, { ReactNode } from 'react';
import usePermissions from 'hooks/usePermissions.hook';

interface IComponentPermissionRenderWrapperProps {
  componentPermissions: string[];
  children: ReactNode;
}
type ComponentPermissionRenderWrapperProps = {
  componentPermissions: string[];
  children: ReactNode;
};

const ComponentPermissionRenderWrapper: React.FC<IComponentPermissionRenderWrapperProps> = ({
  componentPermissions,
  children,
}: ComponentPermissionRenderWrapperProps) => {
  const { currentUserAccessPermissions } = usePermissions();

  const getArraysIntersection = (a1: string[], a2: string[]) => {
    return a1.filter((n) => {
      return a2.indexOf(n) !== -1;
    });
  };
  const componentHasPermission =
    getArraysIntersection(currentUserAccessPermissions, componentPermissions).length !== 0;

  return <> {componentHasPermission ? children : ''}</>;
};

export default ComponentPermissionRenderWrapper;
