import React, { SyntheticEvent, useState } from 'react';
import FieldErrorMessage from './FieldErrorMessage';
import MaxCharacterReadout from './MaxCharacterReadout';
import { StyledLabel, RequiredAsterisk, StyledInput, StyledFieldset } from './Styled';

type FormInputGroupTypes = {
  register: any;
  errors: any;
  fieldID: string;
  fieldName: string;
  isRequired?: boolean;
  validationObj?: any;
  type?: string;
  defaultValue?: string;
};
export default function FormInputGroup({
  register,
  errors,
  fieldID,
  fieldName,
  isRequired,
  validationObj,
  defaultValue,
  type,
}: FormInputGroupTypes) {
  const [typedInputLength, setTypedInputLength] = useState(0);
  const { maxLength, minLength } = validationObj;

  function handleInputChange(e: React.SyntheticEvent) {
    const target = e.target as HTMLTextAreaElement;

    setTypedInputLength(target.value.length);
  }

  return (
    <StyledFieldset>
      <StyledLabel htmlFor={fieldID}>
        {fieldName}: {isRequired && <RequiredAsterisk />}
      </StyledLabel>
      <StyledInput
        type={type}
        id={fieldID}
        defaultValue={defaultValue}
        {...register(fieldID, {
          onChange: (e: SyntheticEvent) => handleInputChange(e),
          ...validationObj,
        })}
      />
      {errors[fieldID] && <FieldErrorMessage errors={errors} />}

      {maxLength && (
        <MaxCharacterReadout
          minLength={minLength}
          maxLength={maxLength}
          typedInputLength={typedInputLength}
          fieldID={fieldID}
        />
      )}
    </StyledFieldset>
  );
}
