import React, { useState, useContext } from 'react';
import AssetListFlyoutDetails from './content/AssetListFlyoutDetails';
import useAssetInfo from 'hooks/assetInfo.hook';
import Button from 'components/Button/Button';
import { StyledAssetListFlyout, StyledButtonsContainer } from './styles';
import EntitiesApi from 'api/entities/entities.api';
import { getIdToken, replaceUnderscoresWithSpaces } from 'utils/utils';
import ArchiveAssetModal from 'components/Modals/ArchiveAssetModal';
import { AuthenticationContext } from 'contexts/authentication.context';
import { useNavigate } from 'react-router';
import ComponentPermissionRenderWrapper from 'wrappers/ComponentPermissionRenderWrapper';
import { PermissionsNames } from 'config/componentPermissions';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import useMustUpdate from 'hooks/mustUpdate.hook';

const AssetListFlyout = ({
  setFlyoutOpen,
  flyoutOpen,
}: {
  setFlyoutOpen: (arg: boolean) => void;
  flyoutOpen: boolean;
}) => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const navigate = useNavigate();
  const entitiesAPI = new EntitiesApi(token);
  const assetDetails = useAssetInfo();
  const { setMustUpdate } = useMustUpdate();

  const handleArchive = (entityID: string) => {
    setApiCallInProgress(true);

    entitiesAPI
      .deleteAsset(entityID)
      .then(() => {
        setApiCallInProgress(false);
        setFlyoutOpen(false);
        setModalOpen(false);
        alertSuccessMessage(
          `${assetDetails.entity.properties.name} - ${replaceUnderscoresWithSpaces(
            assetDetails.entity.subtype,
          )} has been archived`,
        );
        setMustUpdate(true);
      })

      .catch((err) => {
        console.log(err);
        alertErrorMessage(
          `An error occurred ${assetDetails.entity.properties.name} has not been archived`,
        );
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const goToEdit = () => {
    navigate('/edit');
  };

  return (
    <>
      <StyledAssetListFlyout
        isOpen={flyoutOpen}
        onRequestClose={() => {
          setFlyoutOpen(false);
        }}
        title={assetDetails.entity?.properties.name || 'Unknown'}
        subtitle={assetDetails.entity?.subtype}
      >
        <AssetListFlyoutDetails />
        <ComponentPermissionRenderWrapper componentPermissions={[PermissionsNames.WRITE]}>
          <StyledButtonsContainer>
            <Button className="biggerButton" outline onClick={openModal} id="archiveAssetButton">
              Archive
            </Button>
            <Button className="biggerButton" onClick={goToEdit} id="editAssetButton">
              Edit
            </Button>
          </StyledButtonsContainer>
        </ComponentPermissionRenderWrapper>
      </StyledAssetListFlyout>
      {modalOpen && (
        <ArchiveAssetModal
          closeModal={closeModal}
          apiCallInProgress={apiCallInProgress}
          actionFunction={() => handleArchive(assetDetails.entity.entity_id)}
        />
      )}
    </>
  );
};
export default AssetListFlyout;
