import { LatestBeacon } from 'models';
import React, { createContext, useState, ReactNode, useRef } from 'react';
import { getDefaultDropdownObj } from 'utils/utils';
import { beaconAssignmentOptionsArray } from '../components/RegisteredBeaconSearch/menuOptions';
import { BeaconSelectOptionType, selectedRowsType } from '../types';

interface RegisteredBeaconContextI {
  setFilteredData: (arg: any) => void;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  setIsSearching: (arg: boolean) => void;
  isSearching: boolean;
  isSorting: boolean;
  setIsSorting: (arg: boolean) => void;
  isSearchingAll: boolean;
  searchResults: selectedRowsType;
  setSearchResults: (arg: selectedRowsType) => void;
  setIsSearchingAll: (arg: boolean) => void;
  searchInProgress: boolean;
  setSearchInProgress: (arg: boolean) => void;
  assetAssignOptionSelectedObj: BeaconSelectOptionType;
  setAssetAssignOptionSelectedObj: (arg: BeaconSelectOptionType) => void;
  selectedSearchMenuOptionLabel: string;
  selectedSearchMenuOptionValue: string;
  setSelectedSearchMenuOptionLabel: (arg: string) => void;
  setSelectedSearchMenuOptionValue: (arg: string) => void;
  nextToken: string;
  setNextToken: (arg: string) => void;
  nextIsLoading: boolean;
  setNextIsLoading: (arg: boolean) => void;
  activeSortField: string;
  setActiveSortField: (arg: string) => void;
  activeSortDirection: string;
  setActiveSortDirection: (arg: string) => void;
  assignOptionHasNoResults: boolean;
  setAssignOptionHasNoResults: (arg: boolean) => void;
  filteredData: selectedRowsType;
  toggledClearRows: boolean;
  setToggleClearRows: (arg: boolean) => void;
  selectedRows: LatestBeacon[];
  setSelectedRows: (arg: LatestBeacon[]) => void;
  selectedBeaconDialogIsOpen: boolean;
  setSelectedBeaconDialogIsOpen: (arg: boolean) => void;
  confirmationModalOpen: boolean;
  setConfirmationModalOpen: (arg: boolean) => void;
  paginationIndex: number;
  setPaginationIndex: (arg: number) => void;
  assetTotal: number;
  setAssetTotal: (arg: number) => void;
  cachedFilteredDataRef: any;
  nextTokenCachedRef: any;
  assetTotalRef: any;
  paginationIndexRef: any;
}
export const RegisteredBeaconContext = createContext({} as RegisteredBeaconContextI);

type RegisteredBeaconProviderProps = { children: ReactNode };

const RegisteredBeaconProvider = ({ children }: RegisteredBeaconProviderProps) => {
  // search bar variables
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSearchMenuOptionLabel, setSelectedSearchMenuOptionLabel] = useState('');
  const [selectedSearchMenuOptionValue, setSelectedSearchMenuOptionValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingAll, setIsSearchingAll] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);

  const [searchResults, setSearchResults] = useState<selectedRowsType>([]);
  // assignment dropdown  variables
  const defaultDropdownObj = getDefaultDropdownObj(beaconAssignmentOptionsArray);
  const [assetAssignOptionSelectedObj, setAssetAssignOptionSelectedObj] =
    useState(defaultDropdownObj);
  const [assignOptionHasNoResults, setAssignOptionHasNoResults] = useState(false);
  // sort param variables
  const [activeSortField, setActiveSortField] = useState('device_id'); //default sort field
  const [activeSortDirection, setActiveSortDirection] = useState('asc'); // default sort order.
  const [isSorting, setIsSorting] = useState(false);
  // next pagination variables
  const [nextToken, setNextToken] = useState('');
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0); // keep track of pagination clicks
  const [assetTotal, setAssetTotal] = useState(0);
  // table component variables
  const [filteredData, setFilteredData] = useState<selectedRowsType>([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState<LatestBeacon[]>([]);
  // modal / dialog variables
  const [selectedBeaconDialogIsOpen, setSelectedBeaconDialogIsOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  //refs to keep track of state if user cancels search.
  const cachedFilteredDataRef = useRef([]);
  const nextTokenCachedRef = useRef('');
  const assetTotalRef = useRef('');
  const paginationIndexRef = useRef('');

  return (
    <RegisteredBeaconContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        selectedSearchMenuOptionLabel,
        setSelectedSearchMenuOptionLabel,
        selectedSearchMenuOptionValue,
        setSelectedSearchMenuOptionValue,
        isSearching,
        setIsSearching,
        isSearchingAll,
        setIsSearchingAll,
        searchInProgress,
        setSearchInProgress,
        searchResults,
        setSearchResults,
        assetAssignOptionSelectedObj,
        setAssetAssignOptionSelectedObj,
        assignOptionHasNoResults,
        setAssignOptionHasNoResults,
        activeSortField,
        setActiveSortField,
        activeSortDirection,
        setActiveSortDirection,
        isSorting,
        setIsSorting,
        nextToken,
        setNextToken,
        nextIsLoading,
        setNextIsLoading,
        filteredData,
        setFilteredData,
        toggledClearRows,
        setToggleClearRows,
        selectedRows,
        setSelectedRows,
        selectedBeaconDialogIsOpen,
        setSelectedBeaconDialogIsOpen,
        confirmationModalOpen,
        setConfirmationModalOpen,
        paginationIndex,
        setPaginationIndex,
        assetTotal,
        setAssetTotal,
        cachedFilteredDataRef,
        nextTokenCachedRef,
        assetTotalRef,
        paginationIndexRef,
      }}
    >
      {children}
    </RegisteredBeaconContext.Provider>
  );
};

export default RegisteredBeaconProvider;
