import React, { useEffect, useContext, useRef, useState } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledMapDropdownControlWrapper } from '../../Styled';
import LoadingSelectInput from 'components/Loading/LoadingSelectInput';
import { Checkbox } from 'antd';
import DropdownControlReadout from './DropdownControlReadout';
import useCallConfig from 'views/AssetsMapPage/hooks/useCallConfig';
import { MapControlContext } from 'views/AssetsMapPage/contexts/mapcontrol.context';
import useCallSearchAssets from 'views/AssetsMapPage/hooks/useCallSearchAssets';

type AssetSubTypeDropdownFilterInputType = {
  resetSearchFromCheckbox: () => void;
  assetSubtypeCheckedList: string[];
  setAssetSubtypeCheckedList: (arg: string[]) => void;
  assetSubtypeQueryArray: string[];
  assetOwnerQueryArray: string[];
  setAssetSubtypeCheckAll: (arg: boolean) => void;
  assetSubtypeCheckAll: boolean;
};
export default function AssetSubTypeDropdownFilterInput({
  assetSubtypeQueryArray,
  assetOwnerQueryArray,
  assetSubtypeCheckedList,
  setAssetSubtypeCheckedList,
  setAssetSubtypeCheckAll,
  resetSearchFromCheckbox,
  assetSubtypeCheckAll,
}: AssetSubTypeDropdownFilterInputType) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { inSearchMode, setNoSubtypesSelected, apiCallInProgress, setClickedMarkerID } =
    useContext(MapControlContext);
  const { isLoadingOptions, options, defaultCheckedListArray, callGetConfigAssetSubtypes } =
    useCallConfig();
  const { callFilterAssets } = useCallSearchAssets();

  const inputRef: any = useRef(null);

  const handleCheckAllChange = (e: any) => {
    setAssetSubtypeCheckedList(e.target.checked ? defaultCheckedListArray : []);
    setAssetSubtypeCheckAll(e.target.checked);
    resetSearchFromCheckbox();
    setClickedMarkerID('');
  };

  const handleCheckboxClick = (list: any) => {
    setAssetSubtypeCheckedList(list);
    setAssetSubtypeCheckAll(list.length === defaultCheckedListArray.length);
    resetSearchFromCheckbox();
    setClickedMarkerID('');
  };

  useEffect(() => {
    //reset checked to all, after performing a search
    if (inSearchMode) {
      setAssetSubtypeCheckedList(defaultCheckedListArray);
      setAssetSubtypeCheckAll(true);
      setDropdownOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSearchMode]);

  useEffect(() => {
    // toggle noSubTypesSelected boolean, when checklist changes.
    const noSubtypesSelected = assetSubtypeCheckedList.length === 0 && !assetSubtypeCheckAll;
    setNoSubtypesSelected(noSubtypesSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetSubtypeCheckAll, assetSubtypeCheckedList]);

  useEffect(() => {
    // call API with selected options when checked list changes, after load
    if (!isLoadingOptions && !inSearchMode) {
      callFilterAssets(inputRef, assetOwnerQueryArray, assetSubtypeQueryArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetSubtypeCheckedList]);

  useEffect(() => {
    //call api for Asset Types on component load.
    callGetConfigAssetSubtypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set checked list to ALL, after load
    if (!isLoadingOptions) {
      setAssetSubtypeCheckedList(defaultCheckedListArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOptions]);

  return !isLoadingOptions ? (
    <SelectInput
      options={options}
      placeholder={false}
      openMenuOnClick
      ref={inputRef}
      menuIsOpen={dropdownOpen}
      onMenuOpen={() => {
        setDropdownOpen(true);
      }}
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...rest }) => (
          <StyledMapDropdownControlWrapper>
            <components.Control {...rest}>
              <div style={{ display: 'flex' }}>
                <span className="react-control__preplaceholder">Asset Type: </span>
                <DropdownControlReadout
                  checkAll={assetSubtypeCheckAll}
                  checkedList={assetSubtypeCheckedList}
                />
              </div>

              {children}
            </components.Control>
          </StyledMapDropdownControlWrapper>
        ),
        DropdownIndicator: ({ ...rest }) => (
          <button
            onClick={() => setDropdownOpen(false)}
            style={{ transform: `rotate(${dropdownOpen ? '180deg' : '0deg'}` }}
          >
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...rest }) => {
          return (
            <components.Menu {...rest}>
              <Checkbox
                disabled={apiCallInProgress}
                style={{ marginBottom: '20px', width: '100%' }}
                checked={assetSubtypeCheckAll}
                onChange={handleCheckAllChange}
              >
                All
              </Checkbox>

              <Checkbox.Group
                disabled={apiCallInProgress}
                value={assetSubtypeCheckedList}
                options={options}
                defaultValue={defaultCheckedListArray}
                onChange={handleCheckboxClick}
              />
            </components.Menu>
          );
        },
      }}
    />
  ) : (
    <LoadingSelectInput />
  );
}
