import { Estimate, LatestBeacon } from 'models';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import freshIcon from './icons/fresh-asset.svg';
import moderateIcon from './icons/moderate-asset.svg';
import staleIcon from './icons/stale-asset.svg';
import freshIconMoving from './icons/fresh-moving-asset.svg';
import moderateIconMoving from './icons/moderate-moving-asset.svg';
import staleIconMoving from './icons/stale-moving-asset.svg';

export function getIconFromEstimateFreshness(estimate: Estimate) {
  const timeNow = new Date();
  const timeDifferenceInMins = differenceInMinutes(timeNow, parseFloat(estimate.timestamp));

  // timings:
  // > 1 hour = stale
  // 10 - 59 mins = moderate
  // 0 - 9 mins = fresh

  const estimateIsStale = timeDifferenceInMins > 60;
  const estimateIsModerate = timeDifferenceInMins > 10 && timeDifferenceInMins < 59;
  const estimateIsFresh = timeDifferenceInMins < 9;
  const estimateIsMobile = estimate.properties?.mobility_state === 'mobile';

  if (estimateIsMobile) {
    // moving asset icons
    if (estimateIsStale) {
      return staleIconMoving;
    } else if (estimateIsModerate) {
      return moderateIconMoving;
    } else if (estimateIsFresh) {
      return freshIconMoving;
    }
  } else {
    // non moving asset icon
    if (estimateIsStale) {
      return staleIcon;
    } else if (estimateIsModerate) {
      return moderateIcon;
    } else if (estimateIsFresh) {
      return freshIcon;
    }
  }
  return staleIcon; // if nothing above matches.
}

export function getSafeValueFromObject(val: any) {
  if (!val) return '--';
  return val;
}

export function filterAssetMarkersByFloorUUID(
  assetMarkers: any[],
  floorSelectedUUIDArray: string[],
) {
  // function  returns array of markers whos buildin_level_uuid matches one of the selected floor UUIDs
  return assetMarkers.filter((asset: any) => {
    return floorSelectedUUIDArray.includes(asset.estimate.location.building.building_level_uuid);
  });
}

export function getUnitFeatureByFloorSelectedUUID(unitFeatures: any, floorSelectedUUID: string) {
  return unitFeatures.filter((feature: any) => feature.properties.level_id === floorSelectedUUID);
}

export function getLevelFeatureDataFromBuildingID(buildingID: string, levelFeatures: any): any {
  const levelsFeaturesWithMatchingBuildingIDsArray = levelFeatures.filter((feature: any) => {
    if (feature.properties.building_ids) {
      return feature.properties.building_ids.includes(buildingID);
    }
  });

  return levelsFeaturesWithMatchingBuildingIDsArray;
}

export function getUnitFeatureDataFromLevelIDs(levelIDArray: string[], unitFeatures: any): any {
  // return unit feature data filtered by each id in levelIDArray.
  let combinedUnitFeatures: any = [];

  levelIDArray.forEach((levelID) => {
    const levelIDFilteredFeatures = unitFeatures.filter((feature: any) => {
      return feature.properties.level_id === levelID;
    });
    combinedUnitFeatures = [...combinedUnitFeatures, ...levelIDFilteredFeatures];
  });

  return combinedUnitFeatures;
}

export function getLevelFeaturesWithUniqueOrdinals(associatedBuildingLevels: any) {
  // returns array of features with unique ordinals, so we can use them to display floors.
  return associatedBuildingLevels.filter((value: any, index: number, self: any) => {
    return self.findIndex((v: any) => v.properties.ordinal === value.properties.ordinal) === index;
  });
}

export function getSortedFeaturesByOrdinal(levelFeatures: any) {
  return levelFeatures.sort(
    (a: any, b: any) => parseFloat(a.properties.ordinal) - parseFloat(b.properties.ordinal),
  );
}

export function getAssociatedLevelIDsArrayFromOrdinal(ordinal: number, levelFeatures: any) {
  let array: any[] = [];

  levelFeatures.forEach((feature: any) => {
    if (feature.properties.ordinal === ordinal) {
      array.push(feature.id);
    }
  });
  return array;
}
export function getItemsWithEstimate(items: LatestBeacon[]) {
  return items.filter((item) => item.estimate !== null);
}

export function getLowestOrdinalThatHasAsset(levelFeatures: any, assetMarkers: any) {
  // loop through assets, and then loop through levels for each asset.
  // if level ID matches asset building level id, return ordinal of this level.
  // this is the lowest ORDINAL with an asset.
  let lowestOrdinal = 0;
  let ordinalFound = false;

  levelFeatures.forEach((levelFeature: any) => {
    if (ordinalFound) return;

    assetMarkers.forEach((asset: LatestBeacon) => {
      if (ordinalFound) return;
      const assetLevelID = asset.estimate.location.building.building_level_uuid;

      if (levelFeature.id === assetLevelID) {
        lowestOrdinal = levelFeature.properties.ordinal;
        ordinalFound = true;
        return;
      }
    });
  });

  return lowestOrdinal;
}

export function getFloorIndexFromLevelsOrdinal(
  lowestOrdinalWithAsset: number,
  sortedLevelsWithUniqueOrdinals: any,
): number {
  // loop through unique sorted ordinals, and if level ordinal matches lowest ordinal, set this ordinal to the index of iteration,
  // return this index so we can use it to derive the selected floor.

  let foundOrdinal = lowestOrdinalWithAsset;
  let ordinalFound = false;

  sortedLevelsWithUniqueOrdinals.forEach((level: any, index: number) => {
    if (ordinalFound) return;

    if (level.properties.ordinal === lowestOrdinalWithAsset) {
      foundOrdinal = index;
      ordinalFound = true;

      return;
    }
  });
  return foundOrdinal;
}

export function getFloorAssetCountFromLevelIDArray(
  levelIDArray: any,
  assetMarkers: LatestBeacon[],
) {
  // a function that creates an array of markers whos building level uuid matches one of the level_ids in the array.
  // we use this to determine how many assets are on that floor,
  // then return the length of the new array which is the count.
  let filteredMarkersArray = [];

  levelIDArray.forEach((levelID: string) => {
    filteredMarkersArray = assetMarkers.filter((asset) => {
      const { building_level_uuid } = asset.estimate.location.building;
      return levelID === building_level_uuid;
    });
  });

  return filteredMarkersArray.length;
}

export function getBuildingAssetCountFromFeatureBuildingUUIDArray(
  buildingUUIDArray: string[],
  assetMarkers: LatestBeacon[],
) {
  let filteredMarkersArray = [];

  buildingUUIDArray.forEach((buildingUUID) => {
    assetMarkers.forEach((asset) => {
      const { building_uuids } = asset.estimate.location.building;

      if (building_uuids.includes(buildingUUID)) {
        filteredMarkersArray.push(asset);
      }
    });
  });

  return filteredMarkersArray.length;
}

export function getCoordsFromAssets(assets: LatestBeacon[]) {
  let coords: any = [];

  assets.forEach((asset) => {
    const latLongArray = asset.estimate.location.coordinates.coordinates;
    const correctedCoord: any = [...latLongArray].reverse(); // reversed to correct order

    coords.push(correctedCoord);
  });

  return coords;
}

export function getArrayOfValuesFromOptions(options: any) {
  let arr: any = [];

  options.forEach((element: any) => {
    arr.push(element.value);
  });
  return arr;
}

// A function that returns only items if the assets from the APIs owner & subtype exists within the selected filter params.
// used for returning intersection of results of owner & subtype for filter dropdowns.
export function getIntersectionFilteredAssets(
  assets: LatestBeacon[],
  assetOwnerQueryArray: string[],
  assetSubtypeQueryArray: string[],
) {
  // if (assetOwnerQueryArray.length < 1) return assets;
  let filteredAssetArray: LatestBeacon[] = [];
  const assetOwnersArrayEmpty = assetOwnerQueryArray.length < 1;
  const assetSubtypeArrayEmpty = assetSubtypeQueryArray.length < 1;

  assets.forEach((asset) => {
    // loop through assets with estimates,
    // and push assets only if subtype and owner exist on asset.
    // OR if owner OR subtype is empty
    const assetMatchesSelectedOwners = assetOwnerQueryArray.includes(asset.entity.properties.owner);
    const assetMatchesSelectedSubtype = assetSubtypeQueryArray.includes(asset.entity.subtype);

    if (
      (assetMatchesSelectedOwners || assetOwnersArrayEmpty) &&
      (assetMatchesSelectedSubtype || assetSubtypeArrayEmpty)
    ) {
      filteredAssetArray.push(asset);
    }
  });
  return filteredAssetArray;
}
