import React, { useContext, useState } from 'react';
import { selectedRowsType } from '../types';
import BeaconInformationPanel from '../components/BeaconInformationPanel';
import AssetInformationPanelForm from '../components/AssetInformationPanelForm';
import Button from 'components/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import { StyledImportCreateAssetsContainer } from '../components/Styled';
import EntitiesApi from 'api/entities/entities.api';
import { getIdToken, getNewEntityId } from 'utils/utils';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { AuthenticationContext } from 'contexts/authentication.context';
import DevicesApi from 'api/devices/devices.api';
import { useQueryClient } from 'react-query';

interface IFormInputs {
  assetId: string;
  assetName: string;
  assetOwner: { label: string; value: string };
  assetSubType: { label: string; value: string };
  description: string;
}

type ImportCreateAssetsScreenTypes = {
  beaconsForImport: selectedRowsType;
  setImportCreateSelected: (param: boolean) => void;
};
type importCreateAssetDataType = {
  assetName: string;
  assetSubType: {
    label: string;
    value: string;
  };
  assetOwner: {
    label: string;
    value: string;
  };
  description: string;
};

export default function ImportCreateAssetsScreen({
  beaconsForImport,
  setImportCreateSelected,
}: ImportCreateAssetsScreenTypes) {
  const selectedBeacon = beaconsForImport[0]; // only import the first selected beacon.
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const entitiesApi = new EntitiesApi(token);
  const devicesApi = new DevicesApi(token);
  const queryClient = useQueryClient();

  function handleCancel() {
    setImportCreateSelected(false);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<IFormInputs>({ reValidateMode: 'onChange', mode: 'onChange' });

  const onSubmit = handleSubmit((data: importCreateAssetDataType) => {
    setApiCallInProgress(true);

    const entityId = getNewEntityId(); // creates entityID uuid

    // body for POST entity/asset
    const newAssetDetails = {
      modified: new Date().getTime(),
      type: 'asset',
      entity_id: entityId,
      subtype: data.assetSubType.value,
      properties: {
        description: data.description,
        owner: data.assetOwner.value,
        name: data.assetName,
      },
    };

    // body for POST device/beacon
    const newBeaconDetails = {
      ...selectedBeacon,
      entity_id: entityId,
    };

    // first call createNewAsset, and on success we call postBeacon
    // to insert the new beacon and assign to the asset
    entitiesApi
      .createNewAsset(newAssetDetails)
      .then(() => {
        devicesApi
          .postBeacon(newBeaconDetails)
          .then(() => {
            alertSuccessMessage(`1 New Beacon has been imported & created an asset`);
            setApiCallInProgress(false);
            setImportCreateSelected(false);
            queryClient.invalidateQueries('new-beacons');
            queryClient.invalidateQueries('registered-beacons');
          })
          .catch(() => {
            alertErrorMessage(`Error Creating Assets`);
            setApiCallInProgress(false);
          });
      })
      .catch((err) => {
        const message = err.response.data.detail;
        alertErrorMessage(`Error Creating Assets: ${message}`);
        setApiCallInProgress(false);
        console.error(message);
      });
  });

  return (
    <StyledImportCreateAssetsContainer>
      <div className="content">
        <BeaconInformationPanel selectedBeacon={selectedBeacon} />

        <div className="dashed-line" />

        <AssetInformationPanelForm
          errors={errors}
          register={register}
          watch={watch}
          setSubmitEnabled={setSubmitEnabled}
          handleSubmit={handleSubmit}
          Controller={Controller}
          control={control}
        />
      </div>

      <div className="button-container">
        <Button
          style={{ width: '170px', height: '50px' }}
          outline
          onClick={handleCancel}
          id="cancelImportCreateAsset"
        >
          Cancel
        </Button>
        <Button
          style={{ width: '170px', height: '50px' }}
          type="submit"
          id="saveImportCreateAsset"
          onClick={onSubmit}
          disabled={!submitEnabled}
          isLoading={apiCallInProgress}
        >
          Import & Create
        </Button>
      </div>
    </StyledImportCreateAssetsContainer>
  );
}
