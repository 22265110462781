import React, { useEffect, useState } from 'react';
import LoadingScreen from 'components/Loading/LoadingScreen';
import config from 'react-global-configuration';

// a simple wrapper to apply config defined in config/config.json to global object, which values can be read by cognito and other apis.
function ConfigWrapper({ children }: { children: any }) {
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    fetch('/config/config.json')
      .then((response) => response.json())
      .then((res) => {
        config.set(res);
        setConfigLoaded(true);
      })
      .catch(() => {
        console.error('could not find config');
      });
  }, []);

  return configLoaded ? children : <LoadingScreen text={'loading'} />;
}
export default ConfigWrapper;
