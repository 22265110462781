import axios from 'axios';
import config from 'react-global-configuration';

class EntitiesApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accepts', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accepts: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }
  public checkEntityExists = async (id: string) => {
    const url = `${config.get().API_URL}/entity/asset?filter[properties.name]="${id}"`;
    const response = await fetch(url, {
      method: 'GET',
      headers: this.config,
    });
    const { count } = await response.json();
    if (count === 0) {
      return false;
    } else {
      return true;
    }
  };
  public updateAssetDetails = async (newAssetDetails: any) => {
    const url = `${config.get().API_URL}/entity/asset/${newAssetDetails.entity_id}`;
    return axios.put(url, JSON.stringify(newAssetDetails), this.axiosConfig);
  };

  public createNewAsset = async (newAssetDetails: any) => {
    const url = `${config.get().API_URL}/entity/asset`;
    return axios.post(url, JSON.stringify(newAssetDetails), this.axiosConfig);
  };

  public deleteAsset = async (assetID: string) => {
    const url = `${config.get().API_URL}/entity/asset/${assetID}`;
    return axios.delete(url, this.axiosConfig);
  };
}
export default EntitiesApi;
