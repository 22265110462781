import { useContext, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, getSortedArrayByValue } from 'utils/utils';
import ConfigApi from 'api/config/config.api';
import { alertErrorMessage } from 'utils/alerts';
import { getArrayOfValuesFromOptions } from 'views/AssetsMapPage/utils';

export default function useCallConfig() {
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [options, setOptions] = useState([]);

  const [defaultCheckedListArray, setDefaultCheckedListArray] = useState([]);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const configAPI = new ConfigApi(token);

  function callGetConfigAssetSubtypes() {
    configAPI
      .getAssetSubtypes()
      .then((res: any[]) => {
        const alphaSortedOptions = getSortedArrayByValue(res, 'value');
        const defaultOptionsArray = getArrayOfValuesFromOptions(alphaSortedOptions);

        setOptions(alphaSortedOptions);
        setDefaultCheckedListArray(defaultOptionsArray);
        setIsLoadingOptions(false);
      })
      .catch((err) => {
        setIsLoadingOptions(false);
        alertErrorMessage('could not fetch config');
        console.error(err);
      });
  }
  function callGetConfigAssetOwner() {
    configAPI
      .getAssetOwners()
      .then((res: any[]) => {
        const alphaSortedOptions = getSortedArrayByValue(res, 'value');
        const defaultOptionsArray = getArrayOfValuesFromOptions(alphaSortedOptions);

        setOptions(alphaSortedOptions);
        setDefaultCheckedListArray(defaultOptionsArray);
        setIsLoadingOptions(false);
      })
      .catch((err) => {
        setIsLoadingOptions(false);
        alertErrorMessage('could not fetch config');
        console.error(err);
      });
  }
  return {
    isLoadingOptions,
    options,
    defaultCheckedListArray,
    callGetConfigAssetSubtypes,
    callGetConfigAssetOwner,
  };
}
