import axios from 'axios';
import config from 'react-global-configuration';

class AssetsApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accepts', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accepts: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  protected _handleError = (error: any) => Promise.reject(error);

  public getAllAssets = async (token: string): Promise<any> => {
    this.token = token;
    const data = await axios.get(`${config.get().API_URL}/entity/asset`);
    return data;
  };

  public getAssets = async (token: string, query: any): Promise<any> => {
    this.token = token;
    const queryString = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
      queryString.append(key, <string>value);
    });
    queryString.append('count', '50');
    const data = await axios.get(`${config.get().API_URL}/entity/asset/?${queryString.toString()}`);
    return data;
  };

  public getRegisteredBeaconMapAssets = async (term?: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '100');
    if (term) {
      // only search by asset name.
      queryString.append(`filter[properties.name]`, `${term}`);
    }

    return axios.get(
      `${config.get().API_URL}/latest/asset/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getRegisteredBeaconFilteredMapAssets = async (
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '100');

    assetOwnerQueryArray.forEach((assetTypeValue) => {
      queryString.append(`filter[properties.owner]`, `"${assetTypeValue}"`);
    });

    assetSubtypeQueryArray.forEach((assetTypeValue) => {
      queryString.append(`filter[subtype]`, `"${assetTypeValue}"`);
    });

    return axios.get(
      `${config.get().API_URL}/latest/asset/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };
}

export default AssetsApi;
