import React, { useState, useEffect } from 'react';
import { StyledError } from './Styled';

function getErrorString(errType: string): string {
  switch (errType) {
    case 'maxLength':
      return 'Value too long';

    default:
      return '';
  }
}

export default function FieldErrorMessage({ errors }: { errors: any }) {
  const [errorString, setErrorString] = useState('');
  const objLength = Object.keys(errors).length;

  useEffect(() => {
    if (objLength > 0) {
      const fieldVals: any = Object.values(errors);
      const errorType = fieldVals[0].type;
      const errorStr = getErrorString(errorType);

      setErrorString(errorStr);
    } else {
      setErrorString('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objLength]);

  return <StyledError>{errorString}</StyledError>;
}
