import React from 'react';
import './AssetListFlyoutDetails.css';
import { colours } from 'styling/colours';
import { format } from 'date-fns';
import { getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';
import LocationSpan from './LocationSpan';
import AccuracyRangeSpan from './AccuracyRangeSpan';

const AssetListFlyoutDetails: React.FunctionComponent = () => {
  const assetDetails = JSON.parse(localStorage.getItem('assetDetails') || '');

  return (
    <table className="viewAssetDetails">
      <tbody>
        <tr>
          <td>Added On:</td>
          <td>{format(new Date(assetDetails.entity.created), 'dd/LL/yyyy - HH:mm')}</td>
        </tr>
        <tr>
          <td>Last Seen:</td>
          <td>
            <LastSeenCell timestamp={assetDetails.estimate?.timestamp} />
            {` ${getLastSeenString(assetDetails.estimate?.timestamp)}`}
          </td>
        </tr>
        <tr>
          <td>Battery:</td>
          <td>
            {getValueSafely(assetDetails.estimate?.properties?.battery?.battery_percent, '%')}
          </td>
        </tr>
        <tr>
          <td>Owner:</td>
          <td>{getValueSafely(assetDetails.entity?.properties?.owner)}</td>
        </tr>
        <tr>
          <td>Accuracy Range:</td>
          <td>
            <AccuracyRangeSpan assetDetails={assetDetails} />{' '}
          </td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{getValueSafely(assetDetails.estimate?.properties?.mobility_state)}</td>
        </tr>
        <tr>
          <td>Beacon Type:</td>
          <td>
            {getValueSafely(assetDetails.device?.type)} -{' '}
            {getValueSafely(assetDetails.device?.source)}
          </td>
        </tr>
        <tr>
          <td>Beacon ID:</td>
          <td>{getValueSafely(assetDetails.device?.device_id)}</td>
        </tr>
        <tr>
          <td>Asset ID:</td>
          <td>{getValueSafely(assetDetails.entity?.entity_id)}</td>
        </tr>
        <tr>
          <td valign="top">Location:</td>
          <td>
            <LocationSpan assetDetails={assetDetails} />
            <div style={{ color: colours.secondaryText, margin: '10px 0' }}>Possible region:</div>
            <div>{getValueSafely(assetDetails.estimate?.location?.region.name)}</div>
          </td>
        </tr>
        <tr>
          <td valign="top">Description:</td>
          <td id="assetDetailsDescription">{assetDetails.entity.properties.description}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default AssetListFlyoutDetails;
