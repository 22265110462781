import React, { useEffect, useContext, useRef, useState } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledMapDropdownControlWrapper } from '../../Styled';
import LoadingSelectInput from 'components/Loading/LoadingSelectInput';
import { Checkbox } from 'antd';
import DropdownControlReadout from './DropdownControlReadout';
import useCallConfig from 'views/AssetsMapPage/hooks/useCallConfig';
import { MapControlContext } from 'views/AssetsMapPage/contexts/mapcontrol.context';
import useCallSearchAssets from 'views/AssetsMapPage/hooks/useCallSearchAssets';

type AssetOwnerDropdownFilterInputType = {
  assetOwnerQueryArray: string[];
  assetSubtypeQueryArray: string[];
  assetOwnerCheckedList: string[];
  setAssetOwnerCheckedList: (arg: string[]) => void;
  setAssetOwnerCheckAll: (arg: boolean) => void;
  assetOwnerCheckAll: boolean;
  resetSearchFromCheckbox: () => void;
};
export default function AssetOwnerDropdownFilterInput({
  assetOwnerQueryArray,
  assetSubtypeQueryArray,
  assetOwnerCheckedList,
  setAssetOwnerCheckedList,
  assetOwnerCheckAll,
  setAssetOwnerCheckAll,
  resetSearchFromCheckbox,
}: AssetOwnerDropdownFilterInputType) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const inputRef: any = useRef(null);
  const { inSearchMode, setNoOwnersSelected, apiCallInProgress, setClickedMarkerID } =
    useContext(MapControlContext);
  const { isLoadingOptions, options, defaultCheckedListArray, callGetConfigAssetOwner } =
    useCallConfig();
  const { callFilterAssets } = useCallSearchAssets();

  const handleCheckAllChange = (e: any) => {
    setAssetOwnerCheckedList(e.target.checked ? defaultCheckedListArray : []);
    setAssetOwnerCheckAll(e.target.checked);
    resetSearchFromCheckbox();
    setClickedMarkerID('');
  };

  const handleCheckboxClick = (list: any) => {
    setAssetOwnerCheckedList(list);
    setAssetOwnerCheckAll(list.length === defaultCheckedListArray.length);
    resetSearchFromCheckbox();
    setClickedMarkerID('');
  };

  useEffect(() => {
    //reset checked to all, after performing a search
    if (inSearchMode) {
      setAssetOwnerCheckedList(defaultCheckedListArray);
      setAssetOwnerCheckAll(true);
      setDropdownOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSearchMode]);

  useEffect(() => {
    // toggle noSubTypesSelected boolean, when checklist changes.
    const noOwnersSelected = assetOwnerCheckedList.length === 0 && !assetOwnerCheckAll;
    setNoOwnersSelected(noOwnersSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetOwnerCheckAll, assetOwnerCheckedList]);

  useEffect(() => {
    // call API with selected options when checked list changes, after load
    if (!isLoadingOptions && !inSearchMode) {
      callFilterAssets(inputRef, assetOwnerQueryArray, assetSubtypeQueryArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetOwnerCheckedList]);

  useEffect(() => {
    //call api for Asset Types on component load.
    callGetConfigAssetOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set checked list to ALL, after load
    if (!isLoadingOptions) {
      setAssetOwnerCheckedList(defaultCheckedListArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOptions]);

  return !isLoadingOptions ? (
    <SelectInput
      options={options}
      placeholder={false}
      openMenuOnClick
      menuIsOpen={dropdownOpen}
      onMenuOpen={() => {
        setDropdownOpen(true);
      }}
      ref={inputRef}
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...rest }) => (
          <StyledMapDropdownControlWrapper>
            <components.Control {...rest}>
              <div style={{ display: 'flex' }}>
                <span className="react-control__preplaceholder">Owner: </span>
                <DropdownControlReadout
                  checkAll={assetOwnerCheckAll}
                  checkedList={assetOwnerCheckedList}
                />
              </div>

              {children}
            </components.Control>
          </StyledMapDropdownControlWrapper>
        ),
        DropdownIndicator: ({ ...rest }) => (
          <button
            onClick={() => setDropdownOpen(false)}
            style={{ transform: `rotate(${dropdownOpen ? '180deg' : '0deg'}` }}
          >
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...rest }) => {
          return (
            <components.Menu {...rest}>
              <Checkbox
                disabled={apiCallInProgress}
                style={{ marginBottom: '20px', width: '100%' }}
                checked={assetOwnerCheckAll}
                onChange={handleCheckAllChange}
              >
                All
              </Checkbox>

              <Checkbox.Group
                disabled={apiCallInProgress}
                value={assetOwnerCheckedList}
                options={options}
                defaultValue={defaultCheckedListArray}
                onChange={handleCheckboxClick}
              />
            </components.Menu>
          );
        },
      }}
    />
  ) : (
    <LoadingSelectInput />
  );
}
