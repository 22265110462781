/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useContext } from 'react';
import styled from 'styled-components';
import arrowRight from './arrow-right.svg';
import { UserContext } from 'contexts/user.context';
import { Dropdown } from 'antd';
import DropDownMenu from './DropDownMenu';
import { coloursNames } from 'styling/colours';

const StyledDropdownWrapper = styled.div`
  p {
    font-family: 'MuseoSlab';
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    margin-left: 55px;
    padding: 0 15px 0 0;
    position: relative;
    text-align: right;
    cursor: pointer;
    color: ${coloursNames.midGrey};
    &:hover {
      color: ${coloursNames.deepSkyBlue};
      transition: color 0.2s ease-in;
    }
    &:after {
      content: '';
      background: url(${arrowRight}) 0 0 no-repeat;
      background-size: 10px 10px;
      transform: rotate(90deg);
      width: 10px;
      height: 10px;
      position: absolute;
      top: 5px;
      right: 0;
    }
  }
`;
const overlayStyle = {
  marginTop: '10px',
  minWidth: '186px',
  maxWidth: '186px',
  width: '100%',
};

const UserInfoDropdown = () => {
  const userContext = useContext(UserContext);
  const { email } = userContext;

  return (
    <StyledDropdownWrapper>
      <Dropdown
        trigger={['click']}
        destroyPopupOnHide
        overlay={<DropDownMenu />}
        placement="bottomRight"
        arrow
        overlayStyle={overlayStyle}
      >
        <p tabIndex={0}>{email}</p>
      </Dropdown>
    </StyledDropdownWrapper>
  );
};

export default UserInfoDropdown;
