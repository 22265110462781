import config from 'react-global-configuration';
import { replaceUnderscoresWithSpaces } from 'utils/utils';

class ConfigApi {
  token: string;
  headersConfig: object;

  public constructor(token: string) {
    this.token = token;
    this.headersConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accepts: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getAssetSubtypes = async () => {
    const response = await fetch(
      `${config.get().API_URL}/config/asset/fields/subtype`,
      this.headersConfig,
    );
    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const dataFromServer = await response.json();
    const itemsArray = new Array();
    dataFromServer.items.forEach((item: string) => {
      itemsArray.push({ value: item, label: replaceUnderscoresWithSpaces(item) });
    });
    return itemsArray;
  };

  public getAssetOwners = async () => {
    const response = await fetch(
      `${config.get().API_URL}/config/asset/properties/owner`,
      this.headersConfig,
    );
    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const dataFromServer = await response.json();
    const itemsArray = new Array();
    dataFromServer.items.forEach((item: string) => {
      itemsArray.push({ value: item, label: replaceUnderscoresWithSpaces(item) });
    });
    return itemsArray;
  };
}
export default ConfigApi;
