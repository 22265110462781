import React, { useEffect, useState, useContext } from 'react';
import usePageInfo from 'hooks/pageInfo.hook';
import Tablist from './components/Tablist';
import NewBeaconTabContent from './controllers/NewBeaconTabContent';
import RegisteredBeaconTabContent from './controllers/RegisteredBeaconTabContent';
import { useGetNewBeacons, useGetRegisteredBeacons } from 'api/devices/devices.hooks';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import RefreshTimestampAndButton from './components/RefreshTimestampAndButton';
import styled from 'styled-components';
import ImportCreateAssetsScreen from './controllers/ImportCreateAssetsScreen';
import { selectedRowsType } from './types';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import { useLocation } from 'react-router';
import usePermissions from 'hooks/usePermissions.hook';
import RegisteredBeaconContext from './contexts/RegisteredBeacon.context';

const TabContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BeaconManagement: React.FC = () => {
  const [importCreateSelected, setImportCreateSelected] = useState(false); // set import screen.
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const [beaconsForImport, setBeaconsForImport] = useState<selectedRowsType>([]);
  const [newBeaconCount, setNewBeaconCount] = useState(0);
  const [registeredBeaconCount, setRegisteredBeaconCount] = useState(0);
  const [selectedTabKey, setSelectedTabKey] = useState('1');

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const source = 'kontakt';
  const newBeaconQuery = useGetNewBeacons(token, source);
  const registeredBeaconQuery = useGetRegisteredBeacons(token, null, 'device_id', 'asc');
  const { setContextPageTitle } = usePageInfo();
  const location = useLocation();
  const { userPermissions } = usePermissions();

  function handleTabSelect(key: string) {
    setSelectedTabKey(key);
  }

  useEffect(() => {
    // set page title after changing to import screen.
    if (importCreateSelected) setContextPageTitle('Import & Create Asset(s)');
    return () => {
      setContextPageTitle('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importCreateSelected]);

  useEffect(() => {
    if (!userPermissions.hasWrite) {
      // prevent user from being on newbeacon tab without write permission
      setSelectedTabKey('2');
      return;
    }

    if (newBeaconQuery.data) {
      // set correct tab depending on res from newbeacon data.
      const newBeaconsAvailable = newBeaconQuery.data.data.count > 0;
      const defaultTabKey = newBeaconsAvailable ? '1' : '2';
      setSelectedTabKey(defaultTabKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBeaconQuery.data]);

  useEffect(() => {
    // set counts of tabs
    if (newBeaconQuery.data) setNewBeaconCount(newBeaconQuery.data.data.total || 0);
    if (registeredBeaconQuery.data) {
      setRegisteredBeaconCount(registeredBeaconQuery.data.data.total || 0);
    }
  }, [newBeaconQuery, registeredBeaconQuery]);

  useEffect(() => {
    // listen to see if navlinks are clicked, and reset import screen to not appearing.
    setImportCreateSelected(false);
    setBeaconsForImport([]);
  }, [location]);

  return (
    <main>
      <div className="container">
        {importCreateSelected ? (
          <ImportCreateAssetsScreen
            setImportCreateSelected={setImportCreateSelected}
            beaconsForImport={beaconsForImport}
          />
        ) : (
          <>
            <TabContainerStyled>
              <Tablist
                activeKey={selectedTabKey}
                handleTabSelect={handleTabSelect}
                newBeaconQueryCount={newBeaconCount}
                registeredBeaconQueryCount={registeredBeaconCount}
              />
              <RefreshTimestampAndButton
                setSelectedTabKey={setSelectedTabKey}
                selectedTabKey={selectedTabKey}
                setHasRefreshed={setHasRefreshed}
              />
            </TabContainerStyled>
            {selectedTabKey === '1' ? (
              <NewBeaconTabContent
                setImportCreateSelected={setImportCreateSelected}
                setBeaconsForImport={setBeaconsForImport}
                query={newBeaconQuery}
                selectedTabKey={selectedTabKey}
                beaconsForImport={beaconsForImport}
                hasRefreshed={hasRefreshed}
              />
            ) : (
              <RegisteredBeaconContext>
                <RegisteredBeaconTabContent
                  query={registeredBeaconQuery}
                  selectedTabKey={selectedTabKey}
                />
              </RegisteredBeaconContext>
            )}
            <ScrollToTopButton />
          </>
        )}
      </div>
    </main>
  );
};
export default BeaconManagement;
