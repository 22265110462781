import React, { useState } from 'react';
import Button from 'components/Button/Button';
import ArchiveIcon from '../../icons/ArchiveIcon.svg';
import TaskIcon from '../../icons/TaskIcon.svg';
import AntModalStyled from 'components/Modals/AntModalStyled';
import { LatestBeacon } from 'models/devices.models';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { StyledIconWrapper } from '../Styled';

type confirmationModalType = {
  setConfirmationModalOpen: (arg: boolean) => void;
  handleModalConfirm: () => void;
  selectedRows: LatestBeacon[];
};
const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-top: 20px;
  p {
    color: ${colours.primaryText};
  }
  .icon-text-container {
    display: flex;
    justify-content: flex-start;
    img {
      height: 20px;
      margin-right: 10px;
    }
    p {
      margin-top: 0;
      color: ${colours.primaryText};
    }
  }
`;

export default function ArchiveConfirmationModal({
  setConfirmationModalOpen,
  selectedRows,
  handleModalConfirm,
}: confirmationModalType) {
  const [isSecondConfirm, setIsSecondConfirm] = useState(false);
  const isSingleBeacon = selectedRows.length < 2;

  const BeaconReadoutText = () => {
    return isSingleBeacon ? (
      <>
        the beacon <strong> {selectedRows[0].device.device_id}</strong>
      </>
    ) : (
      <>
        these <strong>#{selectedRows.length}</strong> beacons
      </>
    );
  };

  const InnerText = () => {
    return (
      <StyledContentContainer>
        {!isSecondConfirm ? (
          <>
            <p>
              Are you sure you want to archive <BeaconReadoutText /> ?
            </p>
            <div className="icon-text-container">
              <img src={TaskIcon} alt="task" />

              <p>
                Please note, when a beacon is archived: <br />
                its assignment to an asset is broken which may render the asset untraceable; the
                beacon is no longer displayed anywhere in the system and it will not show in search
                results.
              </p>
            </div>
          </>
        ) : (
          <p>
            Please click confirm if you still want to continue archiving <br />
            <BeaconReadoutText />
          </p>
        )}
      </StyledContentContainer>
    );
  };

  return (
    <AntModalStyled onCancel={() => setConfirmationModalOpen(false)} visible centered footer={null}>
      <header>
        <StyledIconWrapper>
          <img src={ArchiveIcon} alt="archive" />{' '}
          <h3>Archive Beacon{isSingleBeacon ? '' : '(s)'}</h3>
        </StyledIconWrapper>
      </header>

      <InnerText />

      <footer>
        <Button onClick={() => setConfirmationModalOpen(false)} outline>
          Cancel
        </Button>
        {!isSecondConfirm ? (
          <Button
            onClick={() => {
              setIsSecondConfirm(true);
            }}
          >
            Archive
          </Button>
        ) : (
          <Button onClick={() => handleModalConfirm()}>Confirm</Button>
        )}
      </footer>
    </AntModalStyled>
  );
}
