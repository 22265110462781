import styled from 'styled-components';
import { coloursNames } from 'styling/colours';
import { colours } from 'styling/colours';

export const DialogContainerStyled = styled.div`
  width: 100%;
  position: fixed;
  bottom: 20px;
  left: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  dialog {
    border-radius: 7px;
    padding: 0 15px;
    max-width: 533px;
    width: 100%;
    height: 100%;
    border: none;
    background: ${coloursNames.black};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events:all;
    >div{
      display: flex;
      align-items:center;
      justify-content: flex-start;
    }
    svg{
      width: 100%;
      height: 100%;
    }
    .selected-readout {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      border-radius: 50%;
      text-align: center;
      color white;
      background: #4E4E4E;
      margin: 0 10px;
    }
    .selected-text{
      color white;
    }
    button {
      width: 100px;
      height: 38px;
      border-radius: 3px;
      font-size: 15px;
      margin-left: 10px;

    }
  }
`;

export const SearchBarStyled = styled.div`
  background: ${colours.disableSelect};
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;

  .react-select-container {
    max-width: 270px;
    width: 100%;
    margin-left: 10px;
    .react-select__control {
      padding: 0 12px;
      border-radius: 6px;
      height: 40px;
    }
  }
`;

export const StyledImportCreateAssetsContainer = styled.div`
  height: 100%;
  padding-bottom: 10px;
  .content {
    margin-top: 40px;
    background: ${colours.white};
    padding: 40px;
    .dashed-line {
      margin: 40px 0;
      width: 100%;
      border: none;
      border-top: 1px dashed #cfcfcf;
      color: #fff;
      background-color: #fff;
      height: 1px;
    }
    .asset-owner-field {
      fieldset {
        height: 80px;
      }
    }
  }

  .button-container {
    margin: 15px 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    button {
      &:last-child {
        margin-left: 10px;
      }
    }
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${coloursNames.midGrey};
  border-radius: 50%;
  img {
    width: 20px;
  }
`;

export const StyledRegisteredDropdownControlWrapper = styled.div`
  .react-select__control {
    padding-left: 16px;
    .react-control__preplaceholder {
      color: ${coloursNames.midGrey};
    }
    .react-select__value-container {
      padding-left: 2px;
    }
    .react-select__single-value {
      overflow: initial;
    }
  }
`;
