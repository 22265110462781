import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import TriangleIcon from './triangle.svg';
import useScrollPastThreshold from 'hooks/useScrollPastThreshold';

const StyledButton = styled.button`
  display: block;
  position: fixed;
  bottom: 100px;
  right: 50px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  background: ${colours.secondary};
  border-radius: 50%;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  &.--active {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    visibility: visible;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  span {
    color: white;
    font-family: 'MuseoSlab';
    font-size: 12px;
    width: 100%;
  }
  :hover {
    cursor: pointer;
  }
`;

export default function ScrollToTopButton() {
  const [hasScrolledPastThreshold, setHasScrolledPastThreshold] = useScrollPastThreshold();

  function handleClick() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    return () => {
      setHasScrolledPastThreshold(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledButton
      onClick={handleClick}
      className={hasScrolledPastThreshold ? '--active' : ''}
      aria-label="Scroll To Top Button"
      tab-index="1"
    >
      <img src={TriangleIcon} alt="scroll to top" />
      <span>Top</span>
    </StyledButton>
  );
}
