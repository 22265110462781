import React, { useState, useEffect, useRef, useContext } from 'react';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import BeaconsTable from 'components/DataTable/BeaconsTable';
import { newBeaconColumns } from 'components/DataTable/columns/newBeaconColumns';
import ImportConfirmationModal from '../components/modals/ImportConfirmationModal';
import ImportModal from '../components/modals/ImportModal';
import { useQueryClient } from 'react-query';
import { selectedRowsType } from '../types';
import NewBeaconDialog from '../components/dialogs/NewBeaconDialog';
import NewBeaconSearchForm from '../components/NewBeaconSearch/NewBeaconSearchForm';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import DevicesApi from 'api/devices/devices.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';

type TableWithFilterPanelType = {
  query: any;
  selectedTabKey: string;
  setImportCreateSelected: (param: boolean) => void;
  setBeaconsForImport: (param: selectedRowsType) => void;
  beaconsForImport: selectedRowsType;
  hasRefreshed: boolean;
};
type selectedRowsObjType = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: selectedRowsType;
};

export default function NewBeaconTabContent({
  query,
  selectedTabKey,
  setImportCreateSelected,
  setBeaconsForImport,
  beaconsForImport,
  hasRefreshed,
}: TableWithFilterPanelType) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [nextToken, setNextToken] = useState('');
  const [paginationIndex, setPaginationIndex] = useState(0); // keep track of pagination clicks
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<selectedRowsType>([]);
  const [preSelectedRows, setPreSelectedRows] = useState<selectedRowsType>([]);
  const [filteredData, setFilteredData] = useState<selectedRowsType>([]);
  const queryClient = useQueryClient();
  const [isImporting, setIsImporting] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<selectedRowsType>([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [selectedBeaconDialogIsOpen, setSelectedBeaconDialogIsOpen] = useState(false);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const devicesApi = new DevicesApi(token);
  const cachedFilteredDataRef = useRef([]);
  const cachedNextTokenRef = useRef('');

  const handleSelectRow = (selectedRowsObj: selectedRowsObjType) => {
    // open dialog if table rows are selected.
    const { selectedRows } = selectedRowsObj;
    const rowsSelected = selectedRows.length > 0;
    setSelectedRows(selectedRows);
    setSelectedBeaconDialogIsOpen(rowsSelected);
  };

  function handleCloseDialog() {
    setSelectedBeaconDialogIsOpen(false);
    setToggleClearRows(!toggledClearRows);
  }

  function handleImportCreateAsset() {
    setBeaconsForImport(selectedRows);
    setImportCreateSelected(true);
  }

  function handleModalConfirm(optionSelected: number) {
    switch (optionSelected) {
      case 1: // import only
        setIsImporting(true);
        break;
      case 2: // import and assign to asset
        break;
      case 3: // import and create asset
        handleImportCreateAsset();
        break;
      default:
        break;
    }
  }

  function handleDialogAction() {
    setConfirmationModalOpen(true);
  }
  function getNoDataText() {
    return !isSearching
      ? 'No Beacons'
      : 'No beacons found. Please check you have correctly entered the full beacon ID.';
  }

  function repopulateTables() {
    // requeries APIs. and resets the table, selection,modals.
    queryClient.invalidateQueries('new-beacons');
    queryClient.invalidateQueries('registered-beacons');
    setToggleClearRows(!toggledClearRows);
    setConfirmationModalOpen(false);
    setSelectedBeaconDialogIsOpen(false);
  }

  function fetchNextPage() {
    const currentData = filteredData;

    setToggleClearRows(!toggledClearRows);
    setConfirmationModalOpen(false);
    setSelectedBeaconDialogIsOpen(false);
    setBeaconsForImport([]);

    setNextIsLoading(true);
    devicesApi
      .getNewBeacons('kontakt', nextToken)
      .then((res) => {
        const combinedData: any = [...currentData, ...res.data.items];

        setFilteredData(combinedData);
        setNextToken(res.data.next);
        cachedFilteredDataRef.current = combinedData;
        cachedNextTokenRef.current = res.data.next;

        setNextIsLoading(false);
        setPaginationIndex(paginationIndex + 1);
      })
      .catch((err) => {
        console.error(err.response);
        alertErrorMessage('Could not retrieve next page');
        setNextIsLoading(false);
      });
  }

  useEffect(() => {
    // set initial filter data and next token from inital query response.
    if (query.data) {
      const { items, next } = query.data.data;

      setFilteredData(items);
      setNextToken(next);
      cachedFilteredDataRef.current = items; // set cached version.
      cachedNextTokenRef.current = next;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.data]);

  useEffect(() => {
    // set preselect rows for when cancelling and returning from import & Create asset screen
    if (beaconsForImport.length > 0) {
      setPreSelectedRows(beaconsForImport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set filtered data to search results if there are matches.
    if (isSearching) {
      setFilteredData(searchResults);
    } else {
      // otherwise revert tabledata & nextToken to cached ref we used on inital load.
      setFilteredData(cachedFilteredDataRef.current);
      setNextToken(cachedNextTokenRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    // reset dialogs and selected rows on tab change
    setSelectedBeaconDialogIsOpen(false);
    setToggleClearRows(!toggledClearRows);
    setBeaconsForImport([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabKey]);

  useEffect(() => {
    // unselect beacons and clear rows on refresh.
    if (hasRefreshed) {
      setToggleClearRows(!toggledClearRows);
      setPreSelectedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRefreshed]);

  return (
    <>
      <NewBeaconSearchForm
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setIsSearching={setIsSearching}
        setSearchResults={setSearchResults}
        setNextToken={setNextToken}
      />
      <DataTableStatusWrapper error={query.error} status={query.status} assetType="Beacons">
        {query.data && (
          <BeaconsTable
            columns={newBeaconColumns}
            clearSelectedRows={toggledClearRows}
            handleSelectRow={handleSelectRow}
            data={filteredData}
            fetchNextPage={fetchNextPage}
            nextIsLoading={nextIsLoading}
            selectableRows
            disableSort
            preSelectedRows={preSelectedRows}
            nextToken={nextToken}
            totalCount={query.data.data.total}
            paginationIndex={paginationIndex}
            noDataComponent={<NoDataComponent text={getNoDataText()} />}
          />
        )}
      </DataTableStatusWrapper>
      {confirmationModalOpen && !isImporting && (
        <ImportConfirmationModal
          selectedCount={selectedRows.length}
          handleModalConfirm={handleModalConfirm}
          setConfirmationModalOpen={setConfirmationModalOpen}
        />
      )}

      {isImporting && (
        <ImportModal
          selectedRows={selectedRows}
          repopulateTables={repopulateTables}
          setIsImporting={setIsImporting}
        />
      )}

      {selectedBeaconDialogIsOpen && (
        <NewBeaconDialog
          handleCloseDialog={handleCloseDialog}
          selectedCount={selectedRows.length}
          handleDialogAction={handleDialogAction}
        />
      )}
    </>
  );
}
