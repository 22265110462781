import React from 'react';

export default function SignoutPage() {
  return (
    <main className="u-flex-center">
      <div className="container">
        <h1>Sorry, there&apos;s been an error.</h1>
        <br />
        <a href="/">Return To Homepage</a>
      </div>
    </main>
  );
}
