import { LatestBeacon } from 'models';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { humaniseStringCasing } from 'utils/utils';
import { StyledSmallTooltipInner } from '../../Styled';
import { getSafeValueFromObject } from '../../utils';

export default function SmallInfoBoxTooltip({ asset }: { asset: LatestBeacon }) {
  return (
    <Tooltip className="tooltip-small" opacity={1} direction="left" permanent offset={[-18, 0]}>
      <StyledSmallTooltipInner>
        <span>{humaniseStringCasing(getSafeValueFromObject(asset.entity?.subtype))}</span>
        <span>{getSafeValueFromObject(asset.entity?.properties.name)}</span>
      </StyledSmallTooltipInner>
    </Tooltip>
  );
}
