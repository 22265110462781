import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { LatestBeacon } from 'models';
import { getItemsWithEstimate } from '../utils';
import AssetsApi from 'api/assets/assets.api';
import { MapControlContext } from './mapcontrol.context';
import { alertErrorMessage } from 'utils/alerts';

type AssetsContextTypes = {
  assetMarkers: LatestBeacon[];
  setAssetMarkers: (arg: LatestBeacon[]) => void;
};
export const AssetsContext = createContext({} as AssetsContextTypes);

type AssetsProviderProps = { children: ReactNode };

const AssetsProvider = ({ children }: AssetsProviderProps) => {
  const [assetMarkers, setAssetMarkers] = useState<LatestBeacon[] | []>([]);

  const { recallGetAssets, setRecallGetAssets, setSearchCleared, setApiCallInProgress } =
    useContext(MapControlContext);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const value: AssetsContextTypes = { assetMarkers, setAssetMarkers };

  function callGetAssets() {
    const assetsApi = new AssetsApi(token);
    setApiCallInProgress(true);

    assetsApi
      .getRegisteredBeaconMapAssets()
      .then((res) => {
        const { items } = res.data;
        const itemsWithEstimate = getItemsWithEstimate(items);

        setAssetMarkers(itemsWithEstimate);
        setApiCallInProgress(false);
        setRecallGetAssets(false);
        setSearchCleared(false); // reset this so floor selection works.
      })
      .catch((err) => {
        alertErrorMessage('Could Not Fetch Assets');
        console.error(err.message);
      });
  }

  useEffect(() => {
    // recall API after search has been cleared.
    if (recallGetAssets) {
      callGetAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recallGetAssets]);

  return <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>;
};

export default AssetsProvider;
