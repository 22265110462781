import React from 'react';
import { LatestAssetResults } from 'models';
import { colours } from 'styling/colours';
import { dateDiff } from 'utils/utils';

const LocationCell = (row: LatestAssetResults) => {
  let showError = false;
  if (dateDiff(new Date(parseInt(row.estimate?.timestamp, 10)), new Date()) > 3600) {
    showError = true;
  }
  if (row.estimate?.location !== undefined) {
    return (
      <div className="u-no-pointer-event" data-tag="allowRowEvents">
        <div style={{ color: showError ? `${colours.error}` : '' }}>
          {row.estimate.location?.building?.building_level_name}/{' '}
          {row.estimate.location?.building?.building_names}
        </div>
        <div style={{ color: showError ? `${colours.error}` : '' }}>
          {row.estimate.location?.region.name}
        </div>
      </div>
    );
  }
  return <>––</>;
};
export default LocationCell;
