import React from 'react';

type SelectInputStatusWrapperTypes = {
  status: string;
  error: any;
  children: React.ReactNode;
};
export default function SelectInputStatusWrapper({
  status,
  error,
  children,
}: SelectInputStatusWrapperTypes): any {
  if (status === 'loading') {
    return <div>Loading...</div>;
  }
  if (error instanceof Error) {
    return <div>{error!.message}</div>;
  }

  return children;
}
