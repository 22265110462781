import { LeafletMouseEvent } from 'leaflet';
import React, { useContext } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import BuildingAssetTotalCountTooltip from '../components/ToolTips/BuildingAssetTotalCountTooltip';
import BuildingLabelTooltip from '../components/ToolTips/BuildingLabelTooltip';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenueContext } from '../contexts/venue.context';
import { getLevelFeatureDataFromBuildingID } from '../utils';

export default function BuildingsGeoJsonLayer() {
  const { buildingFeatures, levelsFeatures } = useContext(VenueContext);
  const {
    setBuildingSelectedLevelID,
    setBuildingSelectedID,
    buildingSelectedID,
    setClickedMarkerID,
    noSubTypesOrOwnersSelected,
    apiCallInProgress,
  } = useContext(MapControlContext);

  const map = useMap();

  function handleGeoJsonLayerClick(e: LeafletMouseEvent, buildingID: string) {
    // clicking on a building will pass the event, which has the level Feature ID.
    const levelID = e.layer.feature.id;
    const newBuildingSelected = buildingID !== buildingSelectedID;

    map.flyTo(e.latlng, map.getZoom());
    setBuildingSelectedLevelID(levelID);
    setBuildingSelectedID(buildingID);

    if (newBuildingSelected) setClickedMarkerID(''); // reset id, so we hide info boxes when we change building selection.
  }

  // render geoJson component for each building in buildings.geojson
  // note we are using buildingFeatures to map over iterations, but levelFeatures to render the actual geojson.
  // we then filter levels, by single ordinal, in this case 0.
  // this will give us the outline of each building.
  return buildingFeatures.map((buildingFeature: any) => {
    const buildingIDLevelsData = getLevelFeatureDataFromBuildingID(
      buildingFeature.id,
      levelsFeatures,
    );
    const buildingIsSelected = buildingSelectedID === buildingFeature.id;
    const buildingName = buildingFeature.properties.name.en;

    return (
      <div key={buildingFeature.id}>
        <BuildingLabelTooltip buildingName={buildingName} feature={buildingIDLevelsData[0]} />

        {!noSubTypesOrOwnersSelected && !apiCallInProgress && (
          <BuildingAssetTotalCountTooltip feature={buildingIDLevelsData[0]} />
        )}

        <GeoJSON
          eventHandlers={{
            click: (e) => {
              handleGeoJsonLayerClick(e, buildingFeature.id);
            },
          }}
          style={{
            fillColor: '#E6F0FC',
            fillOpacity: buildingIsSelected ? 0 : 1,
            color: '#719ED3',
          }}
          data={buildingIDLevelsData}
        />
      </div>
    );
  });
}
