import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  padding: 10px;
`;
export default function VersionReadout() {
  return <StyledDiv>{process.env.REACT_APP_VERSION}</StyledDiv>;
}
