import { useContext, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import AssetsApi from 'api/assets/assets.api';
import { AssetsContext } from '../contexts/assets.context';
import { getIntersectionFilteredAssets, getItemsWithEstimate } from '../utils';
import { MapControlContext } from '../contexts/mapcontrol.context';

export default function useCallSearchAssets() {
  const [noResultsReturned, setNoResultsReturned] = useState(false);

  const { setAssetMarkers } = useContext(AssetsContext);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);

  const {
    setApiCallInProgress,
    setSearchCleared,
    setBuildingSelectedLevelID,
    setBuildingSelectedID,
    setInSearchMode,
    setClickedMarkerID,
    setInFilterMode,
  } = useContext(MapControlContext);

  const callSearchAssets = (term: string) => {
    setApiCallInProgress(true);
    setSearchCleared(false);
    setInSearchMode(false);

    assetsApi
      .getRegisteredBeaconMapAssets(term)
      .then((res) => {
        const { items } = res.data;
        const itemsWithEstimate = getItemsWithEstimate(items);

        setInSearchMode(true);
        setInFilterMode(false);
        setAssetMarkers(itemsWithEstimate);
        setApiCallInProgress(false);
        setClickedMarkerID('');

        if (items.length < 1) setNoResultsReturned(true);
        else if (items.length === 1) {
          // if one result is returned, programatically select the building and floor that this asset belongs to
          // so the user can see it.
          const singleMatchedAssetLevelID = items[0].estimate.location.building.building_level_uuid;
          const singleMatchedAssetBuildingID =
            items[0].estimate.location.building.building_uuids[0];

          setBuildingSelectedLevelID(singleMatchedAssetLevelID);
          setBuildingSelectedID(singleMatchedAssetBuildingID);
        }
      })
      .catch((err) => {
        setApiCallInProgress(false);
        alertErrorMessage(`Search Failed`);
        console.error(err.message);
      });
  };

  const callFilterAssets = (
    inputRef: any,
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
  ) => {
    setApiCallInProgress(true);

    assetsApi
      .getRegisteredBeaconFilteredMapAssets(assetOwnerQueryArray, assetSubtypeQueryArray)
      .then((res) => {
        const { items } = res.data;
        const itemsWithEstimate = getItemsWithEstimate(items);
        const hasFiltersActive =
          assetOwnerQueryArray.length > 0 || assetSubtypeQueryArray.length > 0;

        const filteredAssets = hasFiltersActive
          ? getIntersectionFilteredAssets(
              itemsWithEstimate,
              assetOwnerQueryArray,
              assetSubtypeQueryArray,
            )
          : itemsWithEstimate;

        setAssetMarkers(filteredAssets);
        setApiCallInProgress(false);
        setInFilterMode(true);
        // keep focus on  input after api call, so we can close menu properly.
        if (inputRef.current) inputRef.current.focus();
      })
      .catch((err) => {
        alertErrorMessage('could not search');
        console.log(err);
      });
  };

  return {
    callSearchAssets,
    noResultsReturned,
    setNoResultsReturned,
    callFilterAssets,
  };
}
