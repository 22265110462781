import styled from 'styled-components';
import { colours } from 'styling/colours';
import ArchiveIcon from './ArchiveIcon.svg';
import infoBg from './infoBg.svg';

export const ModalHeading = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  color: ${colours.primaryText};
  background: url(${ArchiveIcon}) no-repeat;
  background-size: 40px 40px;
  background-position-x: left;
  background-position-y: center;
  padding-left: 50px;
  margin: 0 0 30px;
`;
export const InfoPara = styled.p`
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0px;
  color: ${colours.primaryText};
  background: url(${infoBg}) no-repeat;
  background-size: 21px 20px;
  background-position-x: left;
  background-position-y: top;
  padding-left: 30px;
  margin: 30px 0 40px;
`;
