import { LOW_BATTERY_THRESHOLD } from 'config/constants';
import { format } from 'date-fns';
import { LatestBeacon } from 'models';
import React from 'react';
import { colours } from 'styling/colours';
import { getValueSafely } from 'utils/utils';

export default function BatteryReadoutSpan({ asset }: { asset: LatestBeacon }) {
  const timestamp: any = asset.estimate.timestamp;

  if (!asset.estimate?.properties?.battery?.battery_percent) {
    return <span>--</span>;
  }
  if (asset.estimate?.properties?.battery?.battery_percent < LOW_BATTERY_THRESHOLD) {
    return (
      <span style={{ color: colours.error }}>
        {getValueSafely(asset.estimate?.properties?.battery?.battery_percent, '%')}
        {`  (${format(timestamp, 'dd/MM/yyyy - HH:mm')})`}
      </span>
    );
  } else {
    return (
      <span>
        {getValueSafely(asset.estimate?.properties?.battery?.battery_percent, '%')}
        {`  (${format(timestamp, 'dd/MM/yyyy - HH:mm')})`}
      </span>
    );
  }
}
