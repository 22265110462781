import { useQuery } from 'react-query';
import ConfigApi from './config.api';

const getSubtasksQueryKey = (queryKey: string) => {
  return [queryKey];
};
export const useSubTasks = (token: string) => {
  const configApi = new ConfigApi(token);

  return useQuery(getSubtasksQueryKey('config/subtasks'), configApi.getAssetSubtypes);
};
const getAssetTypesQueryKey = (queryKey: string) => {
  return [queryKey];
};
export const useAssetSubTypes = (token: string) => {
  const configApi = new ConfigApi(token);

  return useQuery(getAssetTypesQueryKey('config/assetTypes'), configApi.getAssetSubtypes);
};

const getOwnersQueryKey = (queryKey: string) => {
  return [queryKey];
};
export const useOwners = (token: string) => {
  const configApi = new ConfigApi(token);

  return useQuery(getOwnersQueryKey('config/owners'), configApi.getAssetOwners);
};
