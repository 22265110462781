import React, { createContext, ReactNode, useEffect, useState } from 'react';
import LoadingBars from 'components/Loading/LoadingBars';
import { StyledLoadingContainer } from '../Styled';
import config from '../config';

type VenueContextTypes = {
  buildingFeatures: any;
  levelsFeatures: any;
  unitFeatures: any;
};

export const VenueContext = createContext({} as VenueContextTypes);

type VenueProviderProps = { children: ReactNode };

const VenueProvider = ({ children }: VenueProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);

  // features to store geoJson data required for maps
  const [buildingFeatures, setBuildingFeatures] = useState([]); // buildings info to render geojson from.
  const [levelsFeatures, setLevelsFeatures] = useState([]); //outline of buildings. | Default, when no building selected.
  const [unitFeatures, setUnitFeatures] = useState([]); //outline of rooms, show when building is clicked.

  const value: VenueContextTypes = {
    buildingFeatures,
    levelsFeatures,
    unitFeatures,
  };

  const geojsonDataObj = [
    { key: 'building', setState: setBuildingFeatures, dir: 'buildings/building.geojson' },
    { key: 'levels', setState: setLevelsFeatures, dir: 'buildings/level.geojson' },
    { key: 'unit', setState: setUnitFeatures, dir: 'buildings/unit.geojson' },
  ];

  useEffect(() => {
    //loop through all required files and set features to relevant states.
    // set isLoading to false, after looping through all files.
    let count = 0;

    geojsonDataObj.forEach((file: any) => {
      fetch(`./venues/${config.venue}/${file.dir}`)
        .then((response) => response.json())
        .then((res) => {
          file.setState(res.features);

          count++;
          if (count === geojsonDataObj.length) {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <VenueContext.Provider value={value}>{children}</VenueContext.Provider>
  ) : (
    <StyledLoadingContainer>
      <LoadingBars />
      <p>Loading Map...</p>
    </StyledLoadingContainer>
  );
};

export default VenueProvider;
