import DevicesApi from 'api/devices/devices.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { useContext } from 'react';
import { alertErrorMessage } from 'utils/alerts';
import { getIdToken } from 'utils/utils';
import { RegisteredBeaconContext } from 'views/BeaconManagement/contexts/RegisteredBeacon.context';
import { apiAssetAssignEnum } from 'views/BeaconManagement/enum';

export default function useRegisteredBeaconAPIHandlers() {
  const registeredBeaconContext = useContext(RegisteredBeaconContext);
  const {
    assetAssignOptionSelectedObj,
    activeSortField,
    activeSortDirection,
    setNextIsLoading,
    setIsSorting,
    setActiveSortField,
    setActiveSortDirection,
    isSearching,
    isSearchingAll,
    selectedSearchMenuOptionValue,
    nextToken,
    filteredData,
    setFilteredData,
    setNextToken,
    paginationIndex,
    setPaginationIndex,
    cachedFilteredDataRef,
    nextTokenCachedRef,
    paginationIndexRef,
    assetTotalRef,
    setToggleClearRows,
    toggledClearRows,
    setConfirmationModalOpen,
    setSelectedBeaconDialogIsOpen,
    searchTerm,
    setAssetTotal,
  } = registeredBeaconContext;

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);

  function fetchNextPage() {
    const devicesApi = new DevicesApi(token);

    const currentData = filteredData;
    const assignmentType = assetAssignOptionSelectedObj.value
      ? apiAssetAssignEnum[assetAssignOptionSelectedObj.value]
      : null;

    setNextIsLoading(true);

    devicesApi
      .getRegisteredBeaconsFlexible(
        searchTerm,
        selectedSearchMenuOptionValue,
        assignmentType,
        nextToken,
        activeSortField,
        activeSortDirection,
        isSearchingAll,
      )
      .then((res) => {
        const combinedData = [...currentData, ...res.data.items];
        setNextIsLoading(false);

        setFilteredData(combinedData);
        setNextToken(res.data.next);
        setPaginationIndex(paginationIndex + 1);

        if (!isSearching) {
          // do not cache refs if user is searching, so we can revert, incase they clear search field.
          cachedFilteredDataRef.current = combinedData;
          nextTokenCachedRef.current = res.data.next;
          paginationIndexRef.current = paginationIndex + 1;
        }
      })
      .catch((err) => {
        console.error(err.response);
        alertErrorMessage('Could not retrieve next page');
        setNextIsLoading(false);
      });
  }

  const handleSort = async (column: any, sortDirection: string) => {
    const devicesApi = new DevicesApi(token);

    const assignmentType = assetAssignOptionSelectedObj.value
      ? apiAssetAssignEnum[assetAssignOptionSelectedObj.value]
      : null;

    setIsSorting(true);
    setActiveSortField(column.sortField);
    setActiveSortDirection(sortDirection);
    setToggleClearRows(!toggledClearRows);
    setConfirmationModalOpen(false);
    setSelectedBeaconDialogIsOpen(false);

    devicesApi
      .getRegisteredBeaconsFlexible(
        searchTerm,
        selectedSearchMenuOptionValue,
        assignmentType,
        null, // note nextToken is null  because we are resetting pagination
        column.sortField,
        sortDirection,
        isSearchingAll,
      )
      .then((res: any) => {
        setIsSorting(false);
        setFilteredData(res.data.items);
        setNextToken(res.data.next);
        setAssetTotal(res.data.total);
        setPaginationIndex(0);

        if (!isSearching) {
          // note we do not cache in refs if user is not searching, as we want to revert to them if user is not searching.
          nextTokenCachedRef.current = res.data.next;
          cachedFilteredDataRef.current = res.data.items;
          assetTotalRef.current = res.data.total;
          paginationIndexRef.current = 0;
        }
      })
      .catch((err: any) => {
        console.error(err.response);
        alertErrorMessage('Could not sort results');
        setIsSorting(false);
      });
  };

  return { fetchNextPage, handleSort };
}
