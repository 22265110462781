import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import warning from './warning.svg';

const Warning = styled.span`
  color: ${colours.error};
  padding-right: 20px;
  background: url(${warning}) 25px 0 no-repeat;
  background-size: 15px 15px;
  background-position: right center;
`;

const TableCellWarning: React.FC = (props) => {
  return <Warning>{props.children}</Warning>;
};
export default TableCellWarning;
