import React from 'react';
import { LatestAssetResults } from 'models';
import TableCellWarning from 'components/DataTable/cells/TableCellWarning';
import { getValueSafely } from 'utils/utils';
import { LOW_BATTERY_THRESHOLD } from 'config/constants';

const BatteryCell = (row: LatestAssetResults) => {
  if (row.estimate?.properties?.battery?.battery_percent < LOW_BATTERY_THRESHOLD) {
    return (
      <TableCellWarning>
        {getValueSafely(row.estimate?.properties?.battery?.battery_percent, '%')}
      </TableCellWarning>
    );
  } else {
    return (
      <span className="u-no-pointer-event">
        {getValueSafely(row.estimate?.properties?.battery?.battery_percent, '%')}
      </span>
    );
  }
};

export default BatteryCell;
