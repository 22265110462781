import { useState, useEffect, useMemo } from 'react';
import { Amplify, Cache, Auth } from 'aws-amplify';
import getAmplifyConfig from 'utils/getAmplifyConfig';
import config from 'react-global-configuration';

export type AuthStateTypes = {
  isMock?: boolean;
  isAuthenticated: boolean;
  Session: any;
  authenticationFlowType: string;
  client: any;
  keyPrefix: string;
  pool: any;
  signInUserSession: any;
  storage: any;
  userDataKey: string;
  username: string;
};
export type AuthContextTypes = {
  authState: AuthStateTypes;
  setAuthState: any;
};
//  use localstorage instead of cache, as cache wasnt working correctly with Cypress.
const authStateLocalStorage = JSON.parse(
  localStorage.getItem('aws-amplify-cacheauthState') || '{}',
);

const defaultState = {
  isAuthenticated: false,
  Session: null,
  authenticationFlowType: '',
  client: null,
  keyPrefix: '',
  pool: null,
  signInUserSession: null,
  storage: null,
  userDataKey: '',
  username: '',
};
// check if auth exists in localstorage first and use that, otherwise use default.
const initialAuthState = authStateLocalStorage.data
  ? { ...authStateLocalStorage.data, isAuthenticated: true }
  : defaultState;

const useAuth = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authState, setAuthState] = useState<AuthStateTypes>(initialAuthState);

  const auth = useMemo(() => {
    return Auth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Amplify.configure(getAmplifyConfig(config));

  useEffect(() => {
    // only call auth if authstate not authenticated
    if (!authState.isAuthenticated) {
      setIsAuthenticating(true);
      auth
        .currentAuthenticatedUser()
        .then((res: any) => {
          const newState = { ...res, isAuthenticated: true };
          setAuthState(newState);
          Cache.setItem('authState', newState);
          setIsAuthenticating(false);
        })
        .catch(() => {
          setIsAuthenticating(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = () => {
    Cache.clear();
    return auth.signOut();
  };

  return {
    authState,
    setAuthState,
    signOut,
    isAuthenticating,
  };
};

export default useAuth;
