import React, { useEffect, useState, useContext } from 'react';
import AssetSubTypes from 'components/SelectInput/SelectInputs/AssetSubTypes';
import AssetOwners from 'components/SelectInput/SelectInputs/AssetOwners';
import Button from 'components/Button/Button';
import EntitiesApi from 'api/entities/entities.api';
import useAssetInfo from 'hooks/assetInfo.hook';
import { getIdToken, replaceUnderscoresWithSpaces } from 'utils/utils';
import { alertSuccessMessage, alertErrorMessage } from 'utils/alerts';
import { AuthenticationContext } from 'contexts/authentication.context';
import { useMutation } from 'react-query';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useMustUpdate from 'hooks/mustUpdate.hook';
import { WhiteBg, ButtonsContainer, InputContainer } from './Components';
import { Row, Col } from 'antd';
import {
  MAX_CHARACTERS_IN_ASSET_NAME,
  MIN_CHARACTERS_IN_ASSET_NAME,
  MAX_CHARACTERS_IN_ASSET_DESCRIPTION,
} from 'config/constants';

import {
  StyledLabel,
  RequiredAsterisk,
  StyledInput,
  StyledTextarea,
  StyledError,
  StyledCharactersRemaining,
  StyledFormControlContainer,
} from 'components/FormInput/Styled';
interface IFormInputs {
  assetId: string;
  assetName: string;
  assetOwners: { label: string; value: string };
  assetSubType: { label: string; value: string };
  description: string;
}

const EditAsset: React.FunctionComponent = () => {
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const entitiesApi = new EntitiesApi(token);
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<IFormInputs>();
  const [saveEnabled, setSaveEnabled] = useState(true);

  const navigate = useNavigate();
  const { setMustUpdate } = useMustUpdate();

  const assetDetails = useAssetInfo();

  const [assetNameCharactersRemaining, setAssetNameCharactersRemaining] = useState(
    MAX_CHARACTERS_IN_ASSET_NAME - (assetDetails.entity.properties.name?.length || 0),
  );
  const [assetDescriptionCharactersRemaining, setAssetDescriptionCharactersRemaining] = useState(
    MAX_CHARACTERS_IN_ASSET_DESCRIPTION - (assetDetails.entity.properties.description?.length || 0),
  );
  const putMutation = useMutation((assetDetails: any) => {
    return entitiesApi.updateAssetDetails(assetDetails);
  });

  useEffect(() => {
    if (putMutation.isSuccess) {
      alertSuccessMessage('Changes saved for the asset');
      setMustUpdate(true);
      putMutation.reset();
      navigate(-1);
    }
    if (putMutation.isError) {
      alertErrorMessage('An error occurred. The asset has not been saved');
      putMutation.reset();
    }
  }, [putMutation, navigate, setMustUpdate]);

  const enableSaveButton = () => {
    setSaveEnabled(false);
  };

  const processChanges = (data: any) => {
    const newAssetDetails = {
      modified: new Date().getTime(),
      type: 'asset',
      entity_id: assetDetails.entity.entity_id,
      subtype: data.assetSubType.value,
      properties: {
        description: data.description,
        owner: data.assetOwners.value,
        name: data.assetName,
      },
    };
    return newAssetDetails;
  };
  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    putMutation.mutate(processChanges(data));
  };
  const handleCancel = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    navigate(-1);
  };
  const handleCountCharacters = (
    ev: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
  ) => {
    ev.preventDefault();
    if (ev.currentTarget.id === 'assetName') {
      setAssetNameCharactersRemaining(MAX_CHARACTERS_IN_ASSET_NAME - ev.currentTarget.value.length);
      if (
        ev.currentTarget.value.length >= MIN_CHARACTERS_IN_ASSET_NAME &&
        ev.currentTarget.value.length <= MAX_CHARACTERS_IN_ASSET_NAME
      ) {
        setSaveEnabled(false);
      } else {
        setSaveEnabled(true);
      }
    } else {
      if (ev.currentTarget.value.length <= MAX_CHARACTERS_IN_ASSET_DESCRIPTION) {
        setSaveEnabled(false);
      } else {
        setSaveEnabled(true);
      }
      setAssetDescriptionCharactersRemaining(
        MAX_CHARACTERS_IN_ASSET_DESCRIPTION - ev.currentTarget.value.length,
      );
    }
  };
  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <WhiteBg>
            <Row>
              <Col md={10} lg={10} xl={10}>
                <StyledFormControlContainer>
                  <StyledLabel htmlFor="assetId">
                    Asset Id <RequiredAsterisk />
                  </StyledLabel>
                  <InputContainer>
                    <StyledInput
                      {...register('assetId')}
                      disabled
                      id="assetId"
                      defaultValue={assetDetails.entity.entity_id}
                      onChange={enableSaveButton}
                    />
                  </InputContainer>
                  {errors.assetId && <StyledError>Asset Id is required</StyledError>}
                </StyledFormControlContainer>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 10 }} xl={{ span: 10 }}>
                <StyledFormControlContainer>
                  <StyledLabel htmlFor="assetName">
                    Asset Name <RequiredAsterisk />
                  </StyledLabel>
                  <InputContainer>
                    <StyledInput
                      {...register('assetName', {
                        required: true,
                        minLength: MIN_CHARACTERS_IN_ASSET_NAME,
                        maxLength: MAX_CHARACTERS_IN_ASSET_NAME,
                        validate: async (value) => {
                          if (value === assetDetails.entity.properties.name) {
                            return true;
                          }
                          const itExists = await entitiesApi.checkEntityExists(value);
                          return !itExists;
                        },
                      })}
                      maxLength={MAX_CHARACTERS_IN_ASSET_NAME}
                      className={errors.assetName ? 'error' : ''}
                      id="assetName"
                      defaultValue={assetDetails.entity.properties.name}
                      onChange={handleCountCharacters}
                    />
                  </InputContainer>
                  {errors.assetName?.type === 'required' && (
                    <StyledError id="assetNameValidationMessage">
                      Asset Name is required.
                    </StyledError>
                  )}
                  {errors.assetName?.type === 'maxLength' && (
                    <StyledError id="assetNameValidationMessage">
                      Asset Name must be less than {MAX_CHARACTERS_IN_ASSET_NAME} characters.
                    </StyledError>
                  )}
                  {errors.assetName?.type === 'minLength' && (
                    <StyledError id="assetNameValidationMessage">
                      Asset Name must be greater than {MIN_CHARACTERS_IN_ASSET_NAME} characters.
                    </StyledError>
                  )}
                  {errors.assetName?.type === 'validate' && (
                    <StyledError id="assetNameValidationMessage">
                      Asset with the name specified already exists.
                    </StyledError>
                  )}
                  <StyledCharactersRemaining id="assetNameCharactersRemaining">
                    {MAX_CHARACTERS_IN_ASSET_NAME - assetNameCharactersRemaining <
                    MIN_CHARACTERS_IN_ASSET_NAME
                      ? `Minimum of ${MIN_CHARACTERS_IN_ASSET_NAME} characters required`
                      : `${assetNameCharactersRemaining} characters remaining`}
                  </StyledCharactersRemaining>
                </StyledFormControlContainer>
              </Col>
              <Col
                md={{ span: 10, offset: 1 }}
                lg={{ span: 10, offset: 1 }}
                xl={{ span: 10, offset: 1 }}
              >
                <StyledFormControlContainer>
                  <StyledLabel>
                    Asset Type <RequiredAsterisk />
                  </StyledLabel>
                  <Controller
                    name="assetSubType"
                    control={control}
                    render={({ field }) => (
                      <AssetSubTypes
                        {...field}
                        onInputChange={enableSaveButton}
                        className={errors.assetOwners ? 'error' : ''}
                      />
                    )}
                    defaultValue={{
                      value: assetDetails.entity.subtype,
                      label: replaceUnderscoresWithSpaces(assetDetails.entity.subtype),
                    }}
                  />
                  {errors.assetSubType && (
                    <StyledError id="assetSubtypeValidationMessage">
                      AssetType is required.
                    </StyledError>
                  )}
                </StyledFormControlContainer>
              </Col>
            </Row>
            <Row>
              <Col md={10} lg={{ span: 10 }} xl={{ span: 10 }}>
                <StyledFormControlContainer>
                  <StyledLabel>Owner</StyledLabel>
                  <InputContainer>
                    <Controller
                      name="assetOwners"
                      control={control}
                      render={({ field }) => (
                        <AssetOwners
                          {...field}
                          onInputChange={enableSaveButton}
                          className={errors.assetOwners ? 'error' : ''}
                        />
                      )}
                      defaultValue={{
                        value: assetDetails.entity.properties.owner,
                        label: replaceUnderscoresWithSpaces(assetDetails.entity.properties.owner),
                      }}
                    />
                  </InputContainer>
                  {errors.assetOwners && (
                    <StyledError id="assetOwnerValidationMessage">Owner is required.</StyledError>
                  )}
                </StyledFormControlContainer>
              </Col>
            </Row>
            <Row>
              <Col md={22} lg={{ span: 21 }} xl={{ span: 21 }}>
                <StyledFormControlContainer>
                  <StyledLabel htmlFor="description">Description</StyledLabel>
                  <InputContainer>
                    <StyledTextarea
                      {...register('description', {
                        maxLength: MAX_CHARACTERS_IN_ASSET_DESCRIPTION,
                      })}
                      defaultValue={assetDetails.entity.properties.description}
                      maxLength={MAX_CHARACTERS_IN_ASSET_DESCRIPTION}
                      onChange={handleCountCharacters}
                      id="assetDescription"
                      className={errors.description ? 'error' : ''}
                    />
                  </InputContainer>
                  <StyledCharactersRemaining id="assetDescriptionCharactersRemaining">
                    {assetDescriptionCharactersRemaining} characters
                  </StyledCharactersRemaining>
                  {errors.description && (
                    <StyledError id="assetDescriptionValidationMessage">
                      Description must be less than {MAX_CHARACTERS_IN_ASSET_DESCRIPTION}{' '}
                      characters.
                    </StyledError>
                  )}
                </StyledFormControlContainer>
              </Col>
            </Row>
          </WhiteBg>
          <ButtonsContainer>
            <Button outline onClick={handleCancel} id="cancelEditAsset" className="biggerButton">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={saveEnabled}
              id="saveEditAsset"
              className="biggerButton"
            >
              Save
            </Button>
          </ButtonsContainer>
        </form>
      </div>
    </>
  );
};
export default EditAsset;
