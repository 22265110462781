import { useContext, useEffect, useState } from 'react';
import { AssetsContext } from '../contexts/assets.context';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenueContext } from '../contexts/venue.context';
import {
  getAssociatedLevelIDsArrayFromOrdinal,
  getFloorAssetCountFromLevelIDArray,
  getFloorIndexFromLevelsOrdinal,
  getLevelFeatureDataFromBuildingID,
  getLevelFeaturesWithUniqueOrdinals,
  getLowestOrdinalThatHasAsset,
  getSortedFeaturesByOrdinal,
} from '../utils';

export default function useFloorSelectionHooks() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { levelsFeatures } = useContext(VenueContext);
  const { assetMarkers } = useContext(AssetsContext);
  const { searchCleared, buildingSelectedID, apiCallInProgress, setfloorSelectedIDArray } =
    useContext(MapControlContext);

  const associatedBuildingLevelFeatures = getLevelFeatureDataFromBuildingID(
    buildingSelectedID,
    levelsFeatures,
  );

  const levelsWithUniqueOrdinals = getLevelFeaturesWithUniqueOrdinals(
    associatedBuildingLevelFeatures,
  );

  const sortedLevelsWithUniqueOrdinals = getSortedFeaturesByOrdinal(levelsWithUniqueOrdinals);

  function getOrdinalFromConditions() {
    // function to determine what floor should be selected based on various conditions.
    const noSearchResults = assetMarkers.length < 1;

    // determine lowest floor that has an asset.
    const lowestOrdinalWithAsset = getLowestOrdinalThatHasAsset(
      associatedBuildingLevelFeatures,
      assetMarkers,
    );
    let ordinalToUse = lowestOrdinalWithAsset;

    if (sortedLevelsWithUniqueOrdinals[selectedIndex]) {
      const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
        sortedLevelsWithUniqueOrdinals[selectedIndex].properties.ordinal,
        associatedBuildingLevelFeatures,
      );
      const currentFloorAssetCount = getFloorAssetCountFromLevelIDArray(
        levelIDsArray,
        assetMarkers,
      );
      // keep ordinal at current index if no search results.
      // OR current floor has asset from search
      if (noSearchResults || currentFloorAssetCount > 0) {
        ordinalToUse = selectedIndex;
      }
      // otherwise, return lowest ordinal with asset.
    }
    return ordinalToUse;
  }

  useEffect(() => {
    // FLOOR CHANGING LOGIC
    // when selectedBuildingID changes, and when search has taken place.
    // do not change floor, if user has cleared search

    if (!apiCallInProgress && !searchCleared) {
      const ordinalToUse = getOrdinalFromConditions();

      const ordinalFloorIndex = getFloorIndexFromLevelsOrdinal(
        ordinalToUse,
        sortedLevelsWithUniqueOrdinals,
      );
      if (sortedLevelsWithUniqueOrdinals[ordinalFloorIndex]) {
        const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
          sortedLevelsWithUniqueOrdinals[ordinalFloorIndex].properties.ordinal,
          associatedBuildingLevelFeatures,
        );
        // set selected button to this index, and set the floor ID of this too
        setfloorSelectedIDArray(levelIDsArray);
        setSelectedIndex(ordinalFloorIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingSelectedID, apiCallInProgress]);

  return {
    associatedBuildingLevelFeatures,
    sortedLevelsWithUniqueOrdinals,
    selectedIndex,
    setSelectedIndex,
  };
}
