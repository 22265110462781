import React from 'react';
import { message } from 'antd';
import SuccessAlert from 'components/Alert/SuccessAlert';
import ErrorAlert from 'components/Alert/ErrorAlert';

export const alertErrorMessage = (messageString: string) => {
  message.config({ maxCount: 1 });

  message.error(<ErrorAlert messageString={messageString} />, 6, message.destroy);
};

export const alertSuccessMessage = (messageString: string) => {
  message.config({ maxCount: 1 });
  message.success(<SuccessAlert messageString={messageString} />, 6, message.destroy);
};
