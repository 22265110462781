/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns/esm';
import { LatestBeacon } from 'models';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { humaniseStringCasing } from 'utils/utils';
import { StyledBigTooltipInner } from '../../Styled';
import { getSafeValueFromObject } from '../../utils';
import infoImg from '../../icons/info.svg';
import closeImg from '../../icons/close.svg';
import LastSeenReadoutSpan from '../InfoBoxSpans/LastSeenReadoutSpan';
import AccuracyRangeSpan from '../InfoBoxSpans/AccuracyRangeSpan';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';
import LocationSpan from '../InfoBoxSpans/LocationSpan';

type AssetMarkerLargeInfoboxTooltipType = {
  asset: LatestBeacon;
  handleCloseInfoBox: () => void;
};

export default function LargeInfoBoxTooltip({
  asset,
  handleCloseInfoBox,
}: AssetMarkerLargeInfoboxTooltipType) {
  const createdString = asset.entity ? format(asset.entity?.created, 'dd/MM/yyyy - HH:mm') : '--';
  const assetIsMobile = asset.estimate.properties.mobility_state === 'mobile';

  return (
    <Tooltip
      className="tooltip-large"
      interactive={true}
      opacity={1}
      direction="left"
      permanent
      offset={[-20, 0]}
    >
      <StyledBigTooltipInner>
        <img
          alt="close"
          className="close-button"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          src={closeImg}
          onClick={() => {
            handleCloseInfoBox();
          }}
        />
        <div className="tooltip-inner-content">
          <div className="information-line">
            <h4>Asset Name:</h4>
            <span>{getSafeValueFromObject(asset.entity.properties.name)}</span>
          </div>
          <div className="information-line">
            <h4>Asset Type:</h4>
            <span>{humaniseStringCasing(getSafeValueFromObject(asset.entity?.subtype))}</span>
          </div>
          <div className="information-line">
            <h4>Asset ID:</h4>
            <span>{getSafeValueFromObject(asset.estimate.device_id)}</span>
          </div>
          <div className="information-line">
            <h4>Added On:</h4>
            <span>{createdString}</span>
          </div>
          <div className="information-line">
            <h4>Owner:</h4>
            <span>{getSafeValueFromObject(asset.entity.properties.owner)}</span>
          </div>
          <div className="information-line">
            <h4>Description:</h4>
            <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
              {getSafeValueFromObject(asset.entity.properties.description)}
            </span>
          </div>
        </div>

        <div className="hr"></div>

        <div className="tooltip-inner-content">
          <div className="information-sentance">
            <img src={infoImg} alt="information" />
            <p>Please note, estimated information was derived from the beacon.</p>
          </div>
          <div className="information-line">
            <h4>Last Seen:</h4>
            <LastSeenReadoutSpan timestamp={asset.estimate.timestamp} />
          </div>
          <div className="information-line">
            <h4>Accuracy Range:</h4>
            <AccuracyRangeSpan asset={asset} />
          </div>
          <div className="information-line">
            <h4>Status:</h4>
            <span>{assetIsMobile ? 'Moving' : 'Static'}</span>
          </div>
          <div className="information-line">
            <h4>Battery:</h4>
            <BatteryReadoutSpan asset={asset} />
          </div>
          <div className="information-line">
            <h4>Location:</h4>
            <LocationSpan asset={asset} />
          </div>
          <div className="information-line">
            <h4>Beacon ID:</h4>
            <span>{getSafeValueFromObject(asset.estimate.device_id)}</span>
          </div>
          <div className="information-line">
            <h4>Beacon Type:</h4>
            <span>
              {getSafeValueFromObject(asset.device?.type)} -{' '}
              {getSafeValueFromObject(asset.device?.source)}
            </span>
          </div>
        </div>
      </StyledBigTooltipInner>
    </Tooltip>
  );
}
