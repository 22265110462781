import React, { createContext, ReactNode, useState } from 'react';

type MapControlContextTypes = {
  assetMarkerInfoChecked: boolean;
  setAssetMarkerInfoChecked: (arg: boolean) => void;
  floorSelectedIDArray: string[];
  setfloorSelectedIDArray: (arg: string[]) => void;
  buildingSelectedLevelID: string;
  setBuildingSelectedLevelID: (arg: string) => void;
  buildingSelectedID: string;
  setBuildingSelectedID: (arg: string) => void;
  buildingIsSelected: boolean;
  clickedMarkerID: string;
  setClickedMarkerID: (arg: string) => void;
  apiCallInProgress: boolean;
  setApiCallInProgress: (arg: boolean) => void;
  recallGetAssets: boolean;
  setRecallGetAssets: (arg: boolean) => void;
  searchCleared: boolean;
  setSearchCleared: (arg: boolean) => void;
  inSearchMode: boolean;
  setInSearchMode: (arg: boolean) => void;
  inFilterMode: boolean;
  setInFilterMode: (arg: boolean) => void;
  setNoSubtypesSelected: (arg: boolean) => void;
  setNoOwnersSelected: (arg: boolean) => void;
  noSubTypesOrOwnersSelected: boolean;
};
export const MapControlContext = createContext({} as MapControlContextTypes);

type MapControlProviderProps = { children: ReactNode };

const MapControlProvider = ({ children }: MapControlProviderProps) => {
  const [buildingSelectedID, setBuildingSelectedID] = useState('');
  const [buildingSelectedLevelID, setBuildingSelectedLevelID] = useState('');
  const [assetMarkerInfoChecked, setAssetMarkerInfoChecked] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  // search
  const [searchCleared, setSearchCleared] = useState(false);
  const [inSearchMode, setInSearchMode] = useState(false);
  const [inFilterMode, setInFilterMode] = useState(false);

  const [recallGetAssets, setRecallGetAssets] = useState(true); // set to true for inital load.
  const [floorSelectedIDArray, setfloorSelectedIDArray] = useState(['']);
  const [clickedMarkerID, setClickedMarkerID] = useState('');

  const [noSubtypesSelected, setNoSubtypesSelected] = useState(false);
  const [noOwnersSelected, setNoOwnersSelected] = useState(false);

  const noSubTypesOrOwnersSelected = noOwnersSelected || noSubtypesSelected;
  const buildingIsSelected = buildingSelectedLevelID !== '';

  const value: MapControlContextTypes = {
    assetMarkerInfoChecked,
    setAssetMarkerInfoChecked,
    floorSelectedIDArray,
    setfloorSelectedIDArray,
    buildingSelectedLevelID,
    setBuildingSelectedLevelID,
    buildingSelectedID,
    setBuildingSelectedID,
    buildingIsSelected,
    clickedMarkerID,
    setClickedMarkerID,
    apiCallInProgress,
    setApiCallInProgress,
    recallGetAssets,
    setRecallGetAssets,
    searchCleared,
    setSearchCleared,
    inSearchMode,
    setInSearchMode,
    inFilterMode,
    setInFilterMode,
    setNoSubtypesSelected,
    setNoOwnersSelected,
    noSubTypesOrOwnersSelected,
  };

  return <MapControlContext.Provider value={value}>{children}</MapControlContext.Provider>;
};

export default MapControlProvider;
