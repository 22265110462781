import React from 'react';
import styled from 'styled-components';
import clearFieldIcon from 'assets/icons/clear-field.svg';

const StyledButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export default function ClearInputButton({ onClick }: any) {
  return (
    <StyledButton onClick={(e) => onClick(e)} className="clear-input-button">
      <img src={clearFieldIcon} alt="clear" />
    </StyledButton>
  );
}
