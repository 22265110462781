import { latLngBounds, LatLngExpression } from 'leaflet';
import { LatestBeacon } from 'models';
import { useMap } from 'react-leaflet';
import { getCoordsFromAssets } from '../utils';

export default function useMapHooks() {
  const map = useMap();

  function recenterMapToAssetBounds(assets: LatestBeacon[]) {
    if (assets.length < 1) return;
    const coords: any = getCoordsFromAssets(assets);
    const bounds = latLngBounds(coords[0]);

    bounds.extend(coords);
    map.flyToBounds(bounds, {
      maxZoom: 21,
      padding: [100, 100],
    });
  }

  function removeMapLayerByClassName(className: string) {
    // programatically remove map tooltips,
    map.eachLayer((layer: any) => {
      if (layer.options && layer.options.className === className) {
        map.removeLayer(layer);
      }
    });
  }
  function panToLatLong(eTargetLatLong: LatLngExpression) {
    map.panTo(eTargetLatLong);
  }

  return { removeMapLayerByClassName, recenterMapToAssetBounds, panToLatLong };
}
