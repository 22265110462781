import { LatestBeacon } from 'models';
import React from 'react';
import { dateDiff, getValueSafely } from 'utils/utils';

export default function LocationSpan({ asset }: { asset: LatestBeacon }) {
  if (dateDiff(new Date(parseInt(asset.estimate?.timestamp, 10)), new Date()) > 3600)
    if (asset.estimate?.location !== undefined) {
      return (
        <span>
          <div>
            {asset.estimate.location?.building?.building_level_name}/{' '}
            {asset.estimate.location?.building?.building_names}
          </div>

          <div style={{ color: '#CCCCCB', margin: '5px 0' }}>Possible region(s):</div>
          <div>{getValueSafely(asset.estimate?.location?.region.name)}</div>
        </span>
      );
    }
  return <span>––</span>;
}
