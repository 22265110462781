import React from 'react';
import Button from 'components/Button/Button';
import { Auth } from 'aws-amplify';

export default function SignoutPage() {
  function handleSignIn() {
    Auth.federatedSignIn();
  }
  return (
    <main className="u-flex-center">
      <div className="container">
        <h1>You Are Signed Out</h1>
        <p>Please click the button below to sign in:</p>
        <br />
        <Button onClick={handleSignIn}>Sign In</Button>
      </div>
    </main>
  );
}
