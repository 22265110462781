import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'assets/styles/animation.scss';

export default function ComponentAnimationWrapper({ children }: { children: any }) {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <CSSTransition in={animated} timeout={1} classNames="component-animation">
      {children}
    </CSSTransition>
  );
}
