import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import routes from './routes';
import PreRoute from './PreRoute';
import Navigation from 'components/Navigation/Navigation';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/authentication/callback" element={<Navigate to="/" />} />

        {routes.map((route) => {
          return route.isPrivate ? (
            <Route
              key={route.url}
              path={route.url}
              element={
                <PrivateRoute>
                  <PreRoute route={route}>
                    <>
                      {route.header && (
                        <>
                          <Navigation navCollapsedDefault={route.navCollapsedDefault} />
                          {React.createElement(route.header, { pageTitle: route.title })}
                        </>
                      )}
                      {React.createElement(route.component)}
                    </>
                  </PreRoute>
                </PrivateRoute>
              }
            />
          ) : (
            <Route
              key={route.url}
              path={route.url}
              element={<PreRoute route={route}>{React.createElement(route.component)}</PreRoute>}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}
