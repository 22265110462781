import { colours } from 'styling/colours';

export const datatableStyle = {
  table: {
    style: {
      background: `${colours.background}`,
    },
  },
  headRow: {
    style: {
      background: `${colours.background}`,
      border: 'none',
      marginBottom: '-10px',
    },
  },
  headCells: {
    style: {
      fontFamily: 'MuseoSlab',
      color: `${colours.primary}`,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '1px',
    },
    activeSortStyle: {
      color: `${colours.primary}`,
    },
  },
  rows: {
    style: {
      margin: '0 0 10px 0',
      borderBottomColor: `${colours.lightBorder}!important`,
      border: `1px solid ${colours.lightBorder}`,
      padding: `0`,
      borderRadius: '6px',
      cursor: 'pointer',
      height: '78px',
      fontSize: '15px',
    },
  },
  rowCells: {
    style: {
      fontSize: '15px',
      lineHeight: '18px',
      color: `${colours.primaryText}`,
    },
  },
};

export const datatableBeaconStyle = {
  // copy and override styles from above.
  ...datatableStyle,
  rows: {
    style: {
      ...datatableStyle.rows.style,
      cursor: 'auto',
    },
  },
};
