import React from 'react';
import HeaderAssetSearch from 'components/Header/HeaderAssetSearch';
import HeaderDefault from 'components/Header/HeaderDefault';

// pages
import EditAsset from 'views/EditAsset/EditAsset';
import BeaconManagement from 'views/BeaconManagement/BeaconManagement';
import NotFoundPage from 'views/NotFoundPage/NotFoundPage';
import UnauthenticatedPage from 'views/UnauthenticatedPage/UnauthenticatedPage';
import SignoutPage from 'views/SignoutPage/SignoutPage';
import SupportPage from 'views/SupportPage/SupportPage';
import AssetListPage from 'views/AssetListPage/AssetListPage';
import NoPermissionsPage from 'views/NoPermissionsPage/NoPermissionsPage';
import AssetsMap from 'views/AssetsMapPage/AssetsMapPage';
import ErrorPageView from 'views/ErrorPage/ErrorPage';

export type routesType = {
  url: string;
  title: string;
  isPrivate: boolean;
  header: any | boolean;
  component: React.FC;
  navCollapsedDefault: boolean;
};

const routes: routesType[] = [
  {
    url: 'edit',
    title: 'Edit Asset',
    isPrivate: true,
    header: HeaderDefault,
    component: EditAsset,
    navCollapsedDefault: false,
  },
  {
    url: 'beacon-management',
    title: 'Beacon Management',
    isPrivate: true,
    header: HeaderDefault,
    component: BeaconManagement,
    navCollapsedDefault: false,
  },
  {
    url: 'map',
    title: 'Map View',
    isPrivate: true,
    header: HeaderDefault,
    component: AssetsMap,
    navCollapsedDefault: true,
  },

  {
    url: 'unauthenticated',
    title: 'Not Authenticated',
    isPrivate: false,
    header: false,
    component: UnauthenticatedPage,
    navCollapsedDefault: false,
  },
  {
    url: 'no-permission',
    title: 'You do not have Permission',
    isPrivate: false,
    header: false,
    component: NoPermissionsPage,
    navCollapsedDefault: false,
  },
  {
    url: 'signout',
    title: 'Sign Out',
    isPrivate: false,
    header: false,
    component: SignoutPage,
    navCollapsedDefault: false,
  },
  {
    url: 'support',
    title: 'Support',
    isPrivate: true,
    header: HeaderDefault,
    component: SupportPage,
    navCollapsedDefault: false,
  },
  {
    url: 'error',
    title: 'Error',
    isPrivate: false,
    header: false,
    component: ErrorPageView,
    navCollapsedDefault: false,
  },
  {
    url: '/',
    title: 'Asset List',
    isPrivate: true,
    header: HeaderAssetSearch,
    component: AssetListPage,
    navCollapsedDefault: false,
  },
  {
    url: '*',
    title: 'Not Found',
    isPrivate: false,
    header: false,
    component: NotFoundPage,
    navCollapsedDefault: false,
  },
];

export default routes;
