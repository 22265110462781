import React, { useEffect } from 'react';
import { routesType } from './routes';

const PreRoute = ({ children, route }: { children: React.ReactElement; route: routesType }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.url]);

  return children;
};

export default PreRoute;
