import React, { useContext } from 'react';
import SelectInput from '../SelectInput';
import { useAssetSubTypes } from 'api/config/config.hooks';
import SelectInputStatusWrapper from '../SelectInputWrapper';
import { getIdToken } from 'utils/utils';
import StyledSelectContainer from './StyledSelectContainer';
import { AuthenticationContext } from 'contexts/authentication.context';

type AssetSubTypeProps = {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  ref: any;
  className?: string;
};
interface IAssetSubType {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  ref: any;
}

const AssetSubTypesEl: React.FunctionComponent<IAssetSubType> = (props: AssetSubTypeProps) => {
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const { data, error, status } = useAssetSubTypes(token);
  return (
    <StyledSelectContainer data-test-id="asset-type-options">
      <SelectInputStatusWrapper error={error} status={status}>
        <SelectInput
          id="assetSubType"
          name="assetSubType"
          aria-label="Asset Type"
          placeholder="Enter the Keyword"
          options={data}
          {...props}
        />
      </SelectInputStatusWrapper>
    </StyledSelectContainer>
  );
};
const AssetSubTypes = React.forwardRef((props: AssetSubTypeProps, ref: any) => (
  <AssetSubTypesEl {...props} ref={ref} />
));

export default AssetSubTypes;
