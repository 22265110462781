import React, { useContext } from 'react';
import SelectInput from '../SelectInput';
import { useOwners } from 'api/config/config.hooks';
import SelectInputStatusWrapper from '../SelectInputWrapper';
import { getIdToken } from 'utils/utils';
import { AuthenticationContext } from 'contexts/authentication.context';
import StyledSelectContainer from './StyledSelectContainer';

type AssetOwnerProps = {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  isDisabled?: boolean;
  className?: string;
};
interface IAssetOwner {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  ref: any;
  className?: string;
}
const AssetOwnersEl: React.FunctionComponent<IAssetOwner> = (props: AssetOwnerProps) => {
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const { data, error, status } = useOwners(token);
  return (
    <StyledSelectContainer data-test-id="asset-owner-options">
      <SelectInputStatusWrapper error={error} status={status}>
        <SelectInput
          id="assetOwners"
          name="assetOwners"
          aria-label="Owner"
          placeholder="Enter the Keyword"
          options={data}
          {...props}
        />
      </SelectInputStatusWrapper>
    </StyledSelectContainer>
  );
};
const AssetOwners = React.forwardRef((props: AssetOwnerProps, ref: any) => (
  <AssetOwnersEl {...props} ref={ref} />
));
export default AssetOwners;
