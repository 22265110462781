import React, { useContext } from 'react';
import AssetMapSearchFilterForm from '../components/SearchFilterBar/AssetMapSearchFilterForm';
import { VenueContext } from '../contexts/venue.context';
import { AssetsContext } from '../contexts/assets.context';
import MapComponent from '../components/MapComponent';
import AssetMapTopFilterBar from '../components/AssetMapTopFilterBar';
import { StyledMapFilterContainer } from '../Styled';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { useEffect } from 'react';
import L from 'leaflet';

export default function MapController() {
  const { buildingIsSelected, setBuildingSelectedLevelID, apiCallInProgress } =
    useContext(MapControlContext);
  const { levelsFeatures } = useContext(VenueContext);
  const { assetMarkers } = useContext(AssetsContext);

  const bounds = L.geoJSON(levelsFeatures).getBounds();

  useEffect(() => {
    // always set building selected to null on initial render of layer.
    setBuildingSelectedLevelID('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AssetMapSearchFilterForm />
      <StyledMapFilterContainer>
        <AssetMapTopFilterBar />
        <MapComponent
          assetMarkers={assetMarkers}
          bounds={bounds}
          buildingIsSelected={buildingIsSelected}
          apiCallInProgress={apiCallInProgress}
        />
      </StyledMapFilterContainer>
    </>
  );
}
