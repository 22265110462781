import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import FormInputGroup from 'components/FormInput/FormInputGroup';
import { Flex, Item } from 'react-flex-ready';
import AssetOwners from 'components/SelectInput/SelectInputs/AssetOwners';
import FormFieldsetLabelContainer from 'components/FormInput/FormFieldsetLabelContainer';
import AssetSubTypes from 'components/SelectInput/SelectInputs/AssetSubTypes';
import {
  MAX_CHARACTERS_IN_ASSET_NAME,
  MIN_CHARACTERS_IN_ASSET_NAME,
  MAX_CHARACTERS_IN_ASSET_DESCRIPTION,
} from 'config/constants';
import { objIsEmpty } from 'utils/utils';

const StyledDiv = styled.div`
  header {
    margin-bottom: 40px;
    h2 {
      font-size: 18px;
      color: ${colours.secondaryText};
      font-weight: 600;
    }
  }
`;

type AssetInformationPanelFormTypes = {
  handleSubmit: any;
  setSubmitEnabled: (param: boolean) => void;
  register: any;
  errors: any;
  watch: any;
  Controller: any;
  control: any;
};

const assetNameValidation = {
  required: true,
  maxLength: MAX_CHARACTERS_IN_ASSET_NAME,
  minLength: MIN_CHARACTERS_IN_ASSET_NAME,
};
const descriptionValidation = { required: false, maxLength: MAX_CHARACTERS_IN_ASSET_DESCRIPTION };

export default function AssetInformationPanelForm({
  setSubmitEnabled,
  register,
  errors,
  watch,
  Controller,
  control,
}: AssetInformationPanelFormTypes) {
  const [formFieldsValid, setFormFieldsValid] = useState(false);
  const initialAssetNameVal = useRef('');

  setSubmitEnabled(objIsEmpty(errors) && formFieldsValid); // check no errors, and formfields are valid.

  useEffect(() => {
    // watch for field changes, and set formValidation to true.
    watch(() => {
      const watchFields = watch(['assetName', 'assetSubType', 'assetOwner', 'description']);
      const assetNameChanged = initialAssetNameVal.current !== watchFields[0];
      const assetSubTypeSelected = watchFields[1]?.value !== undefined;
      const assetOwnerSelected = watchFields[2]?.value !== undefined;
      const fieldsValid = assetNameChanged && assetSubTypeSelected && assetOwnerSelected;

      setFormFieldsValid(fieldsValid);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <StyledDiv>
      <header>
        <h2>ASSET INFORMATION</h2>
      </header>
      <form style={{ maxWidth: '740px', width: '100%' }}>
        <Flex col={12} align="flex-start">
          <Item key="col1" col={6} gap={0}>
            <FormInputGroup
              register={register}
              errors={errors}
              fieldID="assetName"
              fieldName="Asset Name"
              type="text"
              isRequired
              validationObj={assetNameValidation}
            />
          </Item>

          <Item key="col2" col={6} gap={5}>
            <FormFieldsetLabelContainer fieldID="assetType" fieldName="Asset Type" isRequired>
              <Controller
                name="assetSubType"
                control={control}
                rules={{ required: true }}
                render={({ field }: any) => <AssetSubTypes {...field} />}
              />
            </FormFieldsetLabelContainer>
          </Item>
        </Flex>
        <Flex col={12} align="flex-start">
          <Item key="col3" col={12} gap={0}>
            <FormInputGroup
              register={register}
              errors={errors}
              fieldID="description"
              fieldName="Description"
              type="text"
              validationObj={descriptionValidation}
            />
          </Item>
        </Flex>

        <Flex col={12} align="flex-start">
          <Item key="col4" col={6} gap={0} className="asset-owner-field">
            <FormFieldsetLabelContainer fieldID="assetOwners" fieldName="Owner" isRequired>
              <Controller
                name="assetOwner"
                control={control}
                rules={{ required: true }}
                render={({ field }: any) => <AssetOwners {...field} />}
              />
            </FormFieldsetLabelContainer>
          </Item>
        </Flex>
      </form>
    </StyledDiv>
  );
}
