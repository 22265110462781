import { useContext } from 'react';
import { TableColumn } from 'react-data-table-component';
import { LatestAssetResults, LatestBeacon } from 'models';
import { getIdToken } from 'utils/utils';
import { AuthenticationContext } from 'contexts/authentication.context';
import EstimatesApi from 'api/estimates/estimates.api';
import useAssetPage from 'hooks/useAssetPageContext.hook';
import usePageInfo from 'hooks/pageInfo.hook';

export default function useAssetPageHandlers() {
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const { setHeaderAssetCount } = usePageInfo();

  const {
    searchTerm,
    searchCategory,
    setIsSearching,
    sortColumnField,
    sortDirection,
    setSortColumnField,
    setSortDirection,
    assetTableData,
    setAssetTableData,
    setIsSorting,
    setIsLoading,
    nextToken,
    setNextToken,
    setNetworkError,
    isSearching,
    cachedAssetTableDataRef,
    cachedAssetCountRef,
    cachedNextTokenRef,
    setFetchingNextPage,
    setPaginationIndex,
    paginationIndex,
    cachedPaginationIndexRef,
  } = useAssetPage();

  function getSearchAPICall() {
    const estimatesAPI = new EstimatesApi(token);

    const hasEnterSearch = searchCategory === ''; // enter search will have no category assigned.

    const apiCall = hasEnterSearch
      ? estimatesAPI.getAllOptionsSearchEstimates
      : estimatesAPI.getSingleOptionSearchEstimates;

    return apiCall;
  }

  function getSearchAPICallArgs(nextToken: string) {
    const hasEnterSearch = searchCategory === ''; // enter search will have no category assigned.

    const args = hasEnterSearch
      ? [searchTerm, nextToken, sortColumnField, sortDirection]
      : [searchTerm, searchCategory, nextToken, sortColumnField, sortDirection];
    return args;
  }

  const callFetchEstimatesSearch = async (): Promise<any> => {
    const estimatesAPI = new EstimatesApi(token);

    const hasEnterSearch = searchCategory === ''; // enter search will have no category assigned.

    const apiCall = hasEnterSearch
      ? estimatesAPI.getAllOptionsSearchEstimates
      : estimatesAPI.getSingleOptionSearchEstimates;

    const args = hasEnterSearch
      ? [searchTerm, '', sortColumnField, sortDirection]
      : [searchTerm, searchCategory, '', sortColumnField, sortDirection];

    apiCall(...args)
      .then((res) => {
        setIsSearching(true);
        setHeaderAssetCount(res.total);
        setAssetTableData(res.results);
        setNextToken(res.next);
        setIsLoading(false);
        setIsSorting(false);
        setPaginationIndex(0); // reset pagination to 0.
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const callFetchEstimates = async (): Promise<any> => {
    const estimatesAPI = new EstimatesApi(token);

    setIsLoading(true);

    estimatesAPI
      .getEstimates(nextToken, sortColumnField, sortDirection)
      .then((res: any) => {
        setHeaderAssetCount(res.total);
        setAssetTableData(res.results);
        setNextToken(res.next);

        setIsLoading(false);
        setIsSorting(false);

        if (!isSearching) {
          // do not cache refs if user is searching, so we can revert, incase they clear search field.
          cachedAssetCountRef.current = res.total;
          cachedAssetTableDataRef.current = res.results;
          cachedNextTokenRef.current = res.next;
          cachedPaginationIndexRef.current = paginationIndex;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsSearching(false);
        setNetworkError(err);
      });
  };

  const callFetchEstimatesLoop = async (): Promise<any> => {
    // call estimates API in loop to meet the pagination index count.
    // use the next token from each calls res for the next iteration.
    // combine results and set total once pagination index is met.
    setIsLoading(true);
    let combinedResults: LatestBeacon[] = [];
    let assetTotal = 0;
    let internalNextToken = '';

    for (let index = 0; index <= paginationIndex; index++) {
      await iterationCall(index);
    }

    async function iterationCall(index: number) {
      const estimatesAPI = new EstimatesApi(token);
      const apiCall = isSearching ? getSearchAPICall() : estimatesAPI.getEstimates;
      const args = isSearching
        ? getSearchAPICallArgs(internalNextToken)
        : [internalNextToken, sortColumnField, sortDirection];

      await apiCall(...args)
        .then((res: any) => {
          assetTotal = res.total;
          combinedResults = [...combinedResults, ...res.results];
          internalNextToken = res.next;

          setIsLoading(false);
          setIsSorting(false);

          if (index === paginationIndex) {
            setHeaderAssetCount(assetTotal);
            setAssetTableData(combinedResults);
            setNextToken(internalNextToken);
            setIsLoading(false);
            setIsSorting(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setIsSearching(false);
          setNetworkError(err);
        });
    }
  };

  const handleSort = async (column: TableColumn<LatestAssetResults>, direction: string) => {
    const estimatesAPI = new EstimatesApi(token);

    setIsSorting(true);
    setSortColumnField(column.sortField);
    setSortDirection(direction);

    estimatesAPI
      .getEstimates('', column.sortField, direction, searchTerm, searchCategory) // empty null token, to reset pagination on sort.
      .then((res: any) => {
        setHeaderAssetCount(res.total);
        setAssetTableData(res.results);
        setNextToken(res.next);
        setIsLoading(false);
        setIsSorting(false);
        setFetchingNextPage(false);
        setPaginationIndex(0);

        if (!isSearching) {
          // do not cache refs if user is searching, so we can revert, incase they clear search field.
          cachedAssetCountRef.current = res.total;
          cachedAssetTableDataRef.current = res.results;
          cachedNextTokenRef.current = res.next;
          cachedPaginationIndexRef.current = paginationIndex;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        setFetchingNextPage(false);
        setNetworkError(err);
      });
  };

  const handlePagination = () => {
    const estimatesAPI = new EstimatesApi(token);

    setFetchingNextPage(true);

    estimatesAPI
      .getEstimates(nextToken, sortColumnField, sortDirection, searchTerm, searchCategory)
      .then((res: any) => {
        const combinedResults: LatestAssetResults[] = [...assetTableData, ...res.results];

        setAssetTableData(combinedResults);
        setNextToken(res.next);
        setIsLoading(false);
        setIsSorting(false);
        setFetchingNextPage(false);
        setPaginationIndex(paginationIndex + 1);

        if (!isSearching) {
          // do not cache refs if user is searching, so we can revert, incase they clear search field.
          cachedAssetTableDataRef.current = combinedResults;
          cachedNextTokenRef.current = res.next;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setNetworkError(err);
        console.error(err);
        setFetchingNextPage(false);
      });
  };

  function handleEnterSearch(searchTerm: string) {
    const estimatesAPI = new EstimatesApi(token);

    estimatesAPI
      .getAllOptionsSearchEstimates(searchTerm, '', sortColumnField, sortDirection)
      .then((res) => {
        setIsSearching(true);
        setHeaderAssetCount(res.total);
        setAssetTableData(res.results);
        setNextToken(res.next);
        setIsLoading(false);
        setIsSorting(false);
        setPaginationIndex(0);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }

  function handleClickOptionSearch(searchTerm: string, searchCategory: string) {
    const estimatesAPI = new EstimatesApi(token);

    estimatesAPI
      .getSingleOptionSearchEstimates(
        searchTerm,
        searchCategory,
        '',
        sortColumnField,
        sortDirection,
      )
      .then((res) => {
        setIsSearching(true);

        setHeaderAssetCount(res.total);
        setAssetTableData(res.results);
        setNextToken(res.next);
        setIsLoading(false);
        setIsSorting(false);
        setPaginationIndex(0);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }

  return {
    callFetchEstimatesSearch,
    callFetchEstimates,
    callFetchEstimatesLoop,
    handleSort,
    handlePagination,
    handleEnterSearch,
    handleClickOptionSearch,
  };
}
