import React from 'react';
import { humaniseStringCasing } from 'utils/utils';

export default function DropdownControlReadout({
  checkedList,
  checkAll,
}: {
  checkedList: string[];
  checkAll: boolean;
}) {
  let readoutString = '';
  const singleSelected = checkedList.length === 1;

  if (checkAll) readoutString = 'all';
  else {
    readoutString = singleSelected ? checkedList[0] : '';
  }

  return (
    <div>
      {`${humaniseStringCasing(readoutString)}`}
      {!singleSelected && ` (${checkedList.length})`}
    </div>
  );
}
