import axios from 'axios';
import config from 'react-global-configuration';

export function callGetUserInfo(token: string) {
  const REACT_CONFIG = config.get();
  const cognitoDomain = REACT_CONFIG.COGNITO_DOMAIN;

  return axios.get(`https://${cognitoDomain}/oauth2/userInfo`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
