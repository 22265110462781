import useAuth from 'hooks/useAuth.hook';
import React, { createContext, ReactNode } from 'react';
import { AuthContextTypes } from 'hooks/useAuth.hook';
import LoadingScreen from 'components/Loading/LoadingScreen';

export const AuthenticationContext = createContext({} as AuthContextTypes);

type AuthenticationProviderProps = { children: ReactNode };

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const { authState, isAuthenticating, setAuthState } = useAuth();
  const value: any = { authState, setAuthState };

  return !isAuthenticating ? (
    <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
  ) : (
    <LoadingScreen />
  );
};

export default AuthenticationProvider;
