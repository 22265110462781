import React from 'react';
import styled from 'styled-components';
import { Flex, Item } from 'react-flex-ready';
import PageTitle from './components/PageTitle';
import AssetSearchField from './components/AssetSearch/AssetSearchField';
import UserInfoDropdown from './components/UserInfoDropdown/UserInfoDropdown';
import usePageInfo from 'hooks/pageInfo.hook';

const HeaderStyled = styled.header`
  margin: 35px 0 30px 0;
`;
const SubHeading = styled.h5`
  margin: 5px 0 0;
`;

const HeaderAssetSearch = ({ pageTitle }: { pageTitle: string }) => {
  const { headerAssetCount } = usePageInfo();

  const paddedObjectCount = headerAssetCount.toString().padStart(2, '0');

  return (
    <HeaderStyled>
      <div className="container">
        <Flex col={12} align="center">
          <Item key="title" col={4} gap={0} style={{ alignSelf: 'flex-start' }}>
            <PageTitle pageTitle={pageTitle} />

            <SubHeading data-test-id="assetCount">
              # {paddedObjectCount} Total Asset{headerAssetCount === 1 ? '' : 's'}
            </SubHeading>
          </Item>
          <Item
            col={8}
            gap={1}
            className="searchbox"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '620px',
            }}
          >
            <AssetSearchField />
            <UserInfoDropdown />
          </Item>
        </Flex>
      </div>
    </HeaderStyled>
  );
};
export default HeaderAssetSearch;
