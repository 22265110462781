import { LatestBeacon } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import LargeInfoBoxMarker from '../components/ToolTips/LargeInfoBoxMarker';
import SmallInfoBoxMarker from '../components/ToolTips/SmallInfoBoxMarker';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useMapHooks from '../hooks/useMapHooks';
import { filterAssetMarkersByFloorUUID } from '../utils';

type SelectedBuildingAssetMarkersLayerType = {
  assetMarkers: LatestBeacon[];
};
export default function SelectedBuildingAssetMarkersLayer({
  assetMarkers,
}: SelectedBuildingAssetMarkersLayerType) {
  const { floorSelectedIDArray, apiCallInProgress, searchCleared, noSubTypesOrOwnersSelected } =
    useContext(MapControlContext); // keep clickedMarkerID in parent, to force re-render of markers when this changes.
  const [filteredAssetMarkers, setFilteredAssetMarkers] = useState(assetMarkers);

  const { recenterMapToAssetBounds } = useMapHooks();

  useEffect(() => {
    // filter asset markers by selected floor uuids
    // update when uuid changes, and after search has taken place.
    // hide assets if subtype or owner dropdowns have nothing selected
    // Note, we want to only run this when necessary as it is an expensive function, and can lead to performance issues.
    if (!apiCallInProgress) {
      const filteredAssets = filterAssetMarkersByFloorUUID(assetMarkers, floorSelectedIDArray);
      const markersToShow = noSubTypesOrOwnersSelected ? [] : filteredAssets;

      setFilteredAssetMarkers(markersToShow);

      // do not reposition user if search was cleared or if infobox was closed.
      if (!searchCleared) {
        recenterMapToAssetBounds(filteredAssets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorSelectedIDArray, apiCallInProgress]);

  return (
    <>
      {filteredAssetMarkers.map((asset) => {
        const randomInt = Math.random() * 100;
        const key = `${randomInt}-${asset.estimate._id}`;

        return (
          <div key={key}>
            <LargeInfoBoxMarker asset={asset} />
            <SmallInfoBoxMarker asset={asset} />
          </div>
        );
      })}
    </>
  );
}
