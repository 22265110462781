import ErrorBoundary from 'wrappers/ErrorBoundary';
import React, { useEffect, useContext } from 'react';
import AuthRefreshTimer from 'components/AuthRefreshTimer';
import useRefreshToken from 'hooks/useAuthRefresh.hook';
import { tokenHasExpired, getIdToken } from 'utils/utils';
import { AuthenticationContext } from 'contexts/authentication.context';
import UserPermissionCheckWrapper from 'wrappers/UserPermissionCheckWrapper';
import { Navigate } from 'react-router-dom';

type PrivateRouteType = {
  children: React.ReactNode | Element[];
};

const PrivateRoute = ({ children }: PrivateRouteType) => {
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const { isAuthenticated } = authContext;
  const refreshToken = useRefreshToken();

  useEffect(() => {
    // refresh auth token if it doesn't exist or is expired.
    if (tokenHasExpired(token)) refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated ? (
    <ErrorBoundary>
      <UserPermissionCheckWrapper>
        <AuthRefreshTimer />
        <div className="wrapper">{children}</div>
      </UserPermissionCheckWrapper>
    </ErrorBoundary>
  ) : (
    <Navigate
      to={{
        pathname: '/unauthenticated',
      }}
    />
  );
};
export default PrivateRoute;
