import { useContext } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { Auth, Cache } from 'aws-amplify';
import useAuth from './useAuth.hook';

const useAuthRefresh = (): any => {
  const { setAuthState, authState } = useContext(AuthenticationContext);
  const { signOut } = useAuth();

  async function refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();

      cognitoUser.refreshSession(currentSession.refreshToken, (err: any) => {
        if (!err) {
          const newState = { ...cognitoUser, isAuthenticated: true };

          setAuthState(newState);
          Cache.setItem('authState', newState);
          console.log('%c[Auth Token Refreshed]', 'color: lightblue;');
        } else {
          console.log(err);
        }
      });
    } catch (e) {
      // check were not using authState that is being mocked for cypress tests
      if (!authState.isMock) {
        // if refresh token fails, sign userout and redirect to cognito signin ui
        console.log(e, 'Logging User Out');
        signOut();
        Auth.federatedSignIn();
      }
    }
  }

  return refreshToken;
};

export default useAuthRefresh;
