import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';

export default function UnauthenticatedPage() {
  useEffect(() => {
    // redirect to self hosted cognito UI after 1 second.
    setTimeout(() => {
      Auth.federatedSignIn();
    }, 1000);
  }, []);
  return (
    <main className="u-flex-center">
      <div className="container">
        <p>Redirecting To Login Page</p>
      </div>
    </main>
  );
}
