import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from './router/AppRouter';
import AssetPageProvider from 'contexts/assetPage.context';
import PageInfoProvider from 'contexts/pageInfo.context';
import AssetInfoProvider from 'contexts/asset.context';
import AuthenticationProvider from 'contexts/authentication.context';
import UserProvider from 'contexts/user.context';
import MustUpdateContainer from 'contexts/mustUpdate.context';
import ConfigWrapper from 'wrappers/ConfigWrapper';
import UserPermissionsProvider from 'contexts/userPermissions.context';

const queryClient = new QueryClient();

export default function App() {
  return (
    <ConfigWrapper>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <UserProvider>
            <UserPermissionsProvider>
              <AssetPageProvider>
                <PageInfoProvider>
                  <AssetInfoProvider>
                    <MustUpdateContainer>
                      <AppRouter />
                    </MustUpdateContainer>
                  </AssetInfoProvider>
                </PageInfoProvider>
              </AssetPageProvider>
            </UserPermissionsProvider>
          </UserProvider>
        </AuthenticationProvider>
      </QueryClientProvider>
    </ConfigWrapper>
  );
}
