import styled from 'styled-components';
import SlidingPane from 'react-sliding-pane';
import { colours } from 'styling/colours';
import bigX from './bigX.svg';

export const StyledAssetListFlyout = styled(SlidingPane)`
  max-width: 600px;
  & .slide-pane__header {
    background: ${colours.assetDetailsFlyoutHeader};
    position: relative;
    padding: 35px 0;
    height: 110px;
  }
  & .slide-pane__header * {
    overflow-x: ellipsis;
  }
  & .slide-pane__close {
    position: absolute;
    right: 10px;
    top: 25px;
    background: url(${bigX}) 0 0 no-repeat;
    background-size: 16px 16px;
    background-position-x: center;
    background-position-y: center;
    opacity: 100%;
  }
  & .slide-pane__close svg {
    display: none;
  }
  & h2.slide-pane__title {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0px;
    color: ${colours.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .slide-pane__subtitle {
    color: ${colours.white};
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .slide-pane__content {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
`;

export const StyledButtonsContainer = styled.div`
  height: 60px;
  text-align: right;
  margin-top: auto;
  button {
    margin-top: 10px;
    &:last-child {
      margin-left: 10px;
    }
  }
`;
