import React from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../scss/leaflet-custom.scss';
import { StyledMapWrapper } from '../Styled';
import { LatestBeacon } from 'models';
import KeyLegendPanel from './KeyLegendPanel';
import FloorSelectorControl from './FloorSelectorControl';
import config from '../config';
import UnitGeoJsonLayer from '../layers/UnitGeoJsonLayer';
import BuildingsGeoJsonLayer from '../layers/BuildingsGeoJsonLayer';
import SelectedBuildingAssetMarkersLayer from '../layers/SelectedBuildingAssetMarkersLayer';
import LoadingOverlay from '../components/LoadingOverlay';

type MapComponentTypes = {
  bounds: any;
  assetMarkers: LatestBeacon[];
  buildingIsSelected: boolean;
  apiCallInProgress: boolean;
};
export default function MapComponent({
  bounds,
  assetMarkers,
  buildingIsSelected,
  apiCallInProgress,
}: MapComponentTypes) {
  return (
    <StyledMapWrapper>
      <MapContainer
        bounds={bounds}
        maxZoom={config.maxZoom}
        minZoom={config.minZoom}
        zoom={config.zoom}
        scrollWheelZoom={false}
        zoomControl={false}
      >
        {buildingIsSelected && <SelectedBuildingAssetMarkersLayer assetMarkers={assetMarkers} />}
        {buildingIsSelected && <UnitGeoJsonLayer />}
        <BuildingsGeoJsonLayer />

        <TileLayer
          opacity={0.5}
          maxNativeZoom={19} // upscale OSM tiles, because they dont give lvl 20.
          maxZoom={config.maxZoom}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="topright" />
        <FloorSelectorControl />
        <KeyLegendPanel />
        {apiCallInProgress && <LoadingOverlay />}
      </MapContainer>
    </StyledMapWrapper>
  );
}
