import React, { useContext } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MapControlContext } from '../contexts/mapcontrol.context';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  height: 73px;
  width: 100%;
  padding: 25px;
  > span {
    margin-right: 20px;
  }
`;

export default function AssetMapTopFilterBar() {
  const { setAssetMarkerInfoChecked } = useContext(MapControlContext);

  function handleCheckboxToggle(ev: CheckboxChangeEvent) {
    const checked = ev.target.checked;

    setAssetMarkerInfoChecked(checked);
  }

  return (
    <StyledDiv>
      <span>Show:</span>
      <Checkbox onChange={handleCheckboxToggle}>Asset Name & Asset Type</Checkbox>
    </StyledDiv>
  );
}
