import React from 'react';
import Button from 'components/Button/Button';
import { CloseOutlined } from '@ant-design/icons';
import { DialogContainerStyled } from '../Styled';
import { LatestBeacon } from 'models/devices.models';
import { checkRowsHaveAssignedAsset } from 'utils/utils';

type DialogButtonTypes = {
  handleArchive: () => void;
  handleAssign: () => void;
  handleCreate: () => void;
  selectedRows: LatestBeacon[];
};
type RegisteredBeaconDialogTypes = {
  selectedRows: LatestBeacon[];
  handleCloseDialog: () => void;
  handleArchive: () => void;
  handleAssign: () => void;
  handleCreate: () => void;
};

const DialogButtons = ({
  handleArchive,
  handleAssign,
  handleCreate,
  selectedRows,
}: DialogButtonTypes) => {
  const rowsHaveAssigned = checkRowsHaveAssignedAsset(selectedRows);
  const isMultiple = selectedRows.length > 1;

  // dialog button conditions:
  // SINGLE ASSET, NOT ASSIGNED. = ASSIGN, CREATE, ARCHIVE
  // SINGLE ASSET, ASSIGNED = REASSIGN AND ARCHIVE
  // MULTIPLE ASSET, NOT ASSIGNED = CREATE AND ARCHIVE.
  // MULTIPLE ASSETS, ASSIGNED = ARCHIVE.
  // MULTIPLE ASSETS, MIXTURE = ARCHIVE

  return (
    <div className="button-container">
      {!isMultiple && !rowsHaveAssigned && (
        <>
          <Button outline onClick={handleArchive}>
            Archive
          </Button>
          <Button onClick={handleAssign}>Assign</Button>
          <Button onClick={handleCreate}>Create</Button>
        </>
      )}
      {!isMultiple && rowsHaveAssigned && (
        <>
          <Button outline onClick={handleArchive}>
            Archive
          </Button>

          <Button onClick={handleAssign}>Reassign</Button>
        </>
      )}

      {isMultiple && !rowsHaveAssigned && (
        <>
          <Button outline onClick={handleArchive}>
            Archive
          </Button>
          <Button onClick={handleCreate}>Create</Button>
        </>
      )}
      {isMultiple && rowsHaveAssigned && (
        <>
          <Button outline onClick={handleArchive}>
            Archive
          </Button>
        </>
      )}
    </div>
  );
};

export default function RegisteredBeaconDialog({
  selectedRows,
  handleCloseDialog,
  handleArchive,
  handleAssign,
  handleCreate,
}: RegisteredBeaconDialogTypes) {
  const selectedRowsCount = selectedRows.length;

  return (
    <DialogContainerStyled>
      <dialog open>
        <div>
          <CloseOutlined
            style={{ color: '#ffffff', width: '16px', height: '16px' }}
            role="button"
            onClick={handleCloseDialog}
          />

          <div className="selected-readout">{selectedRowsCount}</div>
          <div className="selected-text"> beacons selected</div>
        </div>
        <DialogButtons
          selectedRows={selectedRows}
          handleArchive={handleArchive}
          handleAssign={handleAssign}
          handleCreate={handleCreate}
        />
      </dialog>
    </DialogContainerStyled>
  );
}
