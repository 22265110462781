import React, { createContext, useState, ReactNode } from 'react';

type PageInfoProps = { children: ReactNode };

interface IPageInfoProps {
  contextPageTitle: string;
  setContextPageTitle: (value: string) => void;
  headerAssetCount: number;
  setHeaderAssetCount: (value: number) => void;
}

export const PageInfoContext = createContext({} as IPageInfoProps);

const PageInfoProvider = ({ children }: PageInfoProps) => {
  const [contextPageTitle, setContextPageTitle] = useState<string>('');
  const [headerAssetCount, setHeaderAssetCount] = useState<number>(0);
  return (
    <PageInfoContext.Provider
      value={{ contextPageTitle, setContextPageTitle, headerAssetCount, setHeaderAssetCount }}
    >
      {children}
    </PageInfoContext.Provider>
  );
};

export default PageInfoProvider;
