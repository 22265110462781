import React from 'react';
import styled from 'styled-components';
import freshIcon from '../icons/fresh-asset.svg';
import moderateIcon from '../icons/moderate-asset.svg';
import staleIcon from '../icons/stale-asset.svg';
import movingIcon from '../icons/moving-asset-key.svg';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: 195px;
  height: 129px;
  background: white;
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 999;
  border-radius: 6px;
  padding: 0px 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      margin: 5px 0;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
`;

export default function KeyLegendPanel() {
  return (
    <StyledDiv>
      <ul>
        <li>
          <img src={staleIcon} alt="old Estimate" />
          <span>Old estimate</span>
        </li>
        <li>
          <img src={moderateIcon} alt="Moderate Estimate" />
          <span>Moderately fresh estimate</span>
        </li>
        <li>
          <img src={freshIcon} alt="Fresh Estimate" />
          <span>Fresh estimate</span>
        </li>
        <li>
          <img src={movingIcon} alt="Asset Moving" />
          <span>Asset moving</span>
        </li>
      </ul>
    </StyledDiv>
  );
}
