import React from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth.hook';
import styled from 'styled-components';
import { coloursNames } from 'styling/colours';
import config from 'react-global-configuration';

type itemType = {
  to: string;
  text: string;
  callback?: () => void;
  color?: string;
};
const menu: any = Menu;

const StyledMenu = styled(menu)`
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  li.ant-menu-item {
    margin: 0 !important;
    padding: 0 5px;
    &:first-child {
      margin-top: 10px !important;
    }
    &:last-child {
      margin-bottom: 10px !important;
    }
  }
`;

const StyledButton = styled.button`
  display: block;
  outline: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  color: ${(props) => props.color};
  border-radius: 3px;
  padding: 0 18px;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    background: rgba(188, 190, 192, 0.15);
    border-radius: 3px;
    color: #00aeef;
  }
`;

export default function DropDownMenu() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const menuItems = [
    {
      to: '/support',
      text: 'Support',
    },
    {
      to: '/help',
      callback: () => window.open(`${config.get('HELP_URL')}`, '_blank'),
      text: 'Help',
    },
    {
      to: '/signout',
      text: 'Sign out',
      callback: signOut,
      color: `${coloursNames.cinnabarOrange}`,
    },
  ];

  function handleClick(ev: React.SyntheticEvent, item: itemType) {
    ev.preventDefault();

    if (item.callback) {
      item.callback();
    } else {
      navigate(item.to);
    }
  }

  return (
    <StyledMenu>
      {menuItems.map((item) => {
        return (
          <Menu.Item key={item.to}>
            <StyledButton color={item.color} onClick={(ev) => handleClick(ev, item)}>
              {item.text}
            </StyledButton>
          </Menu.Item>
        );
      })}
    </StyledMenu>
  );
}
