import styled from 'styled-components';
import { colours } from 'styling/colours';
import { Modal } from 'antd';

const AntModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 3px;
    max-width: 450px;
    .ant-modal-body {
      padding: 24px 18px;
    }
  }
  .ant-modal-close {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h3 {
      margin-bottom: 0;
      margin-left: 10px;
      font-size: 18px;
      color: ${colours.primaryText};
      font-weight: 600;
    }
  }

  p {
    margin: 20px 0;
    color: ${colours.primaryText};
  }

  .progress-info {
    margin: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 16px;
      color: ${colours.secondaryText};
    }
  }
  .ant-progress-bg {
    background-color: ${colours.inform};
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    button:last-child {
      margin-left: 10px;
    }
  }
`;

export default AntModalStyled;
