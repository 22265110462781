import React from 'react';
import { colours } from 'styling/colours';

function ConfigureIcon({ isActive }: { isActive?: boolean }): any {
  return (
    <svg x="0px" y="0px" viewBox="0 0 16 16">
      <g>
        <g>
          <path
            fill={isActive ? colours.highlight : colours.primaryText}
            d="M12.8,15.1c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7C14.1,12.7,15,10.4,15,8c0-2.4-0.9-4.7-2.6-6.3
			c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0C14.9,2.8,16,5.3,16,8c0,2.7-1,5.2-2.9,7C13,15,12.9,15.1,12.8,15.1z"
          />
        </g>
        <g>
          <path
            fill={isActive ? colours.highlight : colours.primaryText}
            d="M11.5,12.8c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.3-0.5-0.1-0.8c0.9-1.1,1.4-2.5,1.4-3.9c0-1.5-0.5-2.9-1.4-3.9
			c-0.2-0.2-0.2-0.6,0.1-0.8c0.2-0.2,0.6-0.2,0.8,0.1c1.1,1.3,1.7,2.9,1.7,4.6c0,1.7-0.6,3.4-1.7,4.6C11.8,12.8,11.6,12.8,11.5,12.8
			z"
          />
        </g>
        <g>
          <path
            fill={isActive ? colours.highlight : colours.primaryText}
            d="M3.2,15.1c-0.1,0-0.2,0-0.3-0.1C1.1,13.2,0,10.7,0,8c0-2.7,1-5.2,2.9-7c0.2-0.2,0.5-0.2,0.7,0
			c0.2,0.2,0.2,0.5,0,0.7C1.9,3.3,1,5.6,1,8c0,2.4,0.9,4.7,2.6,6.3c0.2,0.2,0.2,0.5,0,0.7C3.5,15,3.4,15.1,3.2,15.1z"
          />
        </g>
        <g>
          <path
            fill={isActive ? colours.highlight : colours.primaryText}
            d="M4.5,12.8c-0.2,0-0.3-0.1-0.4-0.2C3.1,11.4,2.5,9.7,2.5,8c0-1.7,0.6-3.4,1.7-4.6c0.2-0.2,0.5-0.3,0.8-0.1
			C5.1,3.5,5.1,3.8,5,4.1C4.1,5.1,3.6,6.5,3.6,8c0,1.5,0.5,2.9,1.4,3.9c0.2,0.2,0.2,0.6-0.1,0.8C4.8,12.8,4.7,12.8,4.5,12.8z"
          />
        </g>
        <g>
          <g>
            <path
              fill={isActive ? colours.highlight : colours.primaryText}
              d="M10.8,7.5l-0.5-0.1C10.2,7.2,10.1,7,10,6.8l0,0l0.3-0.4c0.1-0.2,0.1-0.5,0-0.6l0,0c-0.2-0.2-0.4-0.2-0.6,0
				L9.2,6C9.1,6,9,5.9,8.9,5.9c-0.1,0-0.2-0.1-0.3-0.1L8.5,5.2C8.4,5,8.2,4.8,8,4.8C7.8,4.8,7.6,5,7.5,5.2L7.5,5.8
				c-0.1,0-0.2,0.1-0.3,0.1C7,5.9,6.9,6,6.8,6L6.4,5.7c-0.2-0.1-0.5-0.1-0.6,0l0,0c-0.2,0.2-0.2,0.4,0,0.6L6,6.8
				C5.9,7,5.8,7.2,5.8,7.5L5.2,7.5C5,7.6,4.8,7.8,4.8,8v0c0,0.2,0.2,0.4,0.4,0.5l0.6,0.1c0,0.1,0.1,0.2,0.1,0.3C5.9,9,6,9.1,6,9.2
				L5.7,9.6c-0.1,0.2-0.1,0.5,0,0.6l0,0c0.2,0.2,0.4,0.2,0.6,0.1L6.8,10c0.2,0.1,0.4,0.2,0.6,0.3l0.1,0.6c0,0.2,0.2,0.4,0.5,0.4h0
				c0.2,0,0.4-0.2,0.5-0.4l0.1-0.6C8.8,10.2,9,10.1,9.2,10l0.5,0.3c0.2,0.1,0.5,0.1,0.6-0.1l0,0c0.2-0.2,0.2-0.4,0-0.6L10,9.2
				C10,9.1,10.1,9,10.1,8.9c0-0.1,0.1-0.2,0.1-0.3l0.6-0.1c0.2,0,0.4-0.2,0.4-0.5v0C11.2,7.8,11,7.6,10.8,7.5z M7.7,9.2
				C7.3,9.1,6.9,8.7,6.8,8.2C6.7,7.4,7.4,6.7,8.2,6.9C8.7,6.9,9,7.3,9.1,7.8C9.3,8.6,8.6,9.3,7.7,9.2z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ConfigureIcon;
