import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import ShowMoreButton from 'components/DataTable/ShowMoreButton';
import { datatableStyle } from 'components/DataTable/DataTableStyling.config';
import { assetTableColumns } from 'components/DataTable/columns/assetTableColumns';
import { ReactComponent as UpDownArrow } from 'components/DataTable/icons/upAndDownArrow.svg';
import { StyledDataTableWrapper } from './style';
import SortingOverlay from './SortingOverlay';
import { LatestAssetResults } from 'models';
import { getNextCount } from 'utils/utils';

type SearchResultsTableType = {
  noDataComponent: any;
  isSorting: boolean;
  assetTableData: any;
  nextPageToken: string;
  handleSort: (column: TableColumn<LatestAssetResults>, direction: string) => Promise<void>;
  handleRowSelected: (arg: any) => void;
  handlePagination: () => void;
  fetchingNextPage: boolean;
  totalCount: number;
  paginationIndex: number;
};
const AssetsTable: React.FC<SearchResultsTableType> = ({
  noDataComponent,
  assetTableData,
  isSorting,
  nextPageToken,
  handleSort,
  handleRowSelected,
  handlePagination,
  fetchingNextPage,
  paginationIndex,
  totalCount,
}: SearchResultsTableType) => {
  const nextCount = getNextCount(paginationIndex, totalCount);

  return (
    <StyledDataTableWrapper
      data-test-id="table"
      className={`table-container ${isSorting ? '--sorting' : ''}`}
    >
      <DataTable
        columns={assetTableColumns}
        /*selectableRows*/
        data={assetTableData}
        defaultSortFieldId={1}
        customStyles={datatableStyle}
        sortIcon={<UpDownArrow />}
        onRowClicked={handleRowSelected}
        onSort={handleSort}
        noDataComponent={noDataComponent}
      />
      {isSorting && <SortingOverlay />}
      {nextPageToken && (
        <ShowMoreButton
          fetchFunction={handlePagination}
          nextIsLoading={fetchingNextPage}
          nextCount={nextCount}
        />
      )}
    </StyledDataTableWrapper>
  );
};
export default AssetsTable;
