import styled from 'styled-components';

export const StyledAlert = styled.div`
  padding: 10px 20px;
  display: flex;
  width: 100%;
  min-width: 200px;
  align-items: center;
  justify-content: space-between;
  span {
    margin: 0 10px;
  }
  button {
    padding: 0;
    background: none;
    outline: none;
    border: none;
    img {
      width: 10px;
      height: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
