function getAmplifyConfig(config: any) {
  const REACT_CONFIG = config.get();

  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      // region: window.REACT_CONFIG.AWS_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: REACT_CONFIG.COGNITO_USERPOOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: REACT_CONFIG.COGNITO_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: REACT_CONFIG.COGNITO_USERFLOW_TYPE,

      // OPTIONAL - Hosted UI REACT_CONFIGuration
      oauth: {
        domain: REACT_CONFIG.COGNITO_DOMAIN,
        scope: ['openid', 'profile'],
        redirectSignIn: `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }/authentication/callback`,
        redirectSignOut: `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }/signout`,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
  };
}
export default getAmplifyConfig;
