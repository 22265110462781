/* eslint-disable react/display-name */
import React from 'react';
import BatteryCell from '../cells/BatteryCell';
import LocationCell from '../cells/LocationCell';
import ArrowCell from '../cells/ArrowCell';
import { LatestAssetResults } from 'models';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import LastSeenCell from 'components/DataTable/cells/LastSeenCell';

export const assetTableColumns: any[] = [
  {
    name: 'ASSET NAME',
    selector: (row: LatestAssetResults) => <DefaultCell value={row.entity.properties.name} />,
    sortable: true,
    minWidth: '210px',
    maxWidth: '220px',
    sortField: 'properties.name',
  },
  {
    name: 'ASSET TYPE',
    selector: (row: LatestAssetResults) => <DefaultCell value={row.entity.subtype} />,
    sortable: true,
    minWidth: '180px',
    maxWidth: '220px',
    overflow: 'false',
    sortField: 'subtype',
  },
  {
    name: 'LAST SEEN',
    sortable: true,
    maxWidth: '180px',

    cell: (row: LatestAssetResults) =>
      row.estimate ? <LastSeenCell timestamp={row.estimate?.timestamp} /> : '––',
    sortField: 'estimate.timestamp',
  },
  {
    name: 'STATUS',
    selector: (row: LatestAssetResults) => (
      <DefaultCell value={row.estimate?.properties?.mobility_state} />
    ),
    sortable: true,
    maxWidth: '150px',
    overflow: 'false',
    sortField: 'estimate.properties.mobility_state',
  },
  {
    name: 'LOCATION',
    cell: (row: LatestAssetResults) => <LocationCell {...row} />,
    sortable: true,
    overflow: 'false',
    minWidth: '230px',
    sortField: 'estimate.location.region.name',
  },
  {
    name: 'OWNER',
    sortable: true,
    selector: (row: LatestAssetResults) => <DefaultCell value={row.entity.properties?.owner} />,
    sortField: 'properties.owner',
    minWidth: '270px',
  },
  {
    name: 'BATTERY',
    cell: (row: LatestAssetResults) => <BatteryCell {...row} />,
    sortable: true,
    sortField: 'estimate.properties.battery.battery_percent',
    minWidth: '90px',
    maxWidth: '140px',
  },
  {
    sortable: false,
    maxWidth: '15px',
    minWidth: '50px',
    cell: () => <ArrowCell />,
    sortField: '',
  },
];
