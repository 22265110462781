import React from 'react';
import { LatestBeacon } from 'models';
import { getValueSafely } from 'utils/utils';

export default function LocationSpan({ assetDetails }: { assetDetails: LatestBeacon }) {
  const buildingNamesArray = assetDetails.estimate?.location?.building?.building_names || [];
  const buildingName = getValueSafely(buildingNamesArray[0]);

  const buildingLevelName = getValueSafely(
    assetDetails.estimate?.location?.building?.building_level_name,
  );

  return <span style={{ marginBottom: '10px' }}>{`${buildingLevelName} / ${buildingName}`}</span>;
}
