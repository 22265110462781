import React, { useEffect, useState } from 'react';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import AssetsTable from 'components/DataTable/AssetsTable';
import AssetListFlyout from './components/AssetListFlyout';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import useAssetPage from 'hooks/useAssetPageContext.hook';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import { getAssetsNonExpired } from 'utils/utils';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import usePageInfo from 'hooks/pageInfo.hook';
import useMustUpdate from 'hooks/mustUpdate.hook';
import useAssetPageHandlers from './useAssetPageHandlers.hook';

const AssetListPage: React.FC = () => {
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const { setHeaderAssetCount, headerAssetCount } = usePageInfo();
  const { mustUpdate, setMustUpdate } = useMustUpdate();
  const { callFetchEstimates, callFetchEstimatesLoop, handleSort, handlePagination } =
    useAssetPageHandlers();

  const {
    selectedSearchMenuOptionLabel,
    searchTerm,
    setSortColumnField,
    setSortDirection,
    assetTableData,
    setAssetTableData,
    isSorting,
    isLoading,
    setIsLoading,
    nextToken,
    setNextToken,
    networkError,
    isSearching,
    searchCleared,
    setSearchCleared,
    cachedAssetTableDataRef,
    cachedAssetCountRef,
    cachedNextTokenRef,
    cachedPaginationIndexRef,
    fetchingNextPage,
    paginationIndex,
    setPaginationIndex,
  } = useAssetPage();

  const handleRowSelected = React.useCallback((row) => {
    setFlyoutOpen(true);
    localStorage.setItem('assetDetails', JSON.stringify(row));
  }, []);

  useEffect(() => {
    // reset state values on load / componnent unmount.
    setIsLoading(true);
    return () => {
      setNextToken('');
      setPaginationIndex(0);
      setSortColumnField('properties.name');
      setSortDirection('asc');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // toggle table results, next token and asset counts to cached refs if user no longer searching.
    if (!isSearching) {
      setHeaderAssetCount(cachedAssetCountRef.current);
      setAssetTableData(cachedAssetTableDataRef.current);
      setNextToken(cachedNextTokenRef.current);
      setPaginationIndex(cachedPaginationIndexRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    // recall api only when search has been cleared, so we get original estimates in table.
    if (searchCleared) {
      callFetchEstimates();
      setSearchCleared(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCleared]);

  useEffect(() => {
    // re-call API after asset archived and mustUpdate is changed.
    // callFetchestimatesLoop will iterate calls until pagination index is met.
    // this way, user will be shown same data if they had used pagination
    if (mustUpdate) {
      callFetchEstimatesLoop();
      setMustUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustUpdate]);

  useEffect(() => {
    //call api on page load to set initial table data.
    callFetchEstimates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <div className="container">
        <DataTableStatusWrapper error={networkError} status={isLoading ? 'loading' : ''}>
          <AssetsTable
            assetTableData={getAssetsNonExpired(assetTableData)}
            nextPageToken={nextToken}
            handleSort={handleSort}
            handlePagination={handlePagination}
            fetchingNextPage={fetchingNextPage}
            handleRowSelected={handleRowSelected}
            isSorting={isSorting}
            paginationIndex={paginationIndex}
            totalCount={headerAssetCount}
            noDataComponent={
              <NoDataComponent
                selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
                text={`Sorry, no results were found for ${searchTerm}`}
              />
            }
          />
        </DataTableStatusWrapper>
        <AssetListFlyout flyoutOpen={flyoutOpen} setFlyoutOpen={setFlyoutOpen} />
      </div>
      <ScrollToTopButton />
    </main>
  );
};
export default AssetListPage;
