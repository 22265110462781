import styled from 'styled-components';
import { colours } from 'styling/colours';

export const WhiteBg = styled.div`
  background-color: ${colours.white};
  padding: 40px;
  margin: 80px 0 16px 0;
`;

export const ButtonsContainer = styled.div`
  text-align: right;
  button {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
`;
