import { LatestAssetResults } from 'models';
import React, { createContext, ReactNode, useState, useRef } from 'react';

type AssetPageProviderProps = { children: ReactNode };
interface AssetPageContextI {
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  searchCategory: string;
  setSearchCategory: (arg: string) => void;
  selectedSearchMenuOptionLabel: string;
  setSelectedSearchMenuOptionLabel: (arg: string) => void;
  isSearching: boolean;
  setIsSearching: (arg: boolean) => void;
  fetchingNextPage: boolean;
  setFetchingNextPage: (arg: boolean) => void;
  paginationIndex: number;
  setPaginationIndex: (arg: number) => void;
  searchCleared: boolean;
  setSearchCleared: (arg: boolean) => void;
  sortColumnField: string | undefined;
  setSortColumnField: (arg: string | undefined) => void;
  sortDirection: string;
  setSortDirection: (arg: string) => void;
  assetTableData: LatestAssetResults[];
  setAssetTableData: (value: LatestAssetResults[]) => void;
  isSorting: boolean;
  setIsSorting: (arg: boolean) => void;
  isLoading: boolean;
  setIsLoading: (arg: boolean) => void;
  nextToken: string;
  setNextToken: (arg: string) => void;
  networkError: Error | undefined;
  setNetworkError: (arg: Error) => void;
  cachedAssetTableDataRef: any;
  cachedAssetCountRef: any;
  cachedNextTokenRef: any;
  cachedPaginationIndexRef: any;
}
export const AssetPageContext = createContext({} as AssetPageContextI);

// this context encompases the state needed by asset search and the asset list page, in order to correctly perform searches and use sorting or next parameters.
const AssetPageProvider = ({ children }: AssetPageProviderProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchCleared, setSearchCleared] = useState(false);
  const [assetTableData, setAssetTableData] = useState<LatestAssetResults[]>([]);
  const [isSorting, setIsSorting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nextToken, setNextToken] = useState('');
  const [fetchingNextPage, setFetchingNextPage] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0); // keep track of pagination clicks
  const [sortColumnField, setSortColumnField] = useState<string | undefined>('properties.name'); // default sort param.
  const [sortDirection, setSortDirection] = useState('asc'); // default sort order.
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [selectedSearchMenuOptionLabel, setSelectedSearchMenuOptionLabel] = useState('');
  const [networkError, setNetworkError] = useState<Error | undefined>();
  const cachedAssetTableDataRef: any = useRef([]);
  const cachedAssetCountRef = useRef(0);
  const cachedNextTokenRef = useRef('');
  const cachedPaginationIndexRef = useRef(0);

  return (
    <AssetPageContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        searchCategory,
        setSearchCategory,
        selectedSearchMenuOptionLabel,
        setSelectedSearchMenuOptionLabel,
        isSearching,
        setIsSearching,
        fetchingNextPage,
        setFetchingNextPage,
        paginationIndex,
        setPaginationIndex,
        sortColumnField,
        setSortColumnField,
        sortDirection,
        setSortDirection,
        assetTableData,
        setAssetTableData,
        isSorting,
        setIsSorting,
        isLoading,
        setIsLoading,
        nextToken,
        setNextToken,
        networkError,
        setNetworkError,
        searchCleared,
        setSearchCleared,
        cachedAssetTableDataRef,
        cachedAssetCountRef,
        cachedNextTokenRef,
        cachedPaginationIndexRef,
      }}
    >
      {children}
    </AssetPageContext.Provider>
  );
};

export default AssetPageProvider;
