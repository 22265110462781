import { useContext } from 'react';
import { AssetPageContext } from 'contexts/assetPage.context';

const useAssetPage = () => {
  const context = useContext(AssetPageContext);
  if (context === undefined) {
    throw new Error('useAssetPage must be used within a SearchProvider');
  }
  return context;
};
export default useAssetPage;
