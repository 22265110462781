import { useQuery } from 'react-query';
import DevicesApi from './devices.api';

export const useGetNewBeacons = (token: string, source: string) => {
  const devicesApi = new DevicesApi(token);

  return useQuery('new-beacons', () => devicesApi.getNewBeacons(source), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    cacheTime: 0,
  });
};

export const useGetRegisteredBeacons = (
  token: string,
  nextToken: string | null,
  sortParam: string,
  sortDirection: string,
) => {
  const devicesApi = new DevicesApi(token);

  return useQuery(
    'registered-beacons',
    () => devicesApi.getRegisteredBeacons(null, nextToken, sortParam, sortDirection),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      cacheTime: 0,
    },
  );
};
