import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import { PermissionsNames } from 'config/componentPermissions';
import usePermissions from 'hooks/usePermissions.hook';

const { TabPane } = Tabs;

type TabListTypes = {
  handleTabSelect: (key: string) => void;
  activeKey: string;
  newBeaconQueryCount: number;
  registeredBeaconQueryCount: number;
};

const TabsStyled = styled(Tabs)`
  max-width: 425px;
  .ant-tabs-tab {
    padding-bottom: 5px;
  }
  .ant-tabs-nav-more {
    display: none; // hide default elipsis button
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
`;

export default function Tablist({
  activeKey,
  handleTabSelect,
  newBeaconQueryCount,
  registeredBeaconQueryCount,
}: TabListTypes) {
  const { currentUserAccessPermissions } = usePermissions();
  const userHasWritePermission = currentUserAccessPermissions.includes(PermissionsNames.WRITE);

  return (
    <TabsStyled moreIcon={null} activeKey={activeKey} onChange={handleTabSelect}>
      <TabPane
        disabled={!userHasWritePermission} // disable without write permission.
        tab={`New Beacons (${newBeaconQueryCount})`}
        key="1"
      />
      <TabPane tab={`Registered Beacons (${registeredBeaconQueryCount})`} key="2" />
    </TabsStyled>
  );
}
