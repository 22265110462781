// KEEP THIS FILE FREE FROM REACT COMPONENTS OR IT WILL BREAK CYPRESS UNIT TESTS.

import jwt_decode from 'jwt-decode';
import { LatestAssetResults, LatestBeacon } from 'models';
import { v4 as uuidv4 } from 'uuid';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { BeaconSelectOptionType } from 'views/BeaconManagement/types';

import { format } from 'date-fns';

export const countSearchResults = (results: any) => {
  let pageObjects: number = 0;
  results?.pages.forEach((res: any) => {
    pageObjects = pageObjects + res.count;
  });
  return pageObjects;
};

export const combineSearchResultsPages = (searchResults: any) => {
  const tempRes: LatestAssetResults[] = [];
  searchResults?.pages.forEach((res: any) => {
    res.results.forEach((row: LatestAssetResults) => {
      tempRes.push(row);
    });
  });
  return tempRes;
};
export const replaceUnderscoresWithSpaces = (text: string | undefined) => {
  if (text !== undefined) {
    return text.replaceAll('_', ' ');
  }
  return '';
};

export const getAccessToken = (authContext: any) => {
  return authContext.signInUserSession.accessToken.jwtToken;
};

export const getIdToken = (authContext: any) => {
  if (!authContext.signInUserSession) return null;
  return authContext.signInUserSession.idToken.jwtToken;
};

export const getNewEntityId = () => {
  return uuidv4();
};

export const generateFlyoutTitle = (assetDetails: LatestAssetResults) => {
  const titleString = `${assetDetails.entity?.properties.name || 'Unknown'}
 - 
  ${assetDetails.entity?.subtype}`;
  return titleString;
};

export const tokenHasExpired = (jwtToken: string): boolean => {
  // a function to check if the time now is later than JWT tokens exp time and return boolean.
  if (!jwtToken) return true;
  const decoded: any = jwt_decode(jwtToken);
  const tokenExp = decoded.exp;
  const timeNow = Date.now();
  const tokenHasExpired: any = isAfter(timeNow, tokenExp);

  return tokenHasExpired;
};
//moved to common
export const dateDiff = (a: Date, b: Date): number => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  const difference = Math.floor((utc2 - utc1) / 1000);
  return difference;
};

export const checkRowsHaveAssignedAsset = (rows: LatestBeacon[]) => {
  const assignedAsset = rows.find((row) => row.device.entity_id && row.device.entity_id !== null);

  return assignedAsset !== undefined;
};

export const objIsEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const getCognitoGroups = (token: any, jsonData: any) => {
  const decodedToken: { 'cognito:groups': [] } = { 'cognito:groups': [] };

  if (token !== null) {
    const properToken: any = jwt_decode(token);
    decodedToken['cognito:groups'] = properToken['cognito:groups'] || [];
  }

  const cognitoGroups = decodedToken['cognito:groups'].filter((group: string) => {
    return Object.keys(jsonData.groups).includes(group);
  });

  return cognitoGroups;
};

export function getDefaultDropdownObj(options: BeaconSelectOptionType[]) {
  return options.filter((option) => option.default)[0];
}

export const replaceWordsInDistanceString = (distance: string): string => {
  return distance
    .replace(/hour/i, 'hr')
    .replace(/minute/i, 'min')
    .replace(/second/i, 'sec');
};

export function getLastSeenString(timestamp: string) {
  const lastSeenDateObj: any = new Date(parseInt(timestamp, 10));
  let lastSeenDate = '';

  if (lastSeenDateObj.toString() !== 'Invalid Date') {
    lastSeenDate = `(${format(lastSeenDateObj, 'dd/LL/yyyy - HH:mm')})`;
  }

  return lastSeenDate;
}

export function getAssetsNonExpired(assetData: LatestBeacon[]) {
  // function that filters out assets that have expired.
  // loops through assets, and only returns assets that do not have expires
  // if they do have expires value, we only allow assets that have not yet expired (isBefore)

  const filteredData: LatestBeacon[] = [];
  const timeNow = Date.now();

  assetData.forEach((item: any) => {
    if (!item.entity.expires) {
      filteredData.push(item);
    } else {
      if (isBefore(timeNow, item.entity.expires)) {
        filteredData.push(item);
      }
    }
  });

  return filteredData;
}

export const getValueSafely = (target: any, endOfDataString?: string) => {
  if (!target) {
    return '--';
  }
  return `${target}${endOfDataString ? endOfDataString : ''}`;
};

export function getNextCount(paginationIndex: number, totalCount: number) {
  const countOfShownRows = (paginationIndex + 1) * 50; // add 1 so we can multiply from 1.
  const nextCount = totalCount - countOfShownRows;
  // if total is more or equal to amount shown plus 50, next page will be at least 50.

  if (totalCount > countOfShownRows + 50) return 50;
  // otherwise return remaining amount if over 0.
  else return nextCount > 0 ? nextCount : 0;
}

export function humaniseStringCasing(string: string) {
  let i = 0;
  let frags = string.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function getSortedArrayByValue(arr: any[], value: string): any {
  return arr.sort((a: any, b: any) => a[value].toLowerCase().localeCompare(b[value].toLowerCase()));
}
