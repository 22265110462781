import React, { useState, createContext, ReactNode, useContext, useEffect } from 'react';
import { getIdToken, getCognitoGroups } from 'utils/utils';
import { AuthenticationContext } from 'contexts/authentication.context';
import LoadingScreen from 'components/Loading/LoadingScreen';
import { PermissionsNames } from 'config/componentPermissions';

type UserAccessPermissions = string[];

interface IUserPermissionsProps {
  currentUserAccessPermissions: UserAccessPermissions;
  setCurrentUserAccessPermissions: (value: UserAccessPermissions) => void;
  userPermissions: {
    hasRead: boolean;
    hasWrite: boolean;
    hasAdmin: boolean;
  };
}

export const UserPermissionsContext = createContext({} as IUserPermissionsProps);

type UserPermissionsProviderProps = { children: ReactNode };

const UserPermissionsProvider = ({ children }: UserPermissionsProviderProps) => {
  const [currentUserAccessPermissions, setCurrentUserAccessPermissions] =
    useState<UserAccessPermissions>([]);

  const [permissionsSet, setPermissionsSet] = useState(false);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);

  const userPermissions = {
    hasRead: currentUserAccessPermissions.includes(PermissionsNames.READ),
    hasWrite: currentUserAccessPermissions.includes(PermissionsNames.WRITE),
    hasAdmin: currentUserAccessPermissions.includes(PermissionsNames.ADMIN),
  };

  useEffect(() => {
    // retrieve permission role from config once.
    fetch('/config/permission_role_model.json')
      .then((response) => response.json())
      .then((res) => {
        let currentUserPermissions: string[] = [];
        const cognitoGroups = getCognitoGroups(token, res);
        // loop over users cognito groups

        cognitoGroups.forEach((group: string) => {
          // for each group iteration, add permission if it does not exist.
          res.groups[group].forEach((permission: string) => {
            if (!currentUserPermissions.includes(permission)) {
              currentUserPermissions.push(permission);
            }
          });
        });

        setCurrentUserAccessPermissions(currentUserPermissions);
        setPermissionsSet(true);
      })
      .catch((e: any) => {
        console.error(e);
        setPermissionsSet(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return permissionsSet ? (
    <UserPermissionsContext.Provider
      value={{
        currentUserAccessPermissions,
        setCurrentUserAccessPermissions,
        userPermissions,
      }}
    >
      {children}
    </UserPermissionsContext.Provider>
  ) : (
    <LoadingScreen text="loading" />
  );
};

export default UserPermissionsProvider;
