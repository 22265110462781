import styled from 'styled-components';
import { colours } from 'styling/colours';

export const Navbar = styled.aside`
  position: fixed;
  max-width: 210px;
  width: 100%;
  height: 100vh;
  background-color: ${colours.white};
  left: 0;
  top: 0;
  box-shadow: -2px 1px 5px 0px rgba(0, 0, 0, 0.29);

  .nav-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    > a {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin: 10px 0 0 10px;
      }
    }
  }

  &.--collapsed {
    width: 90px;
    button {
      right: -19px;
      transform: rotate(180deg);
    }
    .nav-container {
      > a {
        img {
          margin: 10px auto 0 auto;
        }
      }
    }
    nav {
      li {
        a {
          justify-content: center;
        }
      }
    }
  }
`;

export const Nav = styled.nav`
  padding: 0px;
  margin-top: 30px;
  ul {
    padding-left: 0;
    li {
      position: relative;
      list-style: none;
      margin: 15px 0;

      svg {
        margin: 0px 14px;
        height: 20px;
        width: 20px;
        min-width: 20px;
      }
      a {
        display: flex;
        align-items: center;
        padding: 7px 0;
        width: 100%;
        color: ${colours.primaryText};
        text-decoration: none;
        font-size: 15px;
        white-space: nowrap;

        &.selected {
          color: ${colours.highlight};
        }
      }
    }
  }
`;

export const Indicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: ${colours.highlight};
  margin-right: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;
