import React, { useEffect, createContext, ReactNode, useState, useContext } from 'react';
import { callGetUserInfo } from 'api/Oauth2/user';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getAccessToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import LoadingScreen from 'components/Loading/LoadingScreen';

interface userContextI {
  sub: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  email: string;
  username: string;
}
const initialUserState = {
  sub: '',
  email_verified: false,
  name: '',
  nickname: '',
  email: '',
  username: '',
};

export const UserContext = createContext({} as userContextI);

type UserProviderProps = { children: ReactNode };

const UserProvider = ({ children }: UserProviderProps) => {
  const authContext = useContext(AuthenticationContext).authState;
  const [userState, setUserState] = useState(initialUserState);

  useEffect(() => {
    if (authContext.isAuthenticated && authContext.signInUserSession) {
      // obtain access token from amplify auth obj and use it as token for Oath2 api
      const accessToken = getAccessToken(authContext);

      callGetUserInfo(accessToken)
        .then(function (response) {
          // handle success
          setUserState(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            const errorString = `${error.response.data.error}`;
            alertErrorMessage(errorString);
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.isAuthenticated]);

  return userState ? (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  ) : (
    <LoadingScreen />
  );
};

export default UserProvider;
