import React from 'react';

export default function SupportPage() {
  return (
    <main className="u-flex-center" style={{ maxHeight: '550px' }}>
      <div className="container">
        <p>
          Please contact us at{' '}
          <a href="mailto:trackingsupport@navenio.com">trackingsupport@navenio.com</a> to report a
          problem or to give feedback.
        </p>
      </div>
    </main>
  );
}
