import { LatestAssetResults } from 'models';
import React, { createContext, useState, ReactNode } from 'react';

type AssetInfoProps = { children: ReactNode };

interface IAssetInfoProps {
  assetDetails: LatestAssetResults;
  setAssetDetails: (value: LatestAssetResults) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

export const AssetInfoContext = createContext({} as IAssetInfoProps);

const AssetInfoProvider = ({ children }: AssetInfoProps) => {
  const [assetDetails, setAssetDetails] = useState<LatestAssetResults>({} as LatestAssetResults);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <AssetInfoContext.Provider value={{ assetDetails, setAssetDetails, isEditing, setIsEditing }}>
      {children}
    </AssetInfoContext.Provider>
  );
};

export default AssetInfoProvider;
