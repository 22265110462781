import React, { useEffect } from 'react';
import AssetSearchAsyncSelect from './AssetSearchAsyncSelect';
import useAssetPage from 'hooks/useAssetPageContext.hook';
import useAssetPageHandlers from 'views/AssetListPage/useAssetPageHandlers.hook';

const assetSearchOptionsArray = [
  { value: 'properties.name', label: 'Asset Name' },
  { value: 'subtype', label: 'Asset Type' },
  { value: 'location', label: 'Location' },
  { value: 'properties.owner', label: 'Owner' },
];

const AssetSearchField = () => {
  const {
    searchTerm,
    setSearchTerm,
    setSearchCategory,
    setSelectedSearchMenuOptionLabel,
    selectedSearchMenuOptionLabel,
    isSearching,
    setIsSearching,
    setNextToken,
    setSearchCleared,
    setPaginationIndex,
  } = useAssetPage();
  const { handleEnterSearch, handleClickOptionSearch } = useAssetPageHandlers();

  useEffect(() => {
    // reset search state params on component render & unmount.
    setSearchTerm('');
    setSearchCategory('');
    setSelectedSearchMenuOptionLabel('');

    return () => {
      setIsSearching(false);
      setSearchTerm('');
      setSearchCategory('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AssetSearchAsyncSelect
      setSearchCleared={setSearchCleared}
      searchTerm={searchTerm}
      setNextToken={setNextToken}
      handleEnterSearchFunc={handleEnterSearch}
      handleClickOptionSearchFunc={handleClickOptionSearch}
      setSearchTerm={setSearchTerm}
      setIsSearching={setIsSearching}
      isSearching={isSearching}
      menuOptions={assetSearchOptionsArray}
      selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
      setSearchCategory={setSearchCategory}
      setSelectedSearchMenuOptionLabel={setSelectedSearchMenuOptionLabel}
      placeholder="Search for Assets"
      width={375}
      setPaginationIndex={setPaginationIndex}
    />
  );
};

export default AssetSearchField;
