import React from 'react';
import collapseIcon from 'assets/icons/collapse.svg';
import styled from 'styled-components';

const CollapseButtonStyled = styled.button`
  position: absolute;
  right: -16px;
  top: 38px;
  outline: none;
  background: white;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
`;

export default function CollapseButton({ onClick }: { onClick: () => void }) {
  return (
    <CollapseButtonStyled onClick={onClick}>
      <img src={collapseIcon} alt="close" />
    </CollapseButtonStyled>
  );
}
