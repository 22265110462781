import React, { useState } from 'react';
import format from 'date-fns/format';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { alertErrorMessage } from 'utils/alerts';
import Button from 'components/Button/Button';

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  span {
    margin-right: 10px;
    color: ${colours.secondaryText};
  }
`;
type RefreshTimestampAndButtonTypes = {
  setSelectedTabKey: (param: string) => void;
  selectedTabKey: string;
  setHasRefreshed: (param: boolean) => void;
};
export default function RefreshTimestampAndButton({
  setSelectedTabKey,
  selectedTabKey,
  setHasRefreshed,
}: RefreshTimestampAndButtonTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(getTimeString());
  const queryClient = useQueryClient();

  function getTimeString() {
    const timeNow = new Date();
    const year = format(timeNow, 'dd/MM/yyyy');
    const time = format(timeNow, 'HH.mm');
    return `${time} on ${year}`;
  }

  async function handleRefresh() {
    const queries = ['new-beacons', 'registered-beacons'];
    let index = 0;
    setIsLoading(true);
    setHasRefreshed(false);

    for await (const query of queries) {
      queryClient
        .invalidateQueries(query)
        .then(() => {
          index++;
          if (index === queries.length) {
            setIsLoading(false);
            setTime(getTimeString());
            setSelectedTabKey(selectedTabKey);
            setHasRefreshed(true);
            setTimeout(() => {
              // toggle hasRefreshed boolean so that we can listen in useEffect.
              setHasRefreshed(false);
            }, 1);
          }
        })
        .catch((err) => {
          console.error(err.response);
          alertErrorMessage('Could not refresh');
          setIsLoading(false);
        });
    }
  }

  return (
    <StyledContainer>
      <span>Last updated at {time}</span>
      <Button outline isLoading={isLoading} onClick={handleRefresh}>
        Refresh
      </Button>
    </StyledContainer>
  );
}
