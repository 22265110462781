import React, { useContext } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import {
  getAssociatedLevelIDsArrayFromOrdinal,
  getFloorAssetCountFromLevelIDArray,
} from '../utils';
import { AssetsContext } from '../contexts/assets.context';
import { StyledFloorSelectorControlDiv } from '../Styled';
import useFloorSelectionHooks from '../hooks/useFloorSelectionHooks';

// determine levels associated with buildings by filtering matching building_ids in levels.geojson.
// each feature returned represents a level or floor for that building
// we then filter the features by unique ordinals,
// sort them numerically,
// loop over sorted levels and render a button only if building has multiple unique ordinals.
export default function FloorSelectorControl() {
  const { assetMarkers } = useContext(AssetsContext);
  const {
    setfloorSelectedIDArray,
    apiCallInProgress,
    setClickedMarkerID,
    noSubTypesOrOwnersSelected,
  } = useContext(MapControlContext);

  const {
    associatedBuildingLevelFeatures,
    sortedLevelsWithUniqueOrdinals,
    setSelectedIndex,
    selectedIndex,
  } = useFloorSelectionHooks();

  function handleFloorSelect(ev: React.SyntheticEvent, level: any, index: number) {
    ev.preventDefault();

    // loop through all levels and get array of level_ids
    const associatedLevelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
      level.properties.ordinal,
      associatedBuildingLevelFeatures,
    );

    setfloorSelectedIDArray(associatedLevelIDsArray);
    setSelectedIndex(index);
    setClickedMarkerID(''); // reset clicked marker ID to hide info boxes.
  }

  return (
    <StyledFloorSelectorControlDiv data-test-id="floor-selector">
      {sortedLevelsWithUniqueOrdinals.map((level: any, index: number) => {
        // note we are using the index not the ordinal to mark the floor as selected in the button
        const buttonIsActive = selectedIndex === index;

        const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
          sortedLevelsWithUniqueOrdinals[index].properties.ordinal,
          associatedBuildingLevelFeatures,
        );
        const floorAssetCount = getFloorAssetCountFromLevelIDArray(levelIDsArray, assetMarkers);
        const shouldShowFloorCount =
          floorAssetCount > 0 && !noSubTypesOrOwnersSelected && !apiCallInProgress;
        return (
          <div className="button-container" key={index}>
            <button
              className={buttonIsActive ? '--active' : ''}
              onClick={(ev) => handleFloorSelect(ev, level, index)}
            >
              {level.properties.ordinal}
              {shouldShowFloorCount && <span className="count-readout">{floorAssetCount}</span>}
            </button>
          </div>
        );
      })}
    </StyledFloorSelectorControlDiv>
  );
}
