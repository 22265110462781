import React, { useContext } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import transparentImg from '../../icons/transparent.png';
import { MapControlContext } from 'views/AssetsMapPage/contexts/mapcontrol.context';
import { LatestBeacon } from 'models';
import SmallInfoBoxTooltip from './SmallInfoBoxTooltip';

export default function SmallInfoBoxMarker({ asset }: { asset: LatestBeacon }) {
  const { assetMarkerInfoChecked } = useContext(MapControlContext);

  const markerLong = asset.estimate.location.coordinates.coordinates[0];
  const markerLat = asset.estimate.location.coordinates.coordinates[1];

  const customIcon = new L.Icon({
    iconUrl: transparentImg, // 1px alpha image to show nothing.
    iconSize: new L.Point(0, 0),
    iconAnchor: [0, 0],
  });

  return assetMarkerInfoChecked ? (
    <Marker icon={customIcon} position={[markerLat, markerLong]}>
      <SmallInfoBoxTooltip asset={asset} />
    </Marker>
  ) : null;
}
