import React from 'react';
import styled from 'styled-components';
import { Flex, Item } from 'react-flex-ready';
import PageTitle from 'components/Header/components/PageTitle';
import UserInfoDropdown from './components/UserInfoDropdown/UserInfoDropdown';
import { colours } from 'styling/colours';

const HeaderStyled = styled.header`
  margin: 35px 0 50px 0;
`;

const HeaderDefault = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <div style={{ backgroundColor: `${colours.background}`, padding: '0' }}>
      <HeaderStyled>
        <div className="container">
          <Flex col={12} align="center">
            <Item key="title" col={8} gap={0} style={{ alignSelf: 'flex-start' }}>
              <PageTitle pageTitle={pageTitle} />
            </Item>
            <Item
              col={4}
              gap={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '200px',
              }}
            >
              <UserInfoDropdown />
            </Item>
          </Flex>
        </div>
      </HeaderStyled>
    </div>
  );
};
export default HeaderDefault;
