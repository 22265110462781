import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import warning from './icons/warning.svg';

export const StyledFormControlContainer = styled.div`
  min-height: 140px;
`;

export const StyledLabel = styled.label`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0px;
  width: auto;
  margin: 0 0 10px;
  display: block;
`;

export const StyledSup = styled.sup`
  color: ${colours.highlight};
`;

export const RequiredAsterisk: React.FunctionComponent = () => {
  return <StyledSup>*</StyledSup>;
};

export const StyledInput = styled.input`
  border: 1px solid ${colours.tertiaryText};
  border-radius: 3px;
  padding: 16px;
  color: ${colours.primaryText};
  font-size: 15px;
  letter-spacing: 0px;
  width: 100%;
  :disabled {
    background-color: ${colours.disableSelect};
    border: none;
  }
  &.error {
    border: 1px solid ${colours.highlight};
  }
  &.error:focus {
    outline: none;
  }
`;

export const StyledTextarea = styled.textarea`
  border: 1px solid ${colours.tertiaryText};
  border-radius: 3px;
  padding: 16px;
  color: ${colours.primaryText};
  font-size: 15px;
  letter-spacing: 0px;
  width: 100%;
  height: 100px;
  :disabled {
    background-color: ${colours.disableSelect};
    border: none;
  }
  &.error {
    border: 1px solid ${colours.highlight};
  }
  &.error:focus {
    outline: none;
  }
`;

export const StyledError = styled.div`
  background: url(${warning}) 0 0 no-repeat;
  background-size: 18px 18px;
  color: ${colours.error};
  margin: 5px 0 0 0;
  padding-left: 28px;
`;
export const StyledFieldset = styled.fieldset`
  height: 150px;
`;

export const StyledCharactersRemaining = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  color: ${colours.secondaryText};
  margin-top: 5px;
`;
