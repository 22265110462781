import React from 'react';
import { Device } from 'models/devices.models';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const StyledDiv = styled.div`
  header {
    margin-bottom: 20px;

    h2 {
      font-size: 18px;
      color: ${colours.secondaryText};
      font-weight: 600;
    }
  }
  .beacon-information-content {
    display: flex;
    .col {
      width: 300px;
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          display: flex;
          margin: 14px 0;
          font-size: 16px;

          span:first-child {
            width: 100%;
            max-width: 110px;
            display: inline-block;
            color: ${colours.secondaryText};
          }
        }
      }
    }
  }
`;

const BeaconInfoValue = ({ value }: { value: string | null | string[] }) => {
  return <span className="value">{value ? value : '--'}</span>;
};

export default function BeaconInformationPanel({ selectedBeacon }: { selectedBeacon: Device }) {
  return (
    <StyledDiv>
      <header>
        <h2>BEACON INFORMATION</h2>
      </header>
      <div className="beacon-information-content">
        <div className="col">
          <ul>
            <li>
              <span>Beacon ID:</span> <BeaconInfoValue value={selectedBeacon.device_id} />
            </li>
            <li>
              <span>Beacon Type: </span> <BeaconInfoValue value={selectedBeacon.type} />
            </li>
            <li>
              <span>Order ID: </span> <BeaconInfoValue value={selectedBeacon.properties.order_id} />
            </li>
            <li>
              <span>Tags: </span> <BeaconInfoValue value={selectedBeacon.properties.tags} />
            </li>
          </ul>
        </div>
        <div className="col">
          <ul>
            <li>
              <span>Manager: </span>
              <span>--</span>
            </li>
            <li>
              <span>Venue: </span>
              <BeaconInfoValue value={selectedBeacon.internal_metadata.venue?.address} />
            </li>
            <li>
              <span>Notes: </span> <span>--</span>
            </li>
          </ul>
        </div>
      </div>
    </StyledDiv>
  );
}
