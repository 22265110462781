import React, { useState, useEffect } from 'react';
import logo from 'assets/images/navenio-landscape.svg';
import logoSmall from 'assets/images/navenio-small.svg';
import AssetIcon from './icons/AssetIcon';
import ConfigureIcon from './icons/ConfigureIcon';
import { Link, useLocation } from 'react-router-dom';
import VersionReadout from './VersionReadout';

import CollapseButton from './CollapseButton';
import { Tooltip } from 'antd';
import { Navbar, Nav, Indicator } from './styled';
import MapIcon from './icons/MapIcon';

const navLinkArray = [
  {
    name: 'Asset List',
    href: '/',
    icon: AssetIcon,
  },
  {
    name: 'Beacon Management',
    href: '/beacon-management',
    icon: ConfigureIcon,
  },
  {
    name: 'Map View',
    href: '/map',
    icon: MapIcon,
  },
];

const Navigation = ({ navCollapsedDefault }: { navCollapsedDefault: boolean }) => {
  const [userHasCollapsed, setUserHasCollapsed] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(userHasCollapsed);
  const location = useLocation();
  const currentHref = location.pathname;
  const [activeHref, setActiveHref] = useState(currentHref);

  function toggleNavOpen() {
    const bodyEl = document.querySelector('body');
    bodyEl?.classList.toggle('--nav-collapsed');

    setIsCollapsed(!isCollapsed);
    setUserHasCollapsed(!isCollapsed);
  }

  useEffect(() => {
    setActiveHref(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // ensure correct class is on body, when component loading, and sync internal collapse state to default.
    const bodyEl = document.querySelector('body');

    // ensure user action takes priority over default.
    // otherwise follow route default.
    if (userHasCollapsed) {
      setIsCollapsed(true);
      bodyEl?.classList.add('--nav-collapsed');
    } else {
      setIsCollapsed(navCollapsedDefault);
      if (!navCollapsedDefault) {
        bodyEl?.classList.remove('--nav-collapsed');
      } else {
        bodyEl?.classList.add('--nav-collapsed');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  function handleLinkClick(href: string) {
    setActiveHref(href);
  }

  return (
    <Navbar className={isCollapsed ? '--collapsed' : ''}>
      <div className="nav-container">
        <Link to="/" title="Navenio Home Page" aria-label="Navenio Home Page" rel="homepage">
          <img src={isCollapsed ? logoSmall : logo} alt="Navenio Logo" />
        </Link>
        <CollapseButton onClick={toggleNavOpen} />

        <Nav>
          <ul>
            {navLinkArray.map((link) => {
              const linkIsSelected = link.href === activeHref;
              return (
                <li key={link.href}>
                  <Tooltip
                    placement="left"
                    title={link.name}
                    overlayStyle={!isCollapsed ? { display: 'none' } : {}} // dont display tooltip if open.
                  >
                    <Link
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          handleLinkClick(link.href);
                        }
                      }}
                      onClick={() => {
                        handleLinkClick(link.href);
                      }}
                      to={link.href}
                      className={linkIsSelected ? 'selected' : ''}
                    >
                      {linkIsSelected && <Indicator />}
                      <link.icon isActive={linkIsSelected} />
                      {!isCollapsed && link.name}
                    </Link>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        </Nav>
        <VersionReadout />
      </div>
    </Navbar>
  );
};
export default Navigation;
