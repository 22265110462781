import React, { useState, useContext, useEffect } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledRegisteredDropdownControlWrapper } from '../Styled';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getDefaultDropdownObj, getIdToken } from 'utils/utils';
import DevicesApi from 'api/devices/devices.api';
import { beaconAssignmentOptionsArray } from './menuOptions';
import { apiAssetAssignEnum } from 'views/BeaconManagement/enum';
import { BeaconSelectOptionType } from 'views/BeaconManagement/types';
import { alertErrorMessage } from 'utils/alerts';
import LoadingSelectInput from 'components/Loading/LoadingSelectInput';

type RegisteredBeaconAssignedInputSelectTypes = {
  handleSelectAssignmentChange: (arg: BeaconSelectOptionType) => void;
};

export default function RegisteredBeaconAssignedInputSelect({
  handleSelectAssignmentChange,
}: RegisteredBeaconAssignedInputSelectTypes) {
  const [defaultDropdownValue, setDefaultDropdownValue] = useState<null | BeaconSelectOptionType>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState(beaconAssignmentOptionsArray);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const deviceApi = new DevicesApi(token);

  function callBeaconAssignmentOptionsAddApiCount() {
    // loop over each assignment function and call api with assignment param to retrieve total.
    // create new Obj with appended values set states to corresponding values once count reaches 3.
    let count = 0;
    const newOptionsArray: BeaconSelectOptionType[] = [];

    beaconAssignmentOptionsArray.forEach((entry, index) => {
      deviceApi
        .getRegisteredBeaconsTotal(apiAssetAssignEnum[entry.value])
        .then((res) => {
          const total = res.data.total;
          const newOptionObj = {
            value: beaconAssignmentOptionsArray[index].value,
            label: `${beaconAssignmentOptionsArray[index].label} (${total})`,
            default: entry.default ? true : false,
            order: entry.order,
          };
          newOptionsArray.push(newOptionObj);
          count += 1;

          if (count === 3) {
            const defaultDropDownObj = getDefaultDropdownObj(newOptionsArray);
            const sortedOptions = newOptionsArray.sort((a: any, b: any) => a.order - b.order);

            setDefaultDropdownValue(defaultDropDownObj);
            setOptions(sortedOptions);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          alertErrorMessage(`Network Fail: ${err.message}`);
          console.error(err.message);
        });
    });
  }

  useEffect(() => {
    callBeaconAssignmentOptionsAddApiCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <div style={{ width: '100%' }} data-test-id="registered-beacon-status">
      <SelectInput
        defaultValue={defaultDropdownValue}
        onChange={(val: any) => handleSelectAssignmentChange(val)}
        options={options}
        components={{
          Control: ({ children, ...rest }) => (
            <StyledRegisteredDropdownControlWrapper>
              <components.Control {...rest}>
                <span className="react-control__preplaceholder">Show: </span>
                {children}
              </components.Control>
            </StyledRegisteredDropdownControlWrapper>
          ),
        }}
      />
    </div>
  ) : (
    <LoadingSelectInput />
  );
}
