import React, { useState } from 'react';
import AssetSubTypeDropdownFilterInput from '../components/SearchFilterBar/AssetSubTypeDropdownFilterInput';
import AssetOwnerDropdownFilterInput from '../components/SearchFilterBar/AssetOwnerDropdownFilterInput';
import { StyledDropDownContainer } from '../Styled';

// This component stores query state used to call API for both dropdowns.
// We pass states down, so they can be set and consumed from the api calls in children
export default function DropdownFilterInputController({
  resetSearchFromCheckbox,
}: {
  resetSearchFromCheckbox: () => void;
}) {
  const [assetOwnerCheckedList, setAssetOwnerCheckedList] = useState<[] | string[]>([]);
  const [assetSubtypeCheckedList, setAssetSubtypeCheckedList] = useState<[] | string[]>([]);
  const [assetSubtypeCheckAll, setAssetSubtypeCheckAll] = useState(true);
  const [assetOwnerCheckAll, setAssetOwnerCheckAll] = useState(true);

  // the queries that we pass to API. Note they are passed to both components.
  //we can pass empty array if all is selected, as all is unfiltered.
  const assetSubtypeQueryArray = assetSubtypeCheckAll ? [] : assetSubtypeCheckedList;
  const assetOwnerQueryArray = assetOwnerCheckAll ? [] : assetOwnerCheckedList;

  return (
    <StyledDropDownContainer>
      <AssetSubTypeDropdownFilterInput
        assetOwnerQueryArray={assetOwnerQueryArray}
        assetSubtypeQueryArray={assetSubtypeQueryArray}
        assetSubtypeCheckedList={assetSubtypeCheckedList}
        setAssetSubtypeCheckedList={setAssetSubtypeCheckedList}
        assetSubtypeCheckAll={assetSubtypeCheckAll}
        setAssetSubtypeCheckAll={setAssetSubtypeCheckAll}
        resetSearchFromCheckbox={resetSearchFromCheckbox}
      />
      <AssetOwnerDropdownFilterInput
        assetOwnerQueryArray={assetOwnerQueryArray}
        assetSubtypeQueryArray={assetSubtypeQueryArray}
        assetOwnerCheckedList={assetOwnerCheckedList}
        setAssetOwnerCheckedList={setAssetOwnerCheckedList}
        assetOwnerCheckAll={assetOwnerCheckAll}
        setAssetOwnerCheckAll={setAssetOwnerCheckAll}
        resetSearchFromCheckbox={resetSearchFromCheckbox}
      />
    </StyledDropDownContainer>
  );
}
