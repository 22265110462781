import React, { useContext } from 'react';
import { Marker } from 'react-leaflet';
import L, { LeafletMouseEvent } from 'leaflet';
import { getIconFromEstimateFreshness } from '../../utils';
import { LatestBeacon } from 'models';
import LargeInfoBoxTooltip from './LargeInfoBoxTooltip';
import { MapControlContext } from 'views/AssetsMapPage/contexts/mapcontrol.context';
import ComponentAnimationWrapper from 'wrappers/ComponentAnimationWrapper';
import useMapHooks from 'views/AssetsMapPage/hooks/useMapHooks';

export default function LargeInfoBoxMarker({ asset }: { asset: LatestBeacon }) {
  const { clickedMarkerID, setClickedMarkerID } = useContext(MapControlContext);

  const { panToLatLong } = useMapHooks();

  const showMarkerLargeInfoBox = clickedMarkerID === asset.estimate.entity_id;

  const markerLong = asset.estimate.location.coordinates.coordinates[0];
  const markerLat = asset.estimate.location.coordinates.coordinates[1];

  function handleMarkerClick(e: LeafletMouseEvent, asset: LatestBeacon) {
    setClickedMarkerID(asset.estimate.entity_id);
    panToLatLong(e.latlng);
  }

  const customIcon = new L.Icon({
    iconUrl: getIconFromEstimateFreshness(asset.estimate),
    iconSize: new L.Point(20, 20),
    iconAnchor: [10, 10], // half of icon size.
    className: 'leaflet-custom-icon',
  });

  function handleCloseInfoBox() {
    setClickedMarkerID('');
  }

  return (
    <ComponentAnimationWrapper>
      <Marker
        eventHandlers={{
          click: (e) => {
            handleMarkerClick(e, asset);
          },
        }}
        icon={customIcon}
        position={[markerLat, markerLong]}
      >
        {showMarkerLargeInfoBox && (
          <LargeInfoBoxTooltip asset={asset} handleCloseInfoBox={handleCloseInfoBox} />
        )}
      </Marker>
    </ComponentAnimationWrapper>
  );
}
