import React, { useContext, useEffect, useState } from 'react';
import { SearchBarStyled } from '../../Styled';
import { StyledAsyncSelect, StyledInputContainer } from 'components/AsyncSelect/Styled';
import ClearInputButton from 'components/Button/ClearInputButton';
import { MapControlContext } from '../../contexts/mapcontrol.context';
import NoSearchResultsPanel from '../NoSearchResultsPanel';
import useCallSearchAssets from '../../hooks/useCallSearchAssets';
import DropdownFilterInputController from 'views/AssetsMapPage/controllers/DropdownFilterInputController';

export default function AssetMapSearchFilterForm() {
  const [searchTerm, setSearchTerm] = useState('');

  const { callSearchAssets, noResultsReturned, setNoResultsReturned } = useCallSearchAssets();

  const {
    setRecallGetAssets,
    setSearchCleared,
    setClickedMarkerID,
    setInSearchMode,
    inSearchMode,
    inFilterMode,
  } = useContext(MapControlContext);

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);
        setNoResultsReturned(false);

        break;
      default:
        break;
    }
  }
  function resetSearchFromCheckbox() {
    setInSearchMode(false);
    setSearchTerm('');
    setNoResultsReturned(false);
  }

  function handleClearField() {
    // clear search field, and trigger recall API in Asset Context, to show unfiltered assets.
    setSearchCleared(true); // set to true so we dont recenter map.
    setInSearchMode(false);
    setSearchTerm('');
    setClickedMarkerID('');
    setNoResultsReturned(false);
    if (!inFilterMode) setRecallGetAssets(true); // we do not want to recall api if filters are present and user cancels search.
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    if (searchTerm.length < 1) return;
    if (e.keyCode === 13) {
      callSearchAssets(searchTerm);
    }
  }

  useEffect(() => {
    // reset search when no searchterm.
    if (searchTerm.length < 1 && inSearchMode) {
      setSearchCleared(true); // set to true so we dont recenter map.
      setInSearchMode(false);
      setClickedMarkerID('');
      setRecallGetAssets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <SearchBarStyled>
      <StyledInputContainer width={350}>
        <StyledAsyncSelect
          placeholder="Search for assets"
          onInputChange={handleInputChange}
          onKeyDown={(e: React.KeyboardEvent) => handleKeyPress(e)}
          inputValue={searchTerm}
          className="asyncSearch"
          classNamePrefix="asyncSearchPrefix"
          menuIsOpen={false}
        />
        {searchTerm.length > 0 && <ClearInputButton onClick={handleClearField} />}
        {noResultsReturned && <NoSearchResultsPanel searchTerm={searchTerm} />}
      </StyledInputContainer>
      <DropdownFilterInputController resetSearchFromCheckbox={resetSearchFromCheckbox} />
    </SearchBarStyled>
  );
}
