import { useContext } from 'react';
import { UserPermissionsContext } from 'contexts/userPermissions.context';

const usePermissions = () => {
  const context = useContext(UserPermissionsContext);
  if (context === undefined) {
    throw new Error('userRoles hook must be used withing a UseRoles context');
  }
  return context;
};
export default usePermissions;
